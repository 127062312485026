import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from "@fortawesome/free-solid-svg-icons";

export default function XButton({clickHandler, additionalClasses}) {
    return (
        <>
            <button className={"absolute right-4 "+additionalClasses} onClick={clickHandler}>
                <FontAwesomeIcon className={`transition-all duration-500 hover:rotate-[180deg]`} icon={faXmark}/> 
            </button>
        </>
    )
}