import React from 'react';
import { UseContextClues } from '../../context/ContextClues';
import { UseToggleContext } from '../../context/ToggleContexts';
import GenericTable from './GenericTable';

export default function CrystalBallSharesTables({tableRef, calculation, breakEvenGrid }) {
    
    const context = UseContextClues();
    const toggler = UseToggleContext();

    const numberStyle = "text-right pr-2";
    const rowStyles = "border h-10 tracking-tight";

    return (
        <>
            {toggler.toggle.breakEven&&<div ref={ref => tableRef.current.sharesTables = ref} className={`w-full  border border-t-4   bg-white grid ${breakEvenGrid?"grid-cols-5":"grid-cols-2"}`}>
                <div className='px-4 pb-4 overflow-y-auto relative scrollbar-hide'>
                    <div className='sticky py-4 z-[10] bg-white top-0 text-lg font-medium whitespace-nowrap tracking-tighter '>
                        Manufacturer Market Share
                    </div>
                    <table className='w-full relative text-sm'>
                        <thead className=' bg-white '>
                            <tr>
                                <th className='text-left'>
                                    Manufacturer
                                </th>
                                <th className='text-right whitespace-nowrap'>
                                    Market Share %
                                </th>
                            </tr>
                        </thead>
                        <tbody className='border '>
                            {context.crystal.mfr_grid.map((mfr, idx) => (
                                <tr key={`Breakeven index: ${idx} - manufacturer: ${mfr}`} className={rowStyles}>
                                    <td className={"pl-2"}>
                                        {mfr.mfr}
                                    </td>
                                    <td className={numberStyle}>
                                        {parseFloat(mfr.marketshare).toLocaleString('en-US', { style: 'percent', minimumFractionDigits: 2 })}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                

                <div className={`${breakEvenGrid&&"border-r-4"}  p-4 pt-[42px] overflow-y-auto  scrollbar-hide text-sm`}>
                    <GenericTable>
                        <tr className={rowStyles}>
                            <td className='pl-2'>
                                Current WAC
                            </td>
                            <td className={numberStyle}>
                                {parseFloat(calculation.current?.wac).toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0, minimumFractionDigits: 0 })}
                            </td>
                        </tr>
                        <tr className={rowStyles}>
                            <td className='pl-2'>
                                Current Rebate
                            </td>
                            <td className={numberStyle}>
                                {parseFloat(calculation.current?.rebate).toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0, minimumFractionDigits: 0 })}
                            </td>
                        </tr>
                        <tr className={rowStyles}>
                            <td className='pl-2'>
                                Current Net Cost
                            </td>
                            <td className={numberStyle}>
                                {parseFloat(calculation.current?.netCost).toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0, minimumFractionDigits: 0 })}
                            </td>
                        </tr>
                    </GenericTable>
                </div>

                {/*  breakeven */}

                {breakEvenGrid&&<>
                    <div className='px-4 pb-4 overflow-y-auto relative text-sm scrollbar-hide'>
                        <div className='sticky py-4 bg-white top-0 pt-4 text-lg font-medium whitespace-nowrap tracking-tighter'>
                            Modeled Market Share
                        </div>
                        <GenericTable {...{
                            headers: [{content: "Manufacturer", class: "text-left "}, {content: "Market Share %", class: "text-right whitespace-nowrap"}]}}
                            >
                                {breakEvenGrid.mfr_grid.map((mfr, idx) => (
                                <tr key={`${idx} - breakeven market mfr: ${mfr}`} className={`${rowStyles} overflow-y-auto`}>
                                    <td className={"pl-2"}>
                                        {mfr.mfr}
                                    </td>
                                    <td className={numberStyle}>
                                        {parseFloat(mfr.marketshare).toLocaleString('en-US', { style: 'percent', minimumFractionDigits: 2 })}
                                    </td>
                                </tr>
                            ))}
                        </GenericTable>
                    </div>
                    <div className='p-4 pt-[42px] text-sm overflow-y-auto  scrollbar-hide' >
                        <GenericTable>
                            <tr className={rowStyles}>
                                <td className='pl-2'>
                                    Modeled WAC
                                </td>
                                <td className={numberStyle}>
                                    {parseFloat(calculation.breakeven.wac).toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0, minimumFractionDigits: 0 })}
                                </td>
                            </tr>
                            <tr className={rowStyles}>
                                <td className='pl-2'>
                                    Modeled Rebate
                                </td>
                                <td className={numberStyle}>
                                    {parseFloat(calculation.breakeven.rebate).toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0, minimumFractionDigits: 0 })}
                                </td>
                            </tr>
                            <tr className={rowStyles}>
                                <td className='pl-2'>
                                    Modeled Net Cost
                                </td>
                                <td className={numberStyle}>
                                    {parseFloat(calculation.breakeven.netCost).toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0, minimumFractionDigits: 0 })}
                                </td>
                            </tr>
                            <tr className={`${rowStyles} bg-primary-dark text-white font-extrabold`}>
                                <td className='pl-2'>
                                    Modeled Shift
                                </td>
                                <td className={numberStyle}>
                                    {parseFloat(breakEvenGrid.breakeven_shift).toLocaleString('en-US', { style: 'percent', minimumFractionDigits: 2 })}
                                </td>
                            </tr>
                        </GenericTable>
                    </div>

                    <div className='px-4 pb-4 pt-[42px] border-r overflow-y-auto  scrollbar-hide text-sm' >
                        <GenericTable>
                            <tr className={rowStyles}>
                                <td className='pl-2'>
                                    Δ WAC
                                </td>
                                <td className={numberStyle}>
                                    {parseFloat(calculation.breakeven.wac-calculation.current?.wac).toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0, minimumFractionDigits: 0 })}
                                </td>
                            </tr>
                            <tr className={rowStyles}>
                                <td className='pl-2'>
                                    Δ Rebate 
                                </td>
                                <td className={numberStyle}>
                                    {parseFloat(calculation.breakeven.rebate-calculation.current?.rebate).toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0, minimumFractionDigits: 0 })}
                                </td>
                            </tr>
                            <tr className={rowStyles}>
                                <td className='pl-2'>
                                    Δ Net Cost
                                </td>
                                <td className={numberStyle}>
                                    {parseFloat(calculation.breakeven.netCost-calculation.current?.netCost).toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0, minimumFractionDigits: 0 })}
                                </td>
                            </tr>
                        </GenericTable>
                    </div>
                </>}
            </div>}
        </>
    );
};