import React, { useEffect } from "react";
import { UseContextClues } from '../../context/ContextClues';
import { UseToggleContext } from '../../context/ToggleContexts';
import CountiesMap from "../Map/CountiesMap";
import { globalBoolHandler } from "../../utils/globalHandlers";
import SimpleLineChart from "../Charts/SimpleLineChart";


export default function DataMetricsDynamic({setVCWindow, chartData, addData}) {

    const context = UseContextClues();
    const toggler = UseToggleContext();

    useEffect(() => {
        if (context.mainData?.volume_comparison?.comparison) {
            setVCWindow(true);
        } else setVCWindow(false);
    }, [context.mainData?.volume_comparison, setVCWindow]);

    
    const nameStyle = "border py-2 pl-3";
    const unitStyle = "border pl-3";
    const rangeStyle = "text-center border font-medium";

    function colorPick(lvl) {
        if (lvl === "Very High") {
            return 'bg-primary-red'
        } else if (lvl === 'High') {
            return 'bg-light-red'
        } else if (lvl === 'Normal') {
            return 'bg-light-green'
        } else if (lvl === 'Low') {
            return "bg-light-blue"
        } else if (lvl === 'Very Low') {
            return "bg-hover-blue text-white"
        } else {
            return 'bg-white'
        };
    };

    const legendColors = [
        "bg-[#8DF0B7] text-primary-dark",
        "bg-[#6EEABA] text-primary-dark",
        "bg-[#50E3C6] text-primary-dark",
        "bg-[#50E3C6] text-primary-dark",
        "bg-[#33DCD9] text-primary-dark",
        "bg-[#17B3D3]",
        "bg-[#117ABC]",
        "bg-[#0C49A3]",
        "bg-[#08218A]",
        "bg-[#070570]",
    ];

    const regexPattern = /[+-]?([0-9]*[.])?[0-9]+/;

    return (
        <>
            <table className=" px-8 w-full text-sm xl:text-base bg-white">
                <thead>
                    {chartData.currentView==="main"&&<tr className="text-left sticky top-0 bg-background-light">
                        <th>DATA</th>
                        <th>UNIT</th>
                        <th className="text-center">RANGE</th>
        
                    </tr>}
                </thead>
                <tbody>
                    {chartData.currentView==="main"&&<>
                        {(context.mainData.newkpis).map(content => (
                            content.name!=="brand_days_per_claim_by_channel"&&<tr key={`Table ${content.name.split("_").join(" ")} ${content.value}`}>
                                <td className={nameStyle+" capitalize"}>{content.name.split("_").join(" ")}: </td>
                                <td className={unitStyle}>{regexPattern.test(content.value)?(content.name.slice(-4)==="_pct"?content.value.toLocaleString(undefined, {style: 'percent', minimumFractionDigits: 3, maximumFractionDigits: 3 }):content.name.slice(-4)==="laim"?new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(content.value) :content.value.toLocaleString('en-US', {maximumFractionDigits: 3})):""}</td>
                                <td className={rangeStyle+" "+colorPick(content.signal)}>{content.signal}</td>
                            </tr>
                        ))}
                    </>}
                    {chartData.currentView==="avgWAC"&&<div className="relative">                      
                        <SimpleLineChart {...{data: chartData.movingAvgWAC}} />
                    </div>}
                    {chartData.currentView==="countiesMap"&&<tr>
                        <td style={{height: toggler.pageHeight-240}} colSpan={3} className="rounded-3xl w-full h-full relative  bg-primary-dark pb-10" >
                            <button className="absolute top-[20px] right-[20px] text-white hover:text-light-blue capitalize" onClick={() => globalBoolHandler("currentView", chartData, addData, 'main')}>
                                view data
                            </button>
                            <CountiesMap />
                            <div className=" text-white flex justify-center mt-4">
                                {legendColors.map((color, idx) => (
                                    <div key={idx+". color legend : "+color} className={color+" px-2 py-1 border"}>
                                        {(10*idx)}%
                                        <br/>
                                        {10*(idx+1)-1}%
                                    </div>
                                ))}
                            </div>
                        </td>
                    </tr>}
                </tbody>
            </table>
        </>
    )
}