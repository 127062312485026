import { extractFromLocal } from "./loadSave";

export async function getCreditlists(set) {
    try {
        const data = await fetch(`/api/get-creditlists/`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json', 
                Authorization: `Bearer ${extractFromLocal()?.accessToken}`
            }
        });
        const results = await data.json();

        if (data.ok) {
            set(data => ({
                ...data,
                creditlist: results.lists
            }));
        }
    } catch(err) {
        return err;
    };
};

export async function forecastCredits(listname, scenario, casenumber, set, load) {
    load(true);
    try {
        const data = await fetch(`/api/forecast_credits?listname=${listname}&scenario=${scenario}&casenumber=${casenumber}`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json', 
                Authorization: `Bearer ${extractFromLocal()?.accessToken}`
            }
        });
        const results = await data.json();
        
        if (data.ok) {
            set(data => ({
                ...data,
                forecastingData: {
                    ...data.forecastingData,
                    [scenario]: results
                }
            }));
            load(false);
        }
    } catch(err) {
        load(false);
        return err;
    };
};

export async function createRebateCredit(payload, listname, currentList, set, change) {
    try {
        const data = await fetch(`/api/create-rebate-credit/`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json', 
                Authorization: `Bearer ${extractFromLocal()?.accessToken}`
            },
            body: JSON.stringify(payload)
        });
        const results = await data.json();

        if (data.ok) {
            if (currentList.includes(listname)) {
                set(data => ({
                    ...data,
                    creditlist: {
                        ...data.creditlist,
                        [listname]: [
                            ...data.creditlist[listname],
                            results 
                        ]
                    }
                }));
            } else {
                set(data => ({
                    ...data,
                    creditlist: {
                        ...data.creditlist,
                        [listname]: [
                            results 
                        ]
                    }
                }));
            }
            change("list");
        }
    } catch(err) {
        return err;
    };
};


export async function deleteRebateCredit(creditId, listObject, listname, set, change) {
    try {
        const data = await fetch(`/api/delete-rebate-credit/${creditId}`, {
            method: "DELETE",
            headers: {
                'Content-Type': 'application/json', 
                Authorization: `Bearer ${extractFromLocal()?.accessToken}`
            }
        });
        const results = await data.json();

        if (data.ok) {
            let current = [ ...listObject[listname] ];
            if (current.length===1&&current[0].id===creditId) {
                let currentObj = {...listObject};
                delete currentObj[listname];
                console.log(currentObj)
                set(data => ({
                    ...data,
                    creditlist: currentObj,
                    creditSelect: null,
                }));
                change('list')
            } else {
                set(data => ({
                    ...data,
                    creditlist: {
                        ...data.creditlist,
                        [listname]: current.filter(credit => credit.id !== creditId)
                    }
                }));
            };
            console.log(results);
        };
    } catch(err) {
        return err;
    };
};


export async function updateRebateCredit(creditId, payload, previous, listname, set, change) {
    try {
        const data = await fetch(`/api/update-rebate-credit/${creditId}`, {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json', 
                Authorization: `Bearer ${extractFromLocal()?.accessToken}`
            },
            body: JSON.stringify(payload)
        });
        const results = await data.json();

        if (data.ok) {
            let current = [...previous];

            set(data => ({
                ...data,
                creditlist: {
                    ...data.creditlist,
                    [listname]: current.map(details=>details.id!==results.id?details:results)
                }
            }));
            change("list");
        };
    } catch(err) {
        return err;
    };
};

