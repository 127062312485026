import React from 'react';
import { formatCurrency } from '../../utils/analytics';
import { formatDecimal } from '../../utils/resultsSummary';

export default function AnalyticsRebatesTable({tableStyles, headerStyles, context, type }) {
    return (
        <>
             <table className={tableStyles}>
                <thead>
                    <tr>
                        <td className={headerStyles}>
                            product name
                        </td>
                        <td className={headerStyles}>
                            category
                        </td>
                        <td className={headerStyles+" text-right"}>
                            Year 1 WAC
                        </td>
                        <td className={headerStyles+" normal-case text-right"}>
                            % of Category Market Share
                        </td>
                        <td className={headerStyles+" text-right"}>
                            Rx Volume
                        </td>
                    </tr>
                </thead>
                <tbody>
                    {context.analytics.highestProductsNoRebatesTable[type]?.map((row, idx) => (
                        <tr key={`Highest Product No Rebate key: ${row.drugname}, index: ${idx}`}>
                            <td>
                                {row.drugname}
                            </td>
                            <td>
                                {row.category}
                            </td>
                            <td className='text-right'>
                                {formatCurrency(row.value)}
                            </td>
                            <td className='text-right'>
                                {(row.category_market_share).toFixed(1)}%
                            </td>
                            <td className='text-right'>
                                {formatDecimal(row.rxvolume)}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    )
};