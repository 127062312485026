import React, { useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import HandleOutsideClick from '../../hooks/HandleOutsideClick';

export default function CustomFormulary({data, value, name, clickHandle, edit, editEdit, dropHeight}) {

    const ref = useRef(null);
    const [ dropped, toggleDrop ]= useState(false);
    const focused = HandleOutsideClick(ref, toggleDrop);

    function handleClick(name, id) {
        clickHandle(name, id)
        toggleDrop(false);
    };

    function handleOpen(e) {
        e.preventDefault();
        toggleDrop(!dropped);
    };
    
    function handleEdit(content) {
        editEdit(content);
        toggleDrop(false);
    };

    return (
        <>
            <div className='relative' ref={ref} >
                <div className={`border px-2 mt-2 mb-4  rounded-lg py-1 w-full flex items-center justify-between`} onClick={handleOpen}>
                    <span className='h-[40px] py-3'>{value}  </span>
                    <FontAwesomeIcon className={`float-right`} icon={faChevronDown}/> 
                </div>
                {dropped&&!focused&&<ul className={`absolute mt-[-1rem] border ${dropHeight?dropHeight:"max-h-[400px]"} bg-white overflow-y-auto min-h-[150px] w-full mr-4 rounded-b-lg shadow-xl cursor-pointer z-[220] `}>
                    {data&&data.map((content, idx) => (
                        <li className='flex '>
                            <div key={`${idx}: ${name} custom selector ${content.formularyid}`} onClick={() => handleClick(name, content.formularyid)} className='relative w-full py-3 border hover:bg-primary-dark hover:text-white px-2'>
                                <div className='flex justify-between'>
                                    <span className='font-bold pr-10'>{content.formularyid}</span>
                                    <span className='font-medium'>{content.lastmodified?content.lastmodified.substring(0,10):null}</span>
                                </div>
                                <div className=''>
                                    <div className='text-[0.7rem] font-light'>{content.description} <span className='text-light-blue'>{content.biosimilar_position}</span></div>
                                </div>
                            </div>
                            {edit&&<button onClick={() => handleEdit(content)} className='float-right w-[150px] border bg-primary-yellow'>
                                Edit
                            </button>}
                        </li>
                    ))}
                </ul>}
            </div>
        </>
    );
};