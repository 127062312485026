import React, { useEffect, useState } from 'react';
import CollapsibleButton from '../Buttons/CollapsibleButton';
import { UseContextClues } from '../../context/ContextClues';
import { UseToggleContext } from '../../context/ToggleContexts';
import { globalBoolHandler, handleCustomListChange, handleExclusionChange, handleToggle } from '../../utils/globalHandlers';
import FormButton from '../Buttons/FormButton';
import Select from 'react-select'

export default function ContractDefintion() {

    const id = "contractDefintions";

    const context = UseContextClues();
    const toggler = UseToggleContext();
    
    const [ defaultList, setDefaults ] = useState({
        exclusions : {},
        constraint : {},
    });

    const exclusion = context.exclusionSettings;

    useEffect(() => {
        var constraints = context.exclusionSettings.custom_lists?.constraint.map(ele => ({label: ele, value: ele}))

        if (constraints)setDefaults(data => ({...data, constraint: constraints}))

    }, [context.exclusionSettings.custom_lists.constraint ])
    useEffect(() => {
        var exclusions = context.exclusionSettings.custom_lists?.exclusion.map(ele => ({label: ele, value: ele}))

        if (exclusions)setDefaults(data => ({...data, exclusions: exclusions}))
    }, [context.exclusionSettings.custom_lists.exclusion ])



    const labelStyle = "font-bold ";
    const inputStyle = "font-light w-full border py-2 px-4 mt-2 mb-2 rounded-lg";
    const checkboxStyles = "mr-2 "

    return (
        <div id={id}>
            <CollapsibleButton useName={id} useValue={toggler.collapsible[id]} buttonTitle={'Contractual Definition'} onClick={e => handleToggle(e, toggler)}  chevron={toggler?.collapsible[id]}/>
            <div className={` transition-all duration-700 px-4 text-xs border-b border-primary-dark ${toggler.collapsible[id] ? "opacity-1 pb-3 h-full" : "opacity-0 overflow-hidden max-h-0 z-200 "}`}>
                
                {/* <!-- contractual definitions --> */}
                <div id="contractualDefinitions" >

                    <label className={labelStyle} htmlFor="brandDefinition">Brand Definition:
                        <select className={inputStyle} id="brandDefinition" value={exclusion.brandDefinition} name="brandDefinition" onChange={e => handleExclusionChange(e, context)}>
                            <option value="mnoBt">MNO/BT</option>
                            <option value="mno">MNO</option>
                        </select>
                    </label>
                    
                    <label className={labelStyle} htmlFor="otcDefinition">OTC Definition:
                        <select className={inputStyle} value={exclusion.otcDefinition} id="otcDefinition" name="otcDefinition" onChange={e => handleExclusionChange(e, context)}>
                            <option value="medispanOpLessDiabeticSupplies">Medispan OP less diabetic supplies</option>
                            <option value="medispanOpLessTestStrips">Medispan OP less test strips</option>
                            <option value="medispanOp">Medispan OP</option>
                        </select>
                    </label>

                    <div className='font-bold mt-3 mb-2'>Exclusions:</div>
                    <div className='grid grid-cols-2 gap-x-6 mb-2'>         
                        <div>
                            <input className={checkboxStyles} type="checkbox" id="exclusionLdd" name="exclusionLdd"  checked={exclusion.exclusions.exclusionLdd} onChange={e => handleExclusionChange(e, context)}/>
                            <label htmlFor="exclusionLdd">LDD</label>
                        </div>
                        <div>
                            <input className={checkboxStyles} type="checkbox" id="exclusionVaccines" name="exclusionVaccines" checked={exclusion.exclusions.exclusionVaccines} onChange={e => handleExclusionChange(e, context)}/>
                            <label htmlFor="exclusionVaccines">Vaccines</label>
                        </div>
                        <div className='whitespace-nowrap'>
                            <input className={checkboxStyles} type="checkbox" id="exclusionAuthGenerics" name="exclusionAuthGenerics" checked={exclusion.exclusions.exclusionAuthGenerics}  onChange={e => handleExclusionChange(e, context)}/>
                            <label className='' htmlFor="exclusionAuthGenerics">Authorized Generics</label>
                        </div>
                        <div>
                            <input className={checkboxStyles} type="checkbox" id="exclusionVa" name="exclusionVa" checked={exclusion.exclusions.exclusionVa} onChange={e => handleExclusionChange(e, context)}/>
                            <label htmlFor="exclusionVa">VA</label>
                        </div>
                        <div>
                            <input className={checkboxStyles} type="checkbox" id="exclusionItu" name="exclusionItu" checked={exclusion.exclusions.exclusionItu} onChange={e => handleExclusionChange(e, context)}/>
                            <label htmlFor="exclusionItu">ITU</label>
                        </div>
                        <div>
                            <input className={checkboxStyles} type="checkbox" id="exclusionLtc" name="exclusionLtc" checked={exclusion.exclusions.exclusionLtc} onChange={e => handleExclusionChange(e, context)}/>
                            <label htmlFor="exclusionLtc">LTC</label>
                        </div>
                        <div>
                            <input className={checkboxStyles} type="checkbox" id="exclusionHomeInfusion" name="exclusionHomeInfusion" checked={exclusion.exclusions.exclusionHomeInfusion} onChange={e => handleExclusionChange(e, context)}/>
                            <label htmlFor="exclusionHomeInfusion">Home Infusion</label>
                        </div>
                        <div>
                            <input className={checkboxStyles} type="checkbox" id="exclusionOTC" name="exclusionOTC" checked={exclusion.exclusions.exclusionOTC} onChange={e => handleExclusionChange(e, context)}/>
                            <label htmlFor="exclusionOTC">OTC</label>
                        </div>
                        <div>
                            <input className={checkboxStyles} type="checkbox" id="exclusion340b" name="exclusion340b" checked={exclusion.exclusions.exclusion340b} onChange={e => handleExclusionChange(e, context)}/>
                            <label htmlFor="exclusion340b">340B</label>
                        </div>
                        <div>
                            <input className={checkboxStyles} type="checkbox" id="exclusionCosmetic" name="exclusionCosmetic" checked={exclusion.exclusions.exclusionCosmetic} onChange={e => handleExclusionChange(e, context)}/>
                            <label htmlFor="exclusionCosmetic">Cosmetic</label>
                        </div>
                        <div>
                            <input className={checkboxStyles} type="checkbox" id="exclusionRepack" name="exclusionRepack" checked={exclusion.exclusions.exclusionRepack} onChange={e => handleExclusionChange(e, context)}/>
                            <label htmlFor="exclusionRepack">Repackager</label>
                        </div>
                        <div>
                            <input className={checkboxStyles} type="checkbox" id="exclusionInstitutional" name="exclusionInstitutional" checked={exclusion.exclusions.exclusionInstitutional} onChange={e => handleExclusionChange(e, context)}/>
                            <label htmlFor="exclusionInstitutional">Institutional</label>
                        </div>
                        <div>
                            <input className={checkboxStyles} type="checkbox" id="exclusionMCO" name="exclusionMCO" checked={exclusion.exclusions.exclusionMCO} onChange={e => handleExclusionChange(e, context)}/>
                            <label htmlFor="exclusionMCO">MCO</label>
                        </div>
                        <div>
                            <input className={checkboxStyles} type="checkbox" id="exclusionClinic" name="exclusionClinic" checked={exclusion.exclusions.exclusionClinic} onChange={e => handleExclusionChange(e, context)}/>
                            <label htmlFor="exclusionClinic">Clinic</label>
                        </div>
                        <div>
                            <input className={checkboxStyles} type="checkbox" id="exclusionNuclear" name="exclusionNuclear" checked={exclusion.exclusions.exclusionNuclear} onChange={e => handleExclusionChange(e, context)}/>
                            <label htmlFor="exclusionNuclear">Nuclear</label>
                        </div>
                        <div>
                            <input className={checkboxStyles} type="checkbox" id="exclusionMilitary" name="exclusionMilitary" checked={exclusion.exclusions.exclusionMilitary} onChange={e => handleExclusionChange(e, context)}/>
                            <label htmlFor="exclusionMilitary">Military</label>
                        </div>
                        <div>
                            <input className={checkboxStyles} type="checkbox" id="exclusionCovidDrugs" name="exclusionCovidDrugs" checked={exclusion.exclusions.exclusionCovidDrugs} onChange={e => handleExclusionChange(e, context)}/>
                            <label htmlFor="exclusionCovidDrugs">Covid Drugs</label>
                        </div>
                        <div>
                            <input className={checkboxStyles} type="checkbox" id="exclusionDAW3456" name="exclusionDAW3456" checked={exclusion.exclusions.exclusionDAW3456} onChange={e => handleExclusionChange(e, context)}/>
                            <label htmlFor="exclusionDAW3456">DAW 3456</label>
                        </div>
                        <div>
                            <input className={checkboxStyles} type="checkbox" id="exclusionCompounds" name="exclusionCompounds" checked={exclusion.exclusions.exclusionCompounds} onChange={e => handleExclusionChange(e, context)}/>
                            <label htmlFor="exclusionCompounds">Compounds</label>
                        </div>
                        <div>
                            <input className={checkboxStyles} type="checkbox" id="exclusionVitamins" name="exclusionVitamins" checked={exclusion.exclusions.exclusionVitamins} onChange={e => handleExclusionChange(e, context)}/>
                            <label htmlFor="exclusionVitamins">Vitamins</label>
                        </div>
                        <div>
                            <input className={checkboxStyles} type="checkbox" id="exclusionNonFdaDrugs" name="exclusionNonFdaDrugs" checked={exclusion.exclusions.exclusionNonFdaDrugs} onChange={e => handleExclusionChange(e, context)}/>
                            <label htmlFor="exclusionNonFdaDrugs">Non FDA Drugs</label>
                        </div>
                        <div>
                            <input className={checkboxStyles} type="checkbox" id="exclusionMSB" name="exclusionMSB" checked={exclusion.exclusions.exclusionMSB} onChange={e => handleExclusionChange(e, context)}/>
                            <label htmlFor="exclusionMSB">Multisource <br/>Brands - O</label>
                        </div>
                    </div>
                    <label className='capitalize '>custom list exclusions
                        <Select 
                            isMulti
                            options={context.models.customList.map((value, idx) => ({
                                value: `${value.list_name}`,
                                label: `${idx+1}: ${value.list_name}`,
                                toString: () => value.id
                            }))}
                            onChange={e => handleCustomListChange(e, 'exclusion', context.setExclusionSettings, context.setSettings)}
                            name='customListExclusion'
                            menuShouldScrollIntoView={false}
                            className='mb-2 mt-1'
                            menuPosition='fixed'
                            value={defaultList.exclusions}
                            menuPortalTarget={document.body} 
                            styles={{ menuPortal: base => ({ ...base, zIndex: 500, fontSize: "x-small"  }) }}
                        />
                    </label>
                    <label className='capitalize'>custom list constraints
                        <Select 
                            isMulti
                            options={context.models.customList.map((value, idx) => ({
                                value: `${value.list_name}`,
                                label: `${idx+1}: ${value.list_name}`,
                                toString: () => value.id
                            }))}
                            onChange={e => handleCustomListChange(e, 'constraint', context.setExclusionSettings, context.setSettings)}
                            menuShouldScrollIntoView={false}
                            name='customListConstraint'
                            className='mt-1'
                            menuPosition='fixed'
                            value={defaultList.constraint}
                            menuPortalTarget={document.body} 
                            styles={{ menuPortal: base => ({ ...base, zIndex: 500, fontSize: "x-small", }) }}
                        />
                    </label>
                </div>
                <FormButton buttonText={'user defined lists'} typeB={true} onClick={() => globalBoolHandler("userDefinedLists", toggler.toggle, toggler.setToggle, !toggler.toggle.userDefinedLists)} />
            </div>
        </div>
    )
};