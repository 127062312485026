import React, { useEffect, useRef, useState } from 'react';
import { extractFromLocal } from '../../utils/loadSave';

export default function Timer() {

    const loggedTime = new Date(extractFromLocal().loggedInAt);
    const currentTime = new Date();

    const checkNumber = 1800+((loggedTime-currentTime)/1000);
    
    const [ remaining, decrease ] = useState(checkNumber>300?300:checkNumber);
    const timerRef = useRef(null);

    useEffect(() => {
        const timer = setInterval(() => {
            decrease(remaining-1)
        }, 1000)
        return () => clearInterval(timer)
    }, [remaining]);
    
    return (
        <div ref={timerRef} className='text-white'>
            {Math.floor(remaining/60)}:{String(Math.floor(remaining % 60)).length<2?"0"+Math.floor(remaining % 60):Math.floor(remaining % 60)}
        </div>
    )
};