import React from 'react';

import { leapfrog } from 'ldrs'
import { UseToggleContext } from '../../context/ToggleContexts';

leapfrog.register();

export default function Loader({textContent, loading, addClass, custom, loadStyle}) {

    const toggler = UseToggleContext();

    return (
        <>
             <div className={`relative font-medium text-xs ${addClass}`}>
                <div style={{height: custom&&(toggler.pageHeight-380)/2 }}  className={`${custom&&"border rounded-xl border-dashed flex items-center justify-center"} p-4`}>
                    {!loading?textContent:<div style={loadStyle} className='flex items-center justify-center'><l-leapfrog color="black"></l-leapfrog></div>}
                </div>
                
            </div>
        </>
    )
};