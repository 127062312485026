import React, { useEffect } from 'react';
import AuxButton from '../Buttons/AuxButton';
import { UseContextClues } from '../../context/ContextClues';
import { UseUserContext } from '../../context/UserContext';
import { UseToggleContext } from '../../context/ToggleContexts';



export default function AuxPanel({handleToggle}) {
    
    const account = UseUserContext();
    const context = UseContextClues();
    const toggler = UseToggleContext();

    useEffect(() => {
        const handleEsc = (event) => {
            if (event.key === 'Escape') {
                toggler.switchAux("")
            }
        };
        window.addEventListener('keydown', handleEsc);
     
        return () => {
           window.removeEventListener('keydown', handleEsc);
        };
    });

    return (
        <>
            <div style={{height: toggler.pageHeight-100}} className='absolute right-0 top-0 z-[100] w-10 whitespace-nowrap border-l bg-background-light overflow-y-auto overflow-x-hidden'>
                <div className='rotate-90 mt-0 '>
                    <AuxButton onClick={handleToggle} buttonText={"Analytics & Drivers"} toggler={toggler.aux} handler={"analytics"} width={190} disabled={!context.results||!context.target}  />
                    {(account.user?.userData.user_type==="admin"||account.user?.userData.user_type==="superadmin"||account.user?.userData.user_type==="trade_user"||account.user?.userData.user_type==="client_mgr")&&   
                        <>
                            <AuxButton onClick={handleToggle} buttonText={"Rates"} toggler={toggler.aux} handler={"rates"} width={100}/>
                            <AuxButton onClick={handleToggle} buttonText={"Events"} toggler={toggler.aux} handler={"events"} width={100}/>
                        </>
                    }
                    <AuxButton onClick={handleToggle} buttonText={"Pricing Lens"} toggler={toggler.aux} handler={"pricingLens"} width={125} disabled={!context.results||!context.target}/>
                    <AuxButton onClick={handleToggle} buttonText={"Crystal Ball"} toggler={toggler.aux} handler={"crystal"} width={170} end disabled={!context.mainData?.success||!context.results||!context.target} />
                </div>
            </div>
        </>
    )
}