import React, { useState }  from "react";
import { UseUserContext } from "../context/UserContext";
import AccountSettings from "../components/Settings/AccountSettings";
import Contact from "../components/Settings/Contact";
import SignUpForm from "../components/Forms/SignUpForm";
import { globalChangeHandler } from "../utils/globalHandlers";
import OrgSettings from "../components/OrgSettings/OrgSettings";
import TwoFactorAuth from "../components/Settings/TwoFactorAuth";

export default function Settings() {

    const account = UseUserContext();

    const [ toggle, setTogg ] = useState({
        password: false,
        authenticated: false,
        currentPanel: "account"
    });

    const buttonStyles = "text-left capitalize hover:text-light-blue pt-8"

    return (
        <>
            <div className="grid grid-cols-[1fr,3fr] px-20 pt-20 max-w-[1340px] mx-auto ">
                <div>
                    <h2 className="text-[40px] font-bold border-r h-[400px] tracking-wider">
                        Settings
                        <div className="text-sm pt-4 capitalize flex flex-col ">
                            <button name="currentPanel" value="account" onClick={(e) => globalChangeHandler(e, null, setTogg)} className={buttonStyles}>
                                account settings
                            </button>
                            {account.user.userData.user_type==="superadmin"&&<button name="currentPanel" value="signUp" onClick={(e) => globalChangeHandler(e, null, setTogg)} className={buttonStyles}>
                                add a new user
                            </button>}
                            {account.user.userData.user_type==="client_mgr"&&<button name="currentPanel" value="org" onClick={(e) => globalChangeHandler(e, null, setTogg)} className={buttonStyles}>
                                Organization Settings
                            </button>}
                            <button name="currentPanel" value="contact" onClick={(e) => globalChangeHandler(e, null, setTogg)} className={buttonStyles}>
                                contact us
                            </button>
                        </div>
                    </h2>
                </div>

                {/* ----------------------------------------------------- start settings panal ----------------------------------------------------- */}

                {toggle.currentPanel==="account"&&<AccountSettings {...{toggle, setTogg}} />}

                {toggle.currentPanel==="contact"&&<Contact />}

                {toggle.currentPanel==="signUp"&&<SignUpForm />}

                {toggle.currentPanel==="org"&&<OrgSettings />}

                {/* ----------------------------------------------------- end settings panal ----------------------------------------------------- */}

            </div>
            {!toggle.authenticated&&toggle.password&&account.user.userData.twofa_enabled&&<>
                <TwoFactorAuth {...{ setTogg }} />
            </>}
        </>
    );
};