import React, { useState } from 'react';
import FormButton from "../Buttons/FormButton";
import { Link, useNavigate } from "react-router-dom";
import { UseContextClues } from '../../context/ContextClues';
import { UseUserContext } from '../../context/UserContext';
import { UseToggleContext } from '../../context/ToggleContexts';
import { login } from '../../utils/accounts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { globalBoolHandler, handleGlobalClear } from '../../utils/globalHandlers';
import CustomHover from '../Hover/CustomHover';

export default function LoginForm({height, addXMark, authenticate2FA, user, setUsername}) {

    const navigate = useNavigate()
    const account = UseUserContext();
    const context = UseContextClues();
    const toggler = UseToggleContext();

    const [pass, setPass] = useState();
    const [loading, setLoading] = useState(false);
    const [ message, setMessage ] = useState("");

    const labelStyles = "flex flex-col";
    const inputStyles = "px-2 py-1 border rounded mt-2 mb-3";

    async function handleLogin(e){
        e.preventDefault();
        setMessage('');
        setLoading(true);
        const previousUser = account.user?.userData?.username
        
        const authenticated = await login(
            {
                "username": user,
                "password": pass,
                "scope": "me"
            },
            account,
            authenticate2FA
        );

        if (authenticated) {
            setMessage('');
            navigate('/');
            setLoading(false)
            // toggler.toggleAuxLogin(false);
            // toggler.toggleTime(false);

            globalBoolHandler("auxLogin", toggler.toggle, toggler.setToggle, false, "showTime", false)
            // globalBoolHandler("showTime", toggler.toggle, toggler.setToggle, false)

            if (previousUser !== user) {
                handleGlobalClear(context, toggler);
                context.setCase(null);
            };

        } else {
            setMessage('Your username and password combination may be incorrect, please try again.');
            setLoading(false);
        };
    };

    const [hover, setHover] = useState(false);

    function handleHover() {
        setHover(!hover)
        setTimeout(() => {setHover(false)}, 5000);
    };

    return (
        <>
            <form className={`flex flex-col justify-center items-center  ${height?height:"h-[100vh]"}`} onSubmit={handleLogin}>
                <div className="flex relative bg-primary-light flex-col border py-28 px-24 rounded-xl shadow-lg shadow-gray-600">
                    {addXMark&&<button aria-label='close panel' type='button' className={"absolute top-3 right-3 "} onClick={() => globalBoolHandler("auxLogin", toggler.toggle, toggler.setToggle, false)}>
                        <FontAwesomeIcon className={`text-sm transition-all duration-500 hover:rotate-[180deg] ${false ? "" : ""}`} icon={faXmark}/> 
                    </button>}
                    <div className="text-2xl font-medium pb-8">Log In</div>
                    <label htmlFor="username" className={labelStyles}>
                        Username<input name="username" className={inputStyles} type="text" autoComplete="username" aria-labelledby="Enter your username" onChange={e => setUsername(e.target.value)} required/>
                    </label>
                    <label htmlFor="password" className={labelStyles}>
                        Password<input name="password" className={inputStyles} type="password" autoComplete="current-passord" aria-labelledby="Enter your password for above username" onChange={e => setPass(e.target.value)} required/>
                    </label>
                    <div className={`${message.length>0?"my-2":''} text-primary-red max-w-[250px] text-xs`}>{message}</div>
                    {/* <label className={labelStyles}>
                        User<select name="scope" className={inputStyles} aria-labelledby="Enter admin privileges" required>
                            <option value='me'>staff</option>
                            <option value="admin">admin</option>
                        </select>
                    </label> */}
                    <button onClick={handleHover} type='button' disabled={hover} className="text-sm text-primary-dark font-light hover:underline underline-offset-2 decoration-inherit py-1 text-left ">Forgot password?</button>
                    <FormButton buttonText={"log in"} loading={loading} loadingSize={15} />
                    <div className='text-[0.6rem] mt-5 tracking-tighter'>
                        By logging in, you agree to our Terms of Services and <Link className='text-blue-500 underline underline-offset-2 hover:text-primary-blue' to={'/privacy'}>Privacy Policy</Link>
                    </div>
                    <CustomHover hovered={hover} bottom={'bottom-[240px]'} right={'right-[140px]'} alternativeBot={"absolute bottom-[-16px] left-[120px] w-3 overflow-hidden mx-auto rotate-[270deg] "} >
                        As a temporary solution, please send <br/>
                        all inquries to <Link to={"mailto:service@259rx.com"}>service@259rx.com</Link><br/>
                        We appreciate your patience!
                    </CustomHover>
                </div>
            </form>
        </>
    )
};