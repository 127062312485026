export default function Logo259({className, hovered}) {
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="50%" 
            height="50%" viewBox="0 0 600 498" space="preserve" className={className}>
            <g>
                <g>
                    <circle className="st0" fill={hovered?"#17B3D3":"#FFFFFF"} cx="298.4" cy="182.4" r="155.5"/>
                    <path className="st1" fill={hovered?"":"#00141C"} d="M421,143.6C399.6,76,327.2,38.4,259.6,59.8l-1.4-4.6C328.2,33,403.4,72,425.6,142.1L421,143.6z"/>
                    <path className="st1" fill={hovered?"":"#00141C"} d="M298.5,315.9c-56.6,0-109.3-36.4-127.3-93.1l4.6-1.4c21.4,67.6,93.8,105.2,161.5,83.8l1.5,4.6
                        C325.3,313.8,311.8,315.9,298.5,315.9z"/>
                    <g>
                        <path className="st2" fill={hovered?"#FFFFFF":"#17B3D3"} d="M419.2,227.8l-193.5,61.3c-42,13.3-86.8-10-100.1-51.9l0,0c-13.3-42,10-86.8,51.9-100.1l193.5-61.3
                            c42-13.3,86.8,10,100.1,51.9l0,0C484.5,169.7,461.2,214.5,419.2,227.8z"/>
                        <path className="st1" fill={hovered?"":"#00141C"} d="M395.2,72c33.8,0,65.2,21.7,76,55.7c13.3,42-10,86.8-51.9,100.1l-193.5,61.3c-8,2.5-16.1,3.7-24.1,3.7
                            c-33.8,0-65.2-21.7-76-55.7c-13.3-42,10-86.8,51.9-100.1l193.5-61.3C379.1,73.2,387.2,72,395.2,72 M395.2,67.2L395.2,67.2
                            c-8.6,0-17.2,1.3-25.6,4l-193.5,61.3c-21.5,6.8-39.1,21.6-49.5,41.6c-10.4,20-12.4,42.9-5.6,64.5c11.2,35.3,43.6,59,80.6,59
                            c8.6,0,17.2-1.3,25.5-4l193.5-61.3c21.5-6.8,39.1-21.6,49.5-41.6c10.4-20,12.4-42.9,5.6-64.5C464.6,91,432.2,67.2,395.2,67.2
                            L395.2,67.2z"/>
                    </g>
                    <path className="st1" fill={hovered?"":"#00141C"} d="M378,97.7l-96.8,30.7l-13.1,4.2L184.5,159c-29.8,9.4-46.4,41.4-36.9,71.2c9.4,29.8,41.4,46.4,71.2,36.9
                        l96.8-30.7L285.6,142l96.8-30.7c27.5-8.7,56.8,4.7,68.6,30.3c-0.4-2.3-0.9-4.6-1.7-6.9C439.8,104.8,407.9,88.3,378,97.7z"/>
                    <path className="st2" fill={hovered?"#FFFFFF":"#17B3D3"} d="M288.5,226l-75.2,23.8c-23.4,7.4-48.3-4.5-57.6-26.7c0.3,1.4,0.7,2.8,1.1,4.2c7.8,24.8,34.4,38.5,59.1,30.7
                        l75.2-23.8L288.5,226z"/>
                    <g>
                        <circle className="st0" fill={hovered?"#17B3D3":"#FFFFFF"} cx="258.8" cy="57.5" r="8.5"/>
                        <path className="st1" fill={hovered?"":"#00141C"} d="M258.8,68.4c-4.6,0-8.9-3-10.4-7.6c-0.9-2.8-0.6-5.7,0.7-8.3c1.3-2.6,3.6-4.5,6.4-5.3
                            c5.7-1.8,11.8,1.4,13.6,7.1l0,0c0.9,2.8,0.6,5.7-0.7,8.3c-1.3,2.6-3.6,4.5-6.4,5.3C261,68.2,259.9,68.4,258.8,68.4z M258.8,51.5
                            c-0.6,0-1.2,0.1-1.8,0.3c-1.5,0.5-2.8,1.6-3.5,3c-0.7,1.4-0.9,3.1-0.4,4.6c1,3.2,4.4,4.9,7.6,3.9c1.5-0.5,2.8-1.6,3.5-3
                            c0.7-1.4,0.9-3.1,0.4-4.6l0,0c-0.5-1.5-1.6-2.8-3-3.5C260.8,51.7,259.8,51.5,258.8,51.5z"/>
                    </g>
                    <g>
                        <circle className="st0" fill={hovered?"#17B3D3":"#FFFFFF"} cx="338" cy="307.3" r="8.5"/>
                        <path className="st1" fill={hovered?"":"#00141C"} d="M338,318.2c-4.6,0-8.9-3-10.4-7.6c-0.9-2.8-0.6-5.7,0.7-8.3c1.3-2.6,3.6-4.5,6.4-5.3
                            c2.8-0.9,5.7-0.6,8.3,0.7c2.6,1.3,4.5,3.6,5.3,6.4c0.9,2.8,0.6,5.7-0.7,8.3c-1.3,2.6-3.6,4.5-6.4,5.3
                            C340.2,318,339.1,318.2,338,318.2z M338,301.3c-0.6,0-1.2,0.1-1.8,0.3c-3.2,1-5,4.4-3.9,7.6c1,3.2,4.4,5,7.6,3.9
                            c1.5-0.5,2.8-1.6,3.5-3c0.7-1.4,0.9-3.1,0.4-4.6v0c-0.5-1.5-1.6-2.8-3-3.5C339.9,301.5,338.9,301.3,338,301.3z"/>
                    </g>
                </g>
                <polygon className="st2" fill={hovered?"#FFFFFF":"#17B3D3"} points="479.1,56.7 465.4,56.7 465.4,43.1 454.6,43.1 454.6,56.7 440.9,56.7 440.9,67.6 454.6,67.6 
                    454.6,81.3 465.4,81.3 465.4,67.6 479.1,67.6 		"/>
                <polygon className="st2" fill={hovered?"#FFFFFF":"#17B3D3"} points="495.9,22 489.3,22 489.3,15.4 484.1,15.4 484.1,22 477.5,22 477.5,27.2 484.1,27.2 484.1,33.8 
                    489.3,33.8 489.3,27.2 495.9,27.2 		"/>
                <polygon className="st2" fill={hovered?"#FFFFFF":"#17B3D3"} points="474,4.1 470,4.1 470,0 466.7,0 466.7,4.1 462.6,4.1 462.6,7.3 466.7,7.3 466.7,11.4 470,11.4 
                    470,7.3 474,7.3 		"/>
                <g>
                    <path className="st0" fill={hovered?"#17B3D3":"#FFFFFF"} d="M71.3,380.3c22.7,0,35.4,11.7,35.4,33.3c0,17.4-8.4,27.2-27.4,31.6l-49,11c-8.2,1.9-9.8,6.3-9.8,12.9v5.9
                        c0,1.9,0.7,2.4,2.3,2.4h80.7V498H9.8c-6.6,0-9.8-3.1-9.8-9.6v-17.8c0-19.2,5.8-29.3,25.8-33.8l49.5-11.3c8.4-1.9,11-5.9,11-11.5
                        c0-7.8-5.1-13.1-14.5-13.1h-68v-20.6H71.3z"/>
                    <path className="st0" fill={hovered?"#17B3D3":"#FFFFFF"} d="M220.6,380.3v20.6h-68.2c-1.6,0-2.3,0.5-2.3,2.1v21.4c0,1.4,0.7,2.1,2.3,2.1h34.9
                        c31.7,0,39.8,15.7,39.8,34.5c0,21.4-10.6,37-37.7,37h-59.3v-20.6h60c11.7,0,16.4-6.6,16.4-16.7c0-9.4-4.4-15.5-18.1-15.5h-49
                        c-6.6,0-9.8-3.1-9.8-9.6v-45.7c0-6.5,3.1-9.6,9.8-9.6H220.6z"/>
                    <path className="st0" fill={hovered?"#17B3D3":"#FFFFFF"} d="M303.8,380.3c27.6,0,46.4,16.4,46.4,57c0,41.2-19,60.7-53.2,60.7h-38.7v-20.6H297c18.8,0,29.1-8.2,31.9-27.7
                        h-49.3c-23.9,0-34.5-10.3-34.5-31.9c0-21.1,10.3-37.5,39.1-37.5H303.8z M281.6,433.1h47.8c-1.2-22.3-10.8-32.3-30.5-32.3h-16.4
                        c-11.7,0-17.4,7-17.4,17.1C265,428.4,271,433.1,281.6,433.1z"/>
                </g>
                <g>
                    <path className="st2" fill={hovered?"#FFFFFF":"#17B3D3"} d="M437.8,380.3c26.5,0,37.8,16.4,37.8,35.2c0,14.6-7,30.3-24.9,35.4l27.4,47.1h-22.8l-26.9-45.5h-30.7
                        c-1.9,0-3,0.9-3,3V498h-20.6v-46.6c0-13.2,5.6-18.7,18.5-18.7h45.9c11.7,0,16.4-8,16.4-16.4c0-8.4-5.1-15.5-16.4-15.5H374v-20.6
                        H437.8z"/>
                    <path className="st2" fill={hovered?"#FFFFFF":"#17B3D3"} d="M514.2,405.9l30.5,33.3l31.7-33.3h23l-43.2,45.9L600,498h-23.5L544,462.6L510.9,498h-23l44.8-48l-41.9-44.1
                        H514.2z"/>
                </g>
            </g>
        </svg>
    )
}