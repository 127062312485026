import React from 'react';
import Navbar from '../Navbar/Navbar';
import { Link } from 'react-router-dom';

export default function NotFound404 () {
    return (
        <>
            <Navbar />
            <div className=' '>
                <div className='lg:max-w-[1024px] px-4 lg:px-0 mx-auto  text-left h-[80vh]'>
                    <div className='text-6xl font-medium mb-2 mt-10 xl:mt-60'>
                        Something went wrong here.
                    </div>
                    <div className='text-xl'>
                        This is a 404 error, which means you've clicked on a bad link or entered an invalid URL. Try going back home by clicking on the logo or click <Link className='text-light-blue hover:text-hover-blue' to={'/'}>here</Link> 
                    </div>
                </div>
            </div>
        </>
    )
};