import React from 'react';
import PopUpSection from '../Section/PopUpSection';
import { globalBoolHandler } from '../../utils/globalHandlers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { UseToggleContext } from '../../context/ToggleContexts';

export default function SaveSaveAs({setLocation}) {

    const toggler = UseToggleContext();

    const butonStyles = "py-2 px-10 rounded bg-light-blue hover:bg-hover-blue text-white";

    return (
        <>
            <PopUpSection>
                <div className='relative w-[500px] h-[250px] bg-primary-dark mx-auto rounded-lg flex justify-center items-center gap-10'>
                        <button className='absolute text-white top-2 right-3' onClick={() =>  globalBoolHandler("saveMemo", toggler.toggle, toggler.setToggle, false)}>
                            <FontAwesomeIcon className='hover:rotate-[180deg] duration-500' icon={faXmark}/>
                        </button>
                        <button onClick={() => setLocation(2)} className={butonStyles}>
                            save
                        </button>
                        <button onClick={() => setLocation(1)} className={butonStyles}>
                            save as
                        </button>
                    
                </div>
            </PopUpSection>
        </>
    )
}