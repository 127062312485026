import React from 'react';
import Login from '../../pages/Login';
import Navbar from '../Navbar/Navbar';
import { UseToggleContext } from '../../context/ToggleContexts';
import TimeOut from '../TimeOut/TimeOut';
import MobileBlocker from '../Navbar/MobileBlocker';
import Footer from '../Footer/Footer';

export default function BasicSection({children, check, usenavbar}) {

    const toggler = UseToggleContext();
    
    return (
        <>
            {   
                check 
                ?
                    <>
                        <MobileBlocker />
                        {usenavbar?<Navbar />:null}
                        {children}
                        <Footer />
                        {toggler.toggle?.timeOut&&toggler.userActivity === 'Warning'&&<TimeOut {...{}} />}
                    </>
                :
                <Login />
            }
        </>
    )
};
