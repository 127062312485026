import React from 'react';
import PopUpSection from '../Section/PopUpSection';
import { globalBoolHandler } from '../../utils/globalHandlers';
import { UseToggleContext } from '../../context/ToggleContexts';

export default function SaveAs({setLocation, setCase, newCase}) {

    const toggler = UseToggleContext();

    const buttonStyles = "bg-light-blue hover:bg-hover-blue py-3 px-8 rounded text-white";

    return (
        <>
            <PopUpSection>
                <div className='w-[500px] h-[300px] bg-primary-dark rounded shadow-xl mx-auto' >
                    <div className=' w-[450px] flex flex-col justify-center h-full items-center mx-auto'>
                        <label className='flex flex-col w-full'><span className='text-white pb-1'>Enter a new case name</span>
                            <input onChange={e => setCase(e.target.value)} className='rounded py-2 px-2' />
                        </label>
                        <div className="w-full flex justify-between pt-8">
                            <button className={buttonStyles} onClick={() => globalBoolHandler("saveMemo", toggler.toggle, toggler.setToggle, false)}>cancel</button>
                            {newCase&&<button onClick={() =>setLocation(2)} className={buttonStyles} >next</button>}
                        </div>
                    </div>
                </div>
            </PopUpSection>
        </>
    )
}; 