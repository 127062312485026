import React from 'react';

export default function HoverTPN({addDrop, hovered, top, bottom, right, alternativeBot, alternativeTop}) {
    return (
        <div className={`absolute ${right?right:"right-[80px]"} ${!addDrop ? top : bottom} top-[-30px] flex flex-col justify-center ${hovered ? "block" : "hidden"}`}>
            <div className={`${alternativeTop?alternativeTop:"bg-primary-dark text-white p-4 rounded-lg font-medium mb-[-3px] shadow-xl"}`}>
                <div>
                    "T" - Targeted product, shift out
                    <br />
                    "P" - Preferred product, shift in on weighted basis
                    <br />
                    "N" - Neutral, no shift
                </div>
            </div>
            <div className={`${alternativeBot?alternativeBot:" relative w-3 overflow-hidden mx-auto rotate-[270deg]"}`}>
                <div className="h-4 bg-primary-dark rotate-[315deg] origin-top-right rounded-sm"></div>
            </div>
        </div>
    )
}