import React from 'react';
import { convertYears, formatCurrencyExp, formatDecimal, formatPerBrandEstimates, restructureData, sumTotals, transformData } from '../../utils/resultsSummary';
import { UseContextClues } from '../../context/ContextClues';

export default function PricingTable({switchContext, section}) {

    const context = UseContextClues();
    const dealLength = context.exclusionSettings.dealLength;

    const tableStyle = "font-light text-sm my-2 w-full";
    const rowStyles = "pl-2 py-4 border";

    function withHedge(section, channel, key){
        if (context.pricingLens[switchContext]?.percentageByYear){
            return formatPerBrandEstimates((restructureData(transformData(JSON.parse(context[switchContext][section]))))[channel][key] * context.pricingLens[switchContext]?.passBackbyYear[key] * (1-context.pricingLens[switchContext]?.priceLens?.price[key]?.[channel]))
        } else {
           return formatPerBrandEstimates((restructureData(transformData(JSON.parse(context[switchContext][section]))))[channel][key] * context.pricingLens[switchContext]?.priceLens?.passBack * (1-context.pricingLens[switchContext]?.priceLens?.price[key]?.[channel]))
        };
    };
    function withoutHedge(section, channel, key){
        if (context.pricingLens[switchContext]?.percentageByYear) {
           return formatPerBrandEstimates((restructureData(transformData(JSON.parse(context[switchContext][section]))))[channel][key] * context.pricingLens[switchContext]?.passBackbyYear[key] * (1-context.pricingLens[switchContext]?.priceLens?.blanket))
        } else {
            return formatPerBrandEstimates((restructureData(transformData(JSON.parse(context[switchContext][section]))))[channel][key] * context.pricingLens[switchContext]?.priceLens?.passBack * (1-context.pricingLens[switchContext]?.priceLens?.blanket))
        };
    };

    return (
        <>
            <table className={tableStyle}>
                <thead>
                    <tr className='font-bold'>
                        <td className='border whitespace-pre-wrap h-12 bg-primary-dark text-white px-4 pb-1 w-'>Channel</td>
                        {Object.keys(JSON.parse(context[switchContext][section])).map((years, idx) => (
                            idx<dealLength&&<td key={`${idx} - Channel Year: ${years}`} 
                                className='border whitespace-pre-wrap h-12 bg-primary-dark text-white w-[200px] my-2 text-center' 
                            >
                                {convertYears(years, context.settings.year.year1)}
                            </td>
                        ))}
                    </tr>
                </thead>
                <tbody className='bg-background-light'>
                    <>     
                        {Object.keys(restructureData(transformData(JSON.parse(context[switchContext][section])))).map((channel, idx) => (
                            <tr key={`Channel: ${channel} - index: ${idx}`}>
                                <td className={`${rowStyles} pb-1`}>
                                    {channel}
                                </td>
                                
                                {Object.keys((restructureData(transformData(JSON.parse(context[switchContext][section]))))[channel]).map((key, idx) => (
                                    idx<dealLength&&<td key={`${idx} - Channel section: ${key}`} className='border text-right pr-3 pb-1'>
                                        { section === "Per Brand Estimates" ? (
                                                context.pricingLens[switchContext]?.hedgeCheck
                                                ? formatPerBrandEstimates((restructureData(transformData(JSON.parse(context[switchContext][section]))))[channel][key] * context.pricingLens[switchContext]?.priceLens?.passBack * (1-context.pricingLens[switchContext]?.priceLens?.price[key]?.[channel])) 
                                                : formatPerBrandEstimates((restructureData(transformData(JSON.parse(context[switchContext][section]))))[channel][key] * context.pricingLens[switchContext]?.priceLens?.passBack * (1-context.pricingLens[switchContext]?.priceLens?.blanket))                                
                                            )
                                        : section === "Rx_summary" ? formatDecimal((restructureData(transformData(JSON.parse(context[switchContext][section]))))[channel][key])  
                                        : 
                                            context.pricingLens[switchContext]?.hedgeCheck
                                            ? 
                                            withHedge(section, channel, key )
                                            : withoutHedge(section, channel, key )
                                        }
                                    </td>
                                ))}
                            </tr>
                        ))}

                        {section === "Rebates_summary" 
                        && 
                            <tr>
                                <td className={rowStyles}>total</td>
                                    {Object.entries(context.pricingLens[switchContext].totals).map((number, idx) => (
                                        idx<dealLength&&<td key={`Total: ${number} at index: ${idx}`} className='border text-right pr-3 pb-1'>
                                            {formatPerBrandEstimates(number[1])}    
                                        </td>
                                    ))}
                            </tr>
                        }
                        {section === "Rx_summary" 
                        // || section === "Rebates_summary" 
                        && 
                            <tr>
                                <td className={rowStyles}>total</td>
                                {sumTotals(restructureData(transformData(JSON.parse(context[switchContext][section])))).map((value, idx) => (
                                    idx<dealLength&&<td key={`RX and Rebates: ${value} at index: ${idx}`} className='text-right pr-3 border'>
                                        {section === "Rx_summary" ? formatDecimal(value) : formatCurrencyExp(value)}
                                    </td>
                                ))}
                            </tr>
                        }
                    </>
                </tbody>
            </table>
        </>
    )
};