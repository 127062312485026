import React, { useState } from 'react';
import { emailSend } from '../../utils/emailSend';
import { globalChangeHandler } from '../../utils/globalHandlers';
import { UseToggleContext } from '../../context/ToggleContexts';

export default function Contact() {

     const toggler = UseToggleContext();
    
        const [ params, setParams ] = useState({
            name: null,
            phone: null,
            email: null,
            message: null,
        });
        
        const labelStyles = "flex flex-col w-full mb-2 ";
        const inputStyles = "border px-4 py-3 rounded-xl mt-2 ";
    
        var submitEmail = e => {
            e.preventDefault();
            emailSend(params)
        }
    return (
        <>
            <div style={{height: toggler.pageHeight-200}} className='px-20 pt-10 pb-20 overflow-y-auto '>
                <div className='h-[130px] flex items-center tracking-widest text-2xl font-medium'>Contact Support</div>
                <form className=' ' onSubmit={submitEmail}>
                    <div className='flex gap-6'>
                        <label className={labelStyles}>
                            Name
                            <input name='name' className={inputStyles} type='text' onChange={e => globalChangeHandler(e, params, setParams)} required />
                        </label>
                        <label className={labelStyles}>
                            Phone Number
                            <input name='phone' className={inputStyles} type='text'  onChange={e => globalChangeHandler(e, params, setParams)}/>
                        </label>
                    </div>
                        <label className={labelStyles}>
                            Email
                            <input name='email' className={inputStyles} type='email'  onChange={e => globalChangeHandler(e, params, setParams)}/>
                        </label>
                    <label className={labelStyles}>
                        Message
                        <textarea placeholder='Describe your issue or question...' name='message' className={inputStyles+" h-40  "} onChange={e => globalChangeHandler(e, params, setParams)} required/>
                    </label>
                    <button className='px-12 py-2 mb-4 rounded-lg mt-4 text-white font-medium bg-light-blue hover:bg-hover-blue' type='submit'>submit</button>
                </form>
            </div>
        </>
    );
};