import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { UseUserContext } from '../../context/UserContext';

export default function LoadingButton({filename, icon, color, hexColor, onclick, setData}) {
    const account = UseUserContext();
    const [loading, toggle] = useState(false);
    return (
        <button className={` h-full w-full ${color?color:""}`} name={filename} onClick={(e) => onclick(e, toggle, setData, account)}>
            {loading?<l-tail-chase size={"25"} speed="1.75" color={`${hexColor?hexColor:'#16b3d2'}`} ></l-tail-chase>:<FontAwesomeIcon icon={icon}/>}
        </button>
    )
}


