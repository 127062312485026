import React from 'react'

export default function Year1StartDate({additionalClasses, handleInput, updateValue, textSize, inputWidth}) {

    return (
        <>
            <div className={additionalClasses}>
                <label className={`flex justify-between items-center ${textSize?textSize:"text-xs"} font-medium`} htmlFor="year1StartDate">Year 1 Start Date
                    <input name='year1' className={`border ${inputWidth?inputWidth:'w-1/2'} px-3 py-1 rounded`} type="date" id='year1StartDate' value={updateValue} onChange={handleInput}/>
                </label>
            </div>
        </>
    )
};  