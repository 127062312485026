import React, { useEffect, useState } from 'react';
import { globalChangeHandler } from '../../utils/globalHandlers';
import { createRebateCredit, updateRebateCredit } from '../../utils/creditForecast';

export default function RebateCreditForm({data, set, change, editFields, selected}) {

    const labelStyles = "flex flex-col mt-3 capitalize";
    const inputStyles = "border rounded-full px-6 py-2 mt-1";

    const [ fields, setFields ] = useState({
        listname : '',
        drugname : '',
        credit_type : '',
        ndc : '',
        startdate : '',
        mag : ''
    });

    useEffect(() => {
        if (editFields) {
            setFields({
                ...editFields,
                listname: data.creditSelect
            })
        }
    }, [editFields]);
    useEffect(() => {
        if (selected) {
            setFields(data => ({
                ...data,
                listname: selected
            }))
        }
    }, [selected]);

    function handleSubmit(e) {
        e.preventDefault();
        if (editFields) {
            updateRebateCredit(fields.id, fields, data.creditlist[data.creditSelect], data.creditSelect, set, change);
        } else {
            createRebateCredit(fields, data.creditSelect?data.creditSelect:fields.listname, Object.keys(data.creditlist), set, change);
        }
    };

    return (
        <>
            <div className='uppercase tracking-widest pb-4'>
                {editFields?"Edit current":"Create new"} 
            </div>
            <form onSubmit={handleSubmit} className={`border p-6 ${editFields?"bg-primary-yellow":""}`}>
                <label htmlFor='listname' className={labelStyles}>listname
                    <input onChange={e => globalChangeHandler(e, null, setFields)} value={fields.listname} name='listname' type='text' className={inputStyles+" disabled:cursor-not-allowed"} disabled={editFields||data.creditSelect} required />
                </label>
                <label htmlFor='drugname' className={labelStyles}>drugname
                    <input onChange={e => globalChangeHandler(e, null, setFields)} value={fields.drugname} name='drugname' type='text' className={inputStyles} required />
                </label>
                <label htmlFor='credit_type' className={labelStyles}>credit type
                    <input onChange={e => globalChangeHandler(e, null, setFields)} value={fields.credit_type} name='credit_type' type='text' className={inputStyles} required />
                </label>
                <label htmlFor='ndc' className={labelStyles}>NDC
                    <input onChange={e => globalChangeHandler(e, null, setFields)} value={fields.ndc} name='ndc' type='text' className={inputStyles} required />
                </label>
                <label htmlFor='startdate' className={labelStyles}> startdate
                    <input onChange={e => globalChangeHandler(e, null, setFields)} value={fields.startdate} name='startdate' type='date' className={inputStyles} required />
                </label>
                <label htmlFor='mag' className={labelStyles}>magnitude
                    <input onChange={e => globalChangeHandler(e, null, setFields)} value={fields.mag} name='mag' type='number' min={0} max={1} step={0.01} className={inputStyles} required />
                </label>
                <div className='flex justify-between'>
                    <button type='button' onClick={() => change('list')} className='mt-10 border py-3 px-12 rounded-full border-light-blue text-light-blue hover:bg-light-blue hover:text-white hover:shadow-lg'>prev</button>
                    <button type='submit' className='mt-10 border py-3 px-12 rounded-full bg-light-blue hover:bg-hover-blue text-white hover:shadow-xl'>
                        {editFields?"save":"create"}
                    </button>
                </div>
            </form>
        </>
    );
};