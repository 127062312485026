import {extractFromLocal} from "./loadSave";

export function displayCrystalResults ({year, formulary, category, loader, setCrystal, casenumber}, setLoading) {
    loader("crystal")
    setLoading(true);

    // Assuming you have a fetch function to get data
    fetch(`/api/crystalball?casenumber=${casenumber}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${extractFromLocal().accessToken}`,
        },
        body: JSON.stringify({
            contract_year: year,
            formulary: formulary,
            category: category
        })
    })
    .then(response => response.json())
    .then(data => {
        setLoading(false);
        setCrystal(data);
        loader("");
      }).catch(err => {
        console.log(err);
        setLoading(false);
        loader("");
    })
};

export async function optimizeCrystalBallResults(ratevector, breakeven, set, context, setLoading, shift, casenumber, status){
  setLoading(true);
    const payload = {
        "grid": context.crystal.grid,
        "optimization_parameters": {
          "breakeven": breakeven,
          "shift_pct": shift?shift:0,
          ratevector,
        },
        contract_year: context.settings.crystal.CrystalBallContractYear,
        formulary: context.settings.crystal.CrystalBallFormulary,
        category: context.settings.crystal.category
      };

      try {
        const data = await fetch(`/api/crystaloptimize?casenumber=${casenumber}`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${extractFromLocal().accessToken}`,
            },
            body: JSON.stringify(payload)
        });
        const results = await data.json();
        if (data.ok) {
            set(results)
        } else {
          status("There is an error with your input. Message: "+results.detail);
          setTimeout(() => status(""), 7000);
        };
        
      } catch(err) {
        alert("there is an error with your input. message: "+err)
        console.log(err)
      };
      setLoading(false);
};