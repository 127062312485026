import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLightbulb } from "@fortawesome/free-solid-svg-icons";

export default function ImportGuide(){

    const headerFont = "font-bold text-sm mt-2";
    const regText = "text-gray-600 text-sm ml-4";


    return (
        <>
            <div className='text-base pt-8'>
                    <div className='text-lg font-bold mb-4'>
                        Importing Tips
                        <FontAwesomeIcon className='text-lg mr-4 text-light-blue ml-3 rotate-[20deg] ' icon={faLightbulb}/>
                    </div>
                    <span className={headerFont}>How should I format my .csv data file?</span>
                    <div className={regText}>Nestor will do most of the work for you. You just need to be mindful of a few practices:</div>
             
                    <div className={headerFont}>Dates: </div>
                    <div className={regText}>Please try to make sure the date format (if you are using it) is in one of the following formats:</div>
                    <div className='text-sm font-medium italic ml-8 my-2'>
                        <span className='px-2'>‘YYYYMMDD’, </span>
                        <span className='px-2'>‘YYYY-MM-DD’, </span>
                        <span className='px-2'>‘YYYY/MM/DD’, </span>
                        <span className='px-2'>‘MMDDYYYY’, </span>
                        <span className='px-2'>‘MM-DD-YYYY’, </span>
                        <span className='px-2'>‘MM/DD/YYYY’</span>
                    </div>
             
                    <div className={headerFont}>Qty:</div>
                    <div className={regText}>Please make sure your quantity field is correctly formatted to as the metric quantity. Some external data layouts have a convention where quantity contains <b>0’s</b> after the intended decimal place. For example, <b>30</b> tablets should be either <b>“30”</b> or <b>“30.0”</b>, not <b>“000030000”</b>.</div>
             
                    <div className={headerFont}>Carrier:</div>
                    <div className={regText}>Carrier will allow you to narrow your results summary into one or multiple lines of business. If you do not have a column for line of business, select <b>** UNAVAILABLE **</b> .</div>
             
                    <div className={headerFont}>Net Claims:</div>
                    <div className={regText}>If your data is aggregated, you should have a net claims count column. If your data is at the claim level, you may or may not have this column. If you do not, select <b>** UNAVAILABLE **</b>.</div>
            </div>
        </>
    )
};