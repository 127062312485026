import React from "react";
import { convertYears, formatCurrencyExp, formatDecimal, formatPerBrandEstimates, restructureData, sumTotals, transformData } from '../../utils/resultsSummary';
import LockedMessage from "../Error/LockedMessage";

export default function ResultsTable({thisRef, context, next, annualization, dealLength}) {

    const labelStyle = "font-bold text-sm";
    const tableStyle = "font-light text-sm my-2 w-full";

    return (
        <>
            <div ref={thisRef} className={`${context ? "opacity-1": "opacity-0"}`}>
                {context&&<>
                    {Object.keys(context).map((section, idx) => (
                        section!=="Brand_WAC_Summary"&&<div key={`Results data: ${section}, index: ${idx}`}>
                            <div className={labelStyle}>{section.split("_").join(" ")} <LockedMessage /></div>
                            <div className="hidden">{'\n'}</div>
                            <table className={tableStyle}>
                                <thead>
                                    <tr className='font-bold'>
                                        <td className='border whitespace-pre-wrap h-12 bg-primary-dark text-white px-4 leading-none py-2'>Channel</td>
                                        <td className="hidden">{'\t'}</td>
                                        {Object.keys(JSON.parse(context[section])).map((years, idx) => (
                                            idx<dealLength&&<React.Fragment key={`${idx} - Channel Year: ${years}`}>
                                                <td  className='border whitespace-pre-wrap h-12 bg-primary-dark text-white my-2 text-center leading-none py-2' >{convertYears(years, next.settings.year.year1)}</td>
                                                <td className="hidden">{'\t'}</td>
                                            </React.Fragment>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody className="bg-background-light">
                                    <>   
                                        {Object.keys(restructureData(transformData(JSON.parse(context[section])))).map((channel, idx) => (
                                            <tr key={`Channel: ${channel} - index: ${idx}`}>
                                                <td className='border pl-2 leading-none py-2'>
                                                    <div className="hidden">{'\n'}</div>
                                                    {channel}
                                                    <div className="hidden">{'\t'}</div>
                                                </td>
                                                {Object.keys((restructureData(transformData(JSON.parse(context[section]))))[channel]).map((key, idx) => (
                                                    idx<dealLength&&<React.Fragment key={`${idx} - Channel section: ${key}`}>
                                                        <td  className='border text-right pr-2 pt-[-10px] leading-none py-2'>
                                                            { section === "Per Brand Estimates" ? formatPerBrandEstimates((restructureData(transformData(JSON.parse(context[section]))))[channel][key])
                                                            : section === "Rx_summary" ? formatDecimal((restructureData(transformData(JSON.parse(context[section]))))[channel][key])  
                                                            : formatCurrencyExp((restructureData(transformData(JSON.parse(context[section]))))[channel][key])  
                                                            }
                                                        </td>
                                                        <td className="hidden">{'\t'}</td>
                                                    </React.Fragment>
                                                ))}
                                            </tr>
                                        ))}
                                        <tr className="">
                                            <td className="hidden">{'\n'}</td>
                                        </tr>
                                        {section === "Rx_summary" || section === "Rebates_summary" ? 
                                            <>
                                                <tr>
                                                    <td className='pl-2 border py-2'>total</td>
                                                    <td className="hidden">{'\t'}</td>
                                                    {sumTotals(restructureData(transformData(JSON.parse(context[section])))).map((value, idx) => (
                                                        idx<dealLength&&<React.Fragment key={`RX and Rebates: ${value} at index: ${idx}`} >
                                                            <td  className='text-right pr-2 border'>
                                                                {section === "Rx_summary" ? formatDecimal(value) : formatCurrencyExp(value)}
                                                            </td>
                                                            <td className="hidden">{'\t'}</td>
                                                        </React.Fragment>
                                                    ))}
                                                    <td className="hidden">{'\t'}</td>
                                                </tr>
                                                <tr className="">
                                                    <td className="hidden">{'\n'}</td>
                                                </tr>
                                            </>
                                        : null
                                        }
                                    </>
                                </tbody>
                            </table>
                        </div>
                    ))}
                    <div className='mt-4 text-sm font-medium pb-2'>
                        Results have been annualized with a factor of {annualization?annualization.toLocaleString('en-US', {maximumFractionDigits: 3}):"N/A"}*
                    </div>
                    <div className="hidden">{'\n'}</div>
                </>}
            </div>   
        </>
    );
};