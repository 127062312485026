import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";

export default function AddButton({onClick, buttonText, handler, addClasses}) {
    return (
        <> 
            <button 
                type='button' 
                onClick={onClick} 
                aria-label='aux panel toggle button'
                className={`border rounded-lg font-bold text-xs w-full text-white bg-light-blue hover:bg-hover-blue p-2 ${addClasses}`}>
                    <span className='flex items-center justify-between'>
                        {buttonText}
                        <FontAwesomeIcon icon={!handler ? faPlus : faMinus}/>
                    </span>
            </button>
        </>
    )
}