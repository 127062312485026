import {extractFromLocal} from "./loadSave";

export async function listMyFiles(set) {

    try {
        const data = await fetch('/api/list-my-files', {
            method: "GET",
            headers: { 
                'Content-Type'  : 'application/json',
                'Authorization' : `Bearer ${extractFromLocal().accessToken}`}
        });

        let response = await data.json();

        if (data.ok) {
            set(response)
            // return response;
        };
        
    } catch (err) {
        alert(`Error listing user files with following message : ${err}`);
        console.log(err)
    };
};

export async function uploadMyFile(file, set, setFile, setLoading) {
    setLoading(data => ({
        ...data,
        upload:true
    }));

    try {
        const formData = new FormData();
        formData.append("file", file)
        
        const data = await fetch('/api/upload-file-to-me/', {
            method: "POST",
            headers: { 
                'Authorization' : `Bearer ${extractFromLocal().accessToken}`},
                'body'          : formData

        });

        await data.json();

        if (data.ok) {
            alert("File has been uploaded");
            listMyFiles(set);
            setFile("");
                setLoading(data => ({
                ...data,
                upload:false
            }));
            // return response;
        };
        
    } catch (err) {
        alert(`Error uploading ${file} with following message : ${err}`);
            setLoading(data => ({
                ...data,
                upload:false
            }));
        console.log(err)
    };
};

export async function downloadMyFile(filename, setLoading) {

    try {
        const data = await fetch(`/api/download-my-file/${filename}`, {
            method: "GET",
            headers: { 
                'Authorization' : `Bearer ${extractFromLocal().accessToken}`}
        });

        let response = await data.blob();
        
        const link = document.createElement("a");
        link.target = "_blank";
        link.download = filename;
        link.href = window.URL.createObjectURL(
            new Blob([response]),
        );
        link.click();
        setLoading(false)
        
    } catch (err) {
        alert(`Error downloading ${filename} with following message : ${err}`);
        setLoading(false);
        console.log(err)
    };
};

export async function deleteMyFile(filename, set, setLoading) {

    try {
        const data = await fetch(`/api/delete-my-file/${filename}`, {
            method: "DELETE",
            headers: { 
                'Content-Type'  : 'application/json',
                'Authorization' : `Bearer ${extractFromLocal().accessToken}`}
        });

        let response = await data.json();

        if (data.ok) {
            alert(`File ${filename} has been successfully deleted!`);
            listMyFiles(set);
            setLoading(false);
            return response;
        };
        
    } catch (err) {
        alert(`Error deleting ${filename} with following message : ${err}`);
        setLoading(false);
        console.log(err)
    };
};


export function handleDownload(e, toggle, setData, account) {
    e.preventDefault();
    const filename = e.target.name;
    toggle(true);
    if (filename){
        downloadMyFile(filename.split(`${account.user.userData.username}/`)[1], toggle);
    };
};
export function handleDelete(e, toggle, setData, account) {
    e.preventDefault();
    toggle(true);
    const filename = e.target.name;
    if (filename){
        deleteMyFile(filename.split(`${account.user.userData.username}/`)[1], setData, toggle);
    };
};