import React from 'react';
// import Logo259 from '../SVG/Logo259';

export default function OpeningTransition() {
    return (
        <>
            <div className={`z-[500] bg-primary-dark w-full h-[100vh] items-center justify-center text-white text-3xl flex flex-col `}>
                {/* <Logo259 className={'w-[400px]'} /> */}
                <l-tail-chase size={"120"} speed="1.75" color="white" ></l-tail-chase>
                <div className='pt-20 text-4xl'>
                    Loading Revenue Cycle Management...
                </div>
            </div>
        </>
    )
}