export default function SettingsTable({thisRef, context}) {

    const rowStyle = "border my-4";
    const rowFix = "leading-none pb-4 pl-1"

    return (
        <>
            <table className="hidden" border={1} cellSpacing={0} ref={thisRef}>
                <thead>
                    <tr className='bg-primary-dark text-white h-12'>
                        <td className='border pl-1'>Model Settings</td>
                        <td className="hidden">{'\t'}</td>
                        <td className='border pl-1'>Value</td>
                        <td className="hidden">{'\n'}</td>
                    </tr>
                </thead>
                <tbody>
                    <tr className={rowStyle}>
                        <td className={rowFix}>Table Name:</td>
                        <td className="hidden">{'\t'}</td>
                        <td className={rowFix}>{context.settings.claims?.tName}</td>
                        <td className="hidden">{'\n'}</td>
                    </tr>
                    <tr className={rowStyle}>
                        <td className={rowFix}>Case Number:</td>
                        <td className="hidden">{'\t'}</td>
                        <td className={rowFix}>{context.casenumber}</td>
                        <td className="hidden">{'\n'}</td>
                    </tr>
                    {
                        Object.keys(context.settings.modelSettings).map((model, idx) => (
                            <tr className={rowStyle} key={`${idx} - ${model} settings table`}>
                                <td className={rowFix}>{model}</td>
                                <td className="hidden">{'\t'}</td>
                                <td className={rowFix}>{context.settings.modelSettings[model]}</td>
                                <td className="hidden">{'\n'}</td>
                            </tr>
                        ))
                    }
                    <tr className={rowStyle}>
                        <td className={rowFix}>Base Period Start Date:</td>
                        <td className="hidden">{'\t'}</td>
                        <td className={rowFix}>{context.settings.claims?.startDate}</td>
                        <td className="hidden">{'\n'}</td>
                    </tr>
                    <tr className={rowStyle}>
                        <td className={rowFix}>Base Period End Date:</td>
                        <td className="hidden">{'\t'}</td>
                        <td className={rowFix}>{context.settings.claims?.endDate}</td>
                        <td className="hidden">{'\n'}</td>
                    </tr>
                    {
                        Object.keys(context.exclusionSettings.assumptions).map((assumption, idx) => (
                            <tr className={rowStyle} key={`${idx} - ${assumption} settings table`}>
                                <td className={rowFix}>{assumption}</td>
                                <td className="hidden">{'\t'}</td>
                                <td className={rowFix}>{context.exclusionSettings.assumptions[assumption]}</td>
                                <td className="hidden">{'\n'}</td>
                            </tr>
                        ))
                    }
                    <tr className={rowStyle}>
                        <td className={rowFix}>Base Formulary:</td>
                        <td className="hidden">{'\t'}</td>
                        <td className={rowFix}>{context.settings.formulary?.baseFormulary}</td>
                        <td className="hidden">{'\n'}</td>
                    </tr>
                    <tr className={rowStyle}>
                        <td className={rowFix}>Target Formulary:</td>
                        <td className="hidden">{'\t'}</td>
                        <td className={rowFix}>{context.settings.formulary?.targetFormulary}</td>
                        <td className="hidden">{'\n'}</td>
                    </tr>
                    <tr className={rowStyle}>
                        <td className={rowFix}>Brand Definition:</td>
                        <td className="hidden">{'\t'}</td>
                        <td className={rowFix}>{context.exclusionSettings?.brandDefinition}</td>
                        <td className="hidden">{'\n'}</td>
                    </tr>
                    <tr className={rowStyle}>
                        <td className={rowFix}>OTC Definition:</td>
                        <td className="hidden">{'\t'}</td>
                        <td className={rowFix}>{context.exclusionSettings?.otcDefinition}</td>
                        <td className="hidden">{'\n'}</td>
                    </tr>
                    <tr className={rowStyle}>
                        <td className={rowFix}>Exclusions:</td>
                        <td className="hidden">{'\t'}</td>
                        <td className={rowFix}>{context.exclusionSettings?.exclusions&&Object.keys(context.exclusionSettings?.exclusions).forEach((exclusion, idx) => {
                            if (context.exclusionSettings?.exclusions[exclusion]) {
                                    if (exclusion === 'exclusionVaccines') {
                                        return ' Vaccines, '
                                        // fill in for what these are
                                    } else if (exclusion === 'exclusionLdd') {
                                    } else {
                                        return exclusion + ", "
                                    }
                                } 
                            } 
                        )}</td>
                        <td className="hidden">{'\n'}</td>
                    </tr>
                    <tr className={rowStyle}>
                        <td className={rowFix}>Carriers:</td>
                        <td className="hidden">{'\t'}</td>
                        <td className={rowFix}>{context.exclusionSettings?.carriers.length>0&&context.exclusionSettings?.carriers.map((carriers, idx) => (
                            <div key={`${idx}: Event List event: ${carriers}`}>
                                {carriers},&nbsp;
                            </div>
                        ))}</td>
                        <td className="hidden">{'\n'}</td>
                    </tr>
                    <tr>
                        <td>
                            <br/>
                        </td>
                    </tr>
                    <tr className={rowStyle}>
                        <td className={rowFix}>Applied Events:</td>
                        <td className="hidden">{'\t'}</td>
                        <td className={rowFix}>{context.exclusionSettings?.events.length>0&&context.exclusionSettings?.events.map((eventListing, idx) => (
                            <div key={`${idx}: Event List event: ${eventListing}`}>
                                {eventListing},&nbsp;
                            </div>
                        ))}</td>
                        <td className="hidden">{'\n'}</td>
                    </tr>
                </tbody>
            </table>
        </>
    )
};