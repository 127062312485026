import React from 'react';
import { tailChase } from 'ldrs';

export default function TailChaseLoader({size}) {
    tailChase.register();
    return (
        <l-tail-chase
            size={size?size:"20"}
            speed="1.75" 
            color="#00141c" 
        ></l-tail-chase>
    );
};