import React  from "react";
import { UseToggleContext } from '../context/ToggleContexts';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faComments, faFileInvoiceDollar, faMoneyBillTrendUp, faShieldHalved } from "@fortawesome/free-solid-svg-icons";
import desktop259 from './desktop259.png'
import SEO from "../components/SEO/SEO";

export default function About(){

    const toggler = UseToggleContext();

    const unorderStyle = "pl-8 md:pl-16";
    const bulletStyle = "ml-4 md:ml-8 py-3 md:py-6 md:text-xl before:ml-[-38px]";

    const boxStyleTitle = "font-bold";
    const boxStyleContainer = "relative p-4 mx-2 border-2 rounded-xl bg-primary-dark text-white w-[300px] h-[220px] flex items-center flex-col";
    const boxStyleContent = "text-sm font-light pt-3 text-center";

    const listBullets = "py-3 ";

    return (
        <>  
            <SEO  title="About 259Rx | Real rebate experts, not PBM generalists" description="We are real rebate experts, not PBM generalists. If formulary rebate revenue is part of your P&L, we can help you!" />
            <div style={{height: (toggler.pageHeight)}} className="bg-background-light relative overflow-y-auto">
                <div style={{height: (toggler.pageHeight)}} className=" top-0 bottom-0 left-0 right-0 bg-cover bg-fixed bg-bottom h-full bg-[url('https://images.pexels.com/photos/1034665/pexels-photo-1034665.jpeg')]">
                    <div className=" bg-black h-[40%] md:h-[400px] text-white flex flex-col justify-center text-left p-4 md:pl-20 text-2xl md:text-[30px]">
                        <h1>Real rebate experts, not PBM generalists.</h1>
                        <div>If formulary rebate revenue is part of your P&L, <span className="text-[#f10a29] uppercase font-bold">we can help you.</span></div>
                    </div>
                </div>
                <div  className=" mx-auto  overflow-y-auto ">
                    <div className="min-h-[600px] md:h-[900px] relative md:grid grid-cols-2 bg-stone-950 overflow-hidden">
                        <img className="h-full w-full object-cover hidden md:block object-center" alt="nurse holidng pill tray" src="https://images.pexels.com/photos/4047054/pexels-photo-4047054.jpeg" />
                        <div></div>
                        <div className="md:absolute top-0 bottom-0 right-0 left-0 md:grid grid-cols-2 max-w-[1440px] mx-auto h-full">
                            <div></div>
                            <div className="bg-stone-950  text-white text-bold flex items-center justify-center flex-col" >
                                <div className="py-20 px-4 md:pl-20 pr-8">
                                    {/* <ul className={unorderStyle}>
                                        <h3 className={headerStyle}>
                                            Who is 259Rx?
                                        </h3>
                                        <li className={bulletStyle}>Real rebate experts, not PBM generalists.</li>
                                    </ul>
                                    <h3 className={headerStyle}>
                                        Who do we help?
                                    </h3>
                                    <ul className={unorderStyle}>
                                        <li className={bulletStyle}>If formulary rebate revenue is part of your P&L, we can help you.</li>
                                    </ul> */}
                                    <div className="hidden md:block absolute left-0 text-7xl top-32 text-stone-800  rounded-xl font-bold w-full text-left p-12">
                                        How do we help?
                                    </div>
                                    <ul className={unorderStyle}>
                                        <li className={bulletStyle}>
                                            <FontAwesomeIcon className='text-2xl pr-4 text-white ' icon={faCheck}/>    
                                            Competitive Analysis and Benchmarking
                                        </li>

                                        <li className={bulletStyle}>
                                            <FontAwesomeIcon className='text-2xl pr-4 text-white ' icon={faCheck}/>    
                                            Due Diligence / M&A analysis
                                        </li>

                                        <li className={bulletStyle}>
                                            <FontAwesomeIcon className='text-2xl pr-4 text-white ' icon={faCheck}/>    
                                            Aggregation Assessment and Education
                                        </li>

                                        <li className={bulletStyle}>
                                            <FontAwesomeIcon className='text-2xl pr-4 text-white ' icon={faCheck}/>    
                                            Contract Negotiation Support
                                        </li>

                                        <li className={bulletStyle}>
                                            <FontAwesomeIcon className='text-2xl pr-4 text-white ' icon={faCheck}/>    
                                            Micro and Macro Market Dynamics
                                        </li>

                                        <li className={bulletStyle}>
                                            <FontAwesomeIcon className='text-2xl pr-4 text-white ' icon={faCheck}/>    
                                            Regulatory Analysis
                                        </li>

                                        <li className={bulletStyle}>
                                            <FontAwesomeIcon className='text-2xl pr-4 text-white ' icon={faCheck}/>    
                                            Expert Witness/Litigation Support
                                        </li>

                                        <li className={bulletStyle}>
                                            <FontAwesomeIcon className='text-2xl pr-4 text-white ' icon={faCheck}/>    
                                            Complex Forecast Modeling and Scenario Planning
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="min-h-[900px] max-w-[1440px] mx-auto px-4 py-12 md:p-20 flex flex-col justify-center  ">
                        <div className="text-3xl md:text-5xl font-bold">
                            Phase 1 - Complete
                        </div>
                        <div className="flex flex-wrap gap-y-4 justify-center pt-8 text-xl">
                            <div className={boxStyleContainer}>
                                <div className={boxStyleTitle}> 
                                    RFP Underwritiing
                                </div>
                                <div className={boxStyleContent}>
                                    End to end Pricing Raw data to Proposal Output Precision forecasting Guidepost assistance
                                </div>
                                <FontAwesomeIcon className='text-5xl text-white absolute bottom-6 ' icon={faFileInvoiceDollar}/>    
                            </div>
                            <div className={boxStyleContainer}>
                                <div className={boxStyleTitle}> 
                                    Risk Management
                                </div>
                                <div className={boxStyleContent}>
                                    Scenario comparisons toggling FP&A performance comparisons
                                </div>
                                <FontAwesomeIcon className='text-5xl text-white absolute bottom-6 ' icon={faShieldHalved}/>    
                            </div>
                            <div className={boxStyleContainer}>
                                <div className={boxStyleTitle}> 
                                    M&A Due Diligence
                                </div>
                                <div className={boxStyleContent}>
                                    Expert Risk Analysis for volatile markets
                                </div>
                                <FontAwesomeIcon className='text-5xl text-white absolute bottom-6 ' icon={faMoneyBillTrendUp}/>    
                            </div>
                            <div className={boxStyleContainer}>
                                <div className={boxStyleTitle}> 
                                    Formulary Consulting                        
                                </div>
                                <div className={boxStyleContent}>
                                    Identify opportunities for custom formulary clients Trade negotiation assistance                        
                                </div>
                                <FontAwesomeIcon className='text-5xl text-white absolute bottom-6 ' icon={faComments}/>    
                            </div>
                        </div>
                        <div className="pt-14 flex flex-col sm:flex-row justify-around items-center">
                            <img alt="desktop computer" src={desktop259} />
                            <ul className="p-4 text-2xl md:text-3xl font-medium list-disc ml-10">
                                <li className={listBullets}>
                                    Dynamic ETL
                                </li>
                                
                                <li className={listBullets}>
                                    Unique Event Based System
                                </li>
                                
                                <li className={listBullets}>
                                    Information and Version Control
                                </li>
                                
                                <li className={listBullets}>
                                    Highly Tailored Implementations
                                </li>
                                
                            </ul>
                        </div>
                    </div>
                    {/* footer */}
                    <div className="h-[500px] bg-black text-white " >
                        <div className="flex justify-center md:justify-between max-w-[1440px] mx-auto px-20 py-20">
                            <div className="hidden md:block text-6xl font-bold">
                                259Rx
                                {/* <div>
                                    <FontAwesomeIcon className='text-2xl pr-4 text-white ' icon={faFacebook}/>
                                    <FontAwesomeIcon className='text-2xl pr-4 text-white ' icon={faLinkedin}/>
                                    <FontAwesomeIcon className='text-2xl pr-4 text-white ' icon={faInstagram}/>
                                    <FontAwesomeIcon className='text-2xl pr-4 text-white ' icon={faTwitter}/>
                                    <FontAwesomeIcon className='text-2xl pr-4 text-white ' icon={faYoutube}/>
                                    
                                </div> */}
                            </div>
                            <div className='flex flex-col md:flex-row items-center gap-12 md:gap-3 '>
                                <Link to={"/"} className=' hover:text-hover-blue text-white'>Home</Link>
                                <Link to={"/about"} className='hidden md:inline-block hover:text-hover-blue text-white'>About</Link>
                                <Link to={"/privacy"} className='hover:text-hover-blue text-white'>Privacy Policy</Link>
                                {/* <Link to={"/contact"} className='hover:text-hover-blue text-white'>Contact Us</Link> */}
                                <Link to={"/login"} className='hidden md:inline-block hover:text-hover-blue text-white'>Log in</Link>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
};