import { globalBoolHandler } from "./globalHandlers";
import { extractFromLocal } from "./loadSave";

export async function createPipelineEvent(payload, load, current) {

    try {
        const data = await fetch('/api/create-pipeline-event/', {
            method: "POST",
            headers: { 
                'Content-Type' : 'application/json',
                Authorization : `Bearer ${extractFromLocal().accessToken}`},
            body: JSON.stringify(payload)

        });

        let response = await data.json();

        if (data.ok) {
            listPipelineEvents(current, load)
            alert("Pipeline drug event has been successfully created");
            return response;
        };
        
    } catch (err) {
        alert("Error creating pipeline drug event with following error : "+ err);
        console.log(err)
    };
};

export async function listPipelineEvents(payload, set) {
    try {
        let data = await fetch('/api/list_pipeline_events/', {
            method: "GET",
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${extractFromLocal().accessToken}`,
                },
        });

        if (data.ok) {
            let result = await data.json();
            globalBoolHandler('list', payload, set, result);
        };

    } catch (err) {
        console.log(err)
    };
};

export async function getPipelinePrompts(category, casenumber, set) {
    try {
        let data = await fetch(`/api/get-pipeline-prompts/?category=${category}&casenumber=${casenumber}`, {
            method: "GET",
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${extractFromLocal().accessToken}`,
            },
        });

        if (data.ok) {
            let result = await data.json();
            set(result);
        }
    } catch (err) {
        console.log(err);
    };
};