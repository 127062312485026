import React from 'react';

export default function CustomHover({ hovered, top, bottom, left, right, width, text, alternativeBot, alternativeTop, children }) {
    return (
        <div className={`absolute ${right} ${top} ${left} ${bottom} flex flex-col ${width} justify-center ${text} ${hovered ? "block" : "hidden"}`}>
            <div className={`${alternativeTop?alternativeTop:"bg-primary-dark text-white p-4 rounded-lg font-medium mb-[-3px] shadow-xl"}`}>
                <div>
                   {children}
                </div>
            </div>
            <div className={`${alternativeBot?alternativeBot:" relative w-3 overflow-hidden mx-auto rotate-[270deg]"}`}>
                <div className="h-4 bg-primary-dark rotate-[315deg] origin-top-right rounded-sm"></div>
            </div>
        </div>
    )
}