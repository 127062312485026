import React from 'react';

export default function ImportTableRow({ keyNumber, name, rowTitle, tableBodyStyles, selectStyles, onchange, value, optional, included, options, headerKeys, unavailable  }) {

    function checkAttributes(el) {
        for (let i = 0; i < included?.length; i++) {
            if (el.toLowerCase().includes(included[i])) {
                return true;
            }
        }
        return false;
    };

    return (
        <>
            <tr key={`${keyNumber} - field choices`}>
                <td className={tableBodyStyles}>{rowTitle}</td>
                <td className={tableBodyStyles}> 
                    <select name={name} className={selectStyles} onChange={onchange} type='text' value={value}>
                        <option value="" key={'none'}>
                            {optional?"** Optional **":"-- Select --"}
                        </option>
                        {headerKeys.map((el, idx) => (
                            checkAttributes(el) ?
                                <option key={`${keyNumber} included claim status header:${el}, idx:${idx}`} value={el}>
                                    {el}
                                </option>
                                : null
                        ))}
                        {headerKeys.map((el, idx) => (
                            !checkAttributes(el) ?
                                <option key={`${keyNumber} non-included claim status header:${el}, idx:${idx}`} value={el}>
                                    {el}
                                </option>
                                : null
                        ))}
                        {unavailable&&<option value="&&&">
                            ** UNAVAILABLE **
                        </option>}
                    </select>
                </td>
                <td className={tableBodyStyles}>
                    {options}
                </td>
            </tr>
        </>
    )
}