import React from 'react';
import PopUpSection from '../Section/PopUpSection';

export default function VendorNamePopup({addVendor, vendorName, choose, next, data}) {

    function handleNext(e) {
        e.preventDefault();
        if (vendorName&&!Object.keys(data).includes(vendorName)) {
            next(data => ({
                ...data,
                page: 1
            }));
            addVendor(false);
        } else if (Object.keys(data).includes(vendorName)) {
            alert("Vendor already exists!")
        }
    };
    
    return (
        <>
            <PopUpSection >
                <div className='relative bg-white rounded-xl w-[500px] h-[300px] flex mx-auto justify-center flex-col px-2 overflow-y-auto'>
                    <form onSubmit={handleNext} className='pt-24 w-full h-full p-6'>
                        <div className='w-full'>
                            <label className='flex flex-col' htmlFor='vendorName'><span className=' pb-2'>Add a new vendor</span>
                                <input className='border rounded py-1 px-2' onChange={e => choose(e.target.value)} name='vendorName' required />
                            </label>
                            <div className='absolute bottom-10 flex justify-between w-[435px]'>
                                <button className='py-1 px-10 border-light-blue text-light-blue border rounded-full hover:bg-light-blue hover:text-white' type='button' onClick={() => addVendor(false)}>cancel</button>
                                <button className='py-1 px-10 bg-light-blue text-white rounded-full hover:bg-hover-blue' type='submit'>next</button>
                            </div>
                        </div>
                    </form>
                </div>
            </PopUpSection>
        </>
    )
};