import React from 'react';

export default function GenericTable({children, headers, headerStyle}) {
    return (
        <>
             <table className='w-full'>
                <thead>
                    <tr className={headerStyle}> 
                        {headers?.map((headings, idx) => (
                            <th key={idx+". heading titles :"+headings} className={headings.class}>
                                {headings.content}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody className='border '>
                    {children}
                </tbody>
            </table>
        </>
    )
}