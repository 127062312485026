import React from 'react';
import LoginForm from '../Forms/LoginForm';
import PopUpSection from '../Section/PopUpSection';

export default function AuxLogin(){

    return (
        <>
            <PopUpSection 
                popone={"absolute top-0 z-[200] h-[100vh] w-[100vw] bg-gray-500 opacity-[0.6]"} >
                <div className='relative flex flex-col'>
                    <LoginForm height={"m-10"} addXMark={true}/>
                </div>   
            </PopUpSection> 
        </>
    );
};