import React, { useContext } from 'react';
import { UseToggleContext } from '../../context/ToggleContexts';
import ReabateOp from '../SVG/RebateOp';
import Logo259 from '../SVG/Logo259';

export default function MobileBlocker() {

    const toggler = UseToggleContext();
    return (
        <>
            <div style={{height: toggler?.pageHeight}} className='md:hidden absolute top-0 left-0 bg-primary-dark w-full z-[500]'>
                <div className='absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-full'>
                    <div className='flex items-center justify-center pb-10'>
                        {window.location.href.toLowerCase().includes("app.rebateoptimizer.com")?
                            <ReabateOp switchColors={"#dedede"} scale={"scale(0.75)"} className={""} />:
                            <Logo259 />
                        }
                    </div>
                    <div className='px-8 text-white text-center'>
                        This web address can only be accessed by a desktop device
                    </div>
                </div>
            </div>
        </>
    )
};  