import {extractFromLocal} from "./loadSave";

export async function getVendorDeals(set) {
    try {   
        const data = await fetch(`/api/get_vendor_deals`, {
            headers: { 
                'Content-Type' : 'application/json',
                Authorization : `Bearer ${extractFromLocal()?.accessToken}`,
            }
        });
        let response = await data.json();
        if (data.ok) {
            set(response);
        };

    } catch (err) {
        console.log(err);
    };
};

export async function getVendorDealDetails(set, vendor) {
    try {   
        const data = await fetch(`/api/get_vendor_deal_detail?vendor=${vendor}`, {
            headers: { 
                'Content-Type' : 'application/json',
                Authorization : `Bearer ${extractFromLocal()?.accessToken}`,
            }
        });
        let response = await data.json();
        if (data.ok) {
            set(response);
        };

    } catch (err) {
        console.log(err);
    };
};

export async function getContractGuarantees(set, scenario, casenumber, year1, vendor, formulary, lob, setLoading, status) {
    setLoading(true);
    try {   
        const data = await fetch(`/api/get_contract_guarantees?scenario=${scenario}&casenumber=${casenumber}&year1startdate=${year1}&vendor_name=${vendor}&formulary_name=${formulary}&lob_name=${lob}`, {
            headers: { 
                'Content-Type' : 'application/json',
                Authorization : `Bearer ${extractFromLocal()?.accessToken}`,
            }
        });
        let response = await data.json();
        if (data.ok) {
            set(response);
            setLoading(false);
        } else {
            status("There is an error with the current submission using the method 'Get Contract Guarantees' with the following error: "+response.detail);
            setTimeout(() => {
                status("")
                setLoading(false);
            }, 10000)
        }
        
    } catch (err) {
        console.log(err);
        setLoading(false);
    };
};

export async function updateVendorrRate(rateId, payload, setDef, vendorDetails, vendor, next) {
    try {   
        let usePay = {
            ...payload,
            custom_lists: JSON.stringify(payload.custom_lists, null, " "),
            exclusions: JSON.stringify(payload.exclusions, null, " ")
        }
        const data = await fetch(`/api/update_vendor_rate/${rateId}`, {
            method: "PUT",
            headers: { 
                'Content-Type' : 'application/json',
                Authorization : `Bearer ${extractFromLocal()?.accessToken}`,
            },
            body: JSON.stringify(payload)
        });
        let response = await data.json();
        if (data.ok) {
            setDef({[vendor]:vendorDetails[vendor].map(ele => ele.id===rateId?usePay:ele)});
            alert(response.message);
            next(data => ({
                ...data,
                section: 'main'
            }));
        };

    } catch (err) {
        console.log(err);
    };
};

export async function saveVendorRate(payload, vendorDetails, setDetails, vendor) {
    const loader = {
        ...payload,
        custom_lists: JSON.stringify(payload.custom_lists, null, " "),
        exclusions: JSON.stringify(payload.exclusions, null, " "),
        id: "N/A"
    };
    try {
        let data = await fetch(`/api/save_vendor_rate`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${extractFromLocal().accessToken}`,
            },
            body: JSON.stringify(payload)
        });

        let response = await data.json();
        if (data.ok) {
            if (vendorDetails[vendor]?.length>0) {
                setDetails(data => ({
                    ...data,
                    [vendor]:[
                        ...data?.[vendor],
                        loader
                    ]
                }));
            } else {
                setDetails({
                    [vendor]:[
                        loader
                    ]
                });
            };
        };
        alert(response.message)
        
    } catch (err) {
        
        console.log(err);
    };
};


export async function deleteVendorRate(id, vendor, set, details, next, previousData, update, choose) {
    try {
        let data = await fetch(`/api/delete_vendor_rate/${id}`, {
            method: "DELETE",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${extractFromLocal().accessToken}`,
            },
        });

        let response = await data.json();
        if (data.ok) {
            if (details[vendor].length > 1) {
                set({
                    [vendor]:details[vendor].filter(obj => obj.id !== id)
                }); 
                alert(response.message);
            } else if (details[vendor].length === 1) {
                const updated = {...previousData};
                delete updated[vendor];
                update(updated);
                alert(response.message);
                next(0);
            }
            choose(null);
        };
        
    } catch (err) {
        console.log(err);
    };
};