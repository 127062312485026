import React, { createContext, useContext, useState } from 'react';
const UserContext = createContext({});

export default function UserContextProvider({children}) {

    const [ user, setUser ] = useState({
        loggedIn: false,
        token: null,
        userData: {
            username: '',
            user_type: ''
        }
    });

    const userProps = {
        setUser,
        user,
    };
    
    return <UserContext.Provider value={userProps}>{children}</UserContext.Provider>
};

export const UseUserContext = () => useContext(UserContext);