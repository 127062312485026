import React from 'react';
import { formatCurrency } from '../../utils/analytics';

export default function AnalyticsMfrsTable({tableStyles, headerStyles, context, type }) {
    return (
        <>
            <table className={tableStyles}>
                <thead>
                    <tr>
                        <td className={headerStyles}>
                            manufacturers
                        </td>
                        <td className={headerStyles+" text-right"}>
                            value
                        </td>
                    </tr>
                </thead>
                <tbody>
                    {context.analytics.mfrs[type]?.map((row, idx) => (
                        <tr key={`Manufacturer key: ${row.mfr}, index: ${idx}`}>
                            <td>
                                {row.mfr}
                            </td>
                            <td className='text-right'>
                                {formatCurrency(row.value)}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    )
};