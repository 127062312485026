import {extractFromLocal} from "./loadSave";

export async function listCustomList(set) {

    try {
        const data = await fetch('/api/list-custom-lists/', {
            method: "GET",
            headers: { 
                // 'Content-Type'  : 'application/json',
                'Authorization' : `Bearer ${extractFromLocal().accessToken}`}
        });

        let response = await data.json();

        
        if (data.ok) {
            set(data => ({
                ...data,
                customList: [...response]
            }))

        };
        
    } catch (err) {
        // alert(`Error listing user custom list files with following message : ${err}`);
        console.log(err)
    };
};

export async function listCustomListValues(listName, set, loading) {
    loading(true);

    try {
        const data = await fetch(`/api/get-custom-list-values/?list_name=${listName}`, {
            method: "GET",
            headers: { 
                'Authorization' : `Bearer ${extractFromLocal().accessToken}`
            }
        });

        let response = await data.json();

        if (data.ok) {
            set(response)
            loading(false)
            // return response;
        };
        
    } catch (err) {
        
        console.log(err)
        loading(false)
    };
};

export async function addCustomList(payload, set, loading) {
    loading(true);
    
    try {
        const data = await fetch(`/api/add-custom-list/`, {
            method: "POST",
            headers: {
                'Content-Type'  : 'application/json', 
                'Authorization' : `Bearer ${extractFromLocal().accessToken}`
            },
            body: JSON.stringify(payload)
        });

        let response = await data.json();

        if (data.ok) {
            listCustomList(set);
            loading(false);
            return response;
        }
        
    } catch (err) {
        alert(`Error creating list ${payload.listName} with following message from server : ${err}`);
        loading(false);
        console.log(err)
    };
};

export async function deleteMyList(listName, organization, set) {

    try {
        const data = await fetch(`/api/delete-custom-list/?org_id=${organization}&list_name=${listName}`, {
            method: "DELETE",
            headers: { 
                'Content-Type'  : 'application/json',
                'Authorization' : `Bearer ${extractFromLocal().accessToken}`}
        });

        let response = await data.json();

        if (data.ok) {
            alert(`File ${listName} has been successfully deleted!`);
            listCustomList(set);
            return response;
        };
        
    } catch (err) {
        alert(`Error deleting ${listName} with following message : ${err}`);
        console.log(err)
    };
};