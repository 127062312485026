import React, { useEffect, useState } from 'react';
import PopUpSection from '../Section/PopUpSection';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHourglassEnd, faXmark } from '@fortawesome/free-solid-svg-icons';
import { globalBoolHandler } from '../../utils/globalHandlers';
import { UseToggleContext } from '../../context/ToggleContexts';

export default function TimeOut() {

    const [ timer, countdown ] = useState(300);

    const toggler = UseToggleContext();

    useEffect(() => {
        const interval = setInterval(() => {
            countdown(timer-1)
        }, 1000);

        return () => {
            clearInterval(interval)
        };
    }, [timer])


    return (
        <>
             <PopUpSection >
                <div className='relative w-[550px] h-[300px] bg-white mx-auto rounded-xl'>
                    <button type="button" className='absolute right-2 top-2' onClick={() => globalBoolHandler("timeOut", toggler.toggle, toggler.setToggle, false)}>
                        <FontAwesomeIcon className={' text-black hover:rotate-[180deg] transition-all duration-500'} icon={faXmark} />
                    </button>
                    <div className='text-xl p-8 font-medium'>Session Timeout</div>
                    <div className='text-center'>
                        <FontAwesomeIcon className={' '} icon={faHourglassEnd} />
                        <div className='py-3'>Your session is about to expire.</div>
                        <div className='font-medium'>{Math.floor(timer/60)}:{String(Math.floor(timer%60)).length<2?"0"+Math.floor(timer%60):Math.floor(timer%60)}</div>
                        <button className='py-2 px-8 rounded-full border bg-light-blue hover:bg-hover-blue text-white font-bold mt-8' onClick={() => toggler.activate()}>reset</button>
                    </div>
                </div>
                
            </PopUpSection>   
        </>
    )
};