import React, { useState } from 'react';
import SaveSaveAs from './SaveSaveAs';
import SaveMemo from './SaveMemo';
import SaveAs from './SaveAs';
import { cloneCase, saveCase } from '../../utils/loadSave';
import { UseContextClues } from '../../context/ContextClues';
import { UseToggleContext } from '../../context/ToggleContexts';
import { UseUserContext } from '../../context/UserContext';
import HandleKeydownEscape from '../../hooks/HandleKeydownEscape';
import { globalBoolHandler } from '../../utils/globalHandlers';

export default function SaveHelper() {

    const account = UseUserContext();
    const context = UseContextClues();
    const toggler = UseToggleContext();
    
    const [ location, setLocation ] = useState(0);
    const [ newCase, setCase ] = useState("");
    
    const [ memo, addMemo ] = useState(context.models.memo);

    function handleSave() {
        if (newCase) {
            cloneCase(context.casenumber, newCase, account.user.userData.username, true, memo, context.setCase, toggler);
        } else {
            saveCase(context, true, memo, toggler);
        };
    };

    function handleEscape(){
        globalBoolHandler("saveMemo", toggler.toggle, toggler.setToggle, false)
    };
    HandleKeydownEscape(handleEscape);

    return (
        <>

            {
                location === 0 ?
                    <SaveSaveAs {...{setLocation}} /> :
                location === 1 ? 
                    <SaveAs {...{setLocation, setCase, newCase}} /> :
                location === 2 ? 
                    <SaveMemo {...{handleSave, memo, addMemo}} />  :
                null
            }
        </>
    );
};