import React from 'react';
import { UseToggleContext } from '../../context/ToggleContexts';

export default function EventGuide() {

    const toggler = UseToggleContext();

    return (
        <>
            <div  className={`absolute top-10 right-10 border p-8 bg-background-light shadow-xl z-[100] w-[30rem] rounded overflow-y-auto  ${toggler.pageHeight<700?"h-full":""} `}>
                <p>
                    <span className='text-xl font-bold'>How are “Events” handled?</span>
                    <br></br>
                    <br></br>
                    <b>Modifications to WAC</b>
                    <br></br>
                    If you have applied a WAC modification event, it will only be applied if your <em>“Price as of date”</em> is earlier than the specified event. 
                    Because we are loading prices as of a certain date, you don’t need to manually adjust for pricing changes that should have occurred by said date. 
                    
                    <br></br>
                    <br></br>
                    <u>For example</u>: if you wanted to apply an AMP CAP price reduction event, Nestor will ignore it if you choose a date on or after 1/1/2024.
                    <br></br>
                    <br></br>
                    <b>Modifications to Volume</b>
                    <br></br>

                    If you have applied a Volume modification event, it will only be applied if the event is after the end date of your base period.
                    <br></br>

                    <br></br>
                    <b>Modifications to Rate</b>
                    <br></br>
                    If you have applied a Rate modification event, it will only be applied if it is later than your <em>year 1 start date</em>. 
                    Nestor assumes that your loaded formulary rates are indicative of year 1 expected gross rebate earnings.
                </p>
            </div>
        </>
    )
}