import React, { useState } from 'react';
import { checkVerificationSMS, resendOTP } from '../../utils/accounts';
import FormButton from '../Buttons/FormButton';
import { UseUserContext } from '../../context/UserContext';

export default function TwoFactor({challengeId, username, twoFactor}) {

    const account = UseUserContext();

    const [ twoFactorCode, authenticateTwoFactor ] = useState(null);
    const [ loading, setLoader ] = useState(null);
    const [ response, setR ] = useState({});

    const labelStyles = "flex flex-col";
    const inputStyles = "px-3 py-3 border rounded mt-2 mb-3 tracking-widest text-center";

    function handle2Factor(e) {
        e.preventDefault();
        setLoader(true)
        const payload = {
            phone_number: "",
            code: twoFactorCode,
            username: username,
            scopes: ["me"],
            challenge_id: challengeId,
        };

        checkVerificationSMS(payload, setLoader, account, setR);
    };

    return (
        <>
            <form className={`flex flex-col justify-center items-center h-[100vh] `} onSubmit={handle2Factor}>
                <div className="flex relative bg-primary-light flex-col border py-28 px-24 rounded-xl shadow-lg shadow-gray-600">
                    {/* {addXMark&&<button aria-label='close panel' type='button' className={"absolute top-3 right-3 "} onClick={() => globalBoolHandler("auxLogin", toggler.toggle, toggler.setToggle, false)}> */}
                        {/* <FontAwesomeIcon className={`text-sm transition-all duration-500 hover:rotate-[180deg] ${false ? "" : ""}`} icon={faXmark}/>  */}
                    {/* </button>} */}
                    <div className="text-2xl font-medium pb-2">2FA</div>
                    <div className="text-xs font-medium pb-4">Please enter the 6-digit code sent to your phone number to confirm your account.</div>
                    <label htmlFor="username" className={labelStyles}>
                        <input name="username" className={inputStyles} type="text" autoComplete="username" aria-labelledby="Enter your username" onChange={e => authenticateTwoFactor(e.target.value)} required/>
                    </label>
                    {response.detail&&<div className={`${response.detail.length>0?"my-2":''} text-primary-red max-w-[250px] text-xs`}>{response.detail}</div>}
                    <FormButton buttonText={"Verify"} loading={loading} loadingSize={15} />
                    <div className='text-[0.6rem] mt-5 tracking-tighter'>
                        Resend verification code? <button type='button' onClick={() => resendOTP(challengeId, setR)} className=' text-blue-600 underline underline-offset-2 ' >Resend</button>
                    </div>
                </div>
            </form>
        </>
    );
};