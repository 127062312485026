import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRotateForward} from "@fortawesome/free-solid-svg-icons";
import PopUpSection from "../../components/Section/PopUpSection";
import FormButton from "../../components/Buttons/FormButton";
import { UseUserContext } from '../../context/UserContext';

export default function TwoFactorAuth({ setTogg }) {
    
    const account = UseUserContext();

    useEffect(() => {
        
    }, []);

    return (
        <>
            <PopUpSection>
                <div className="w-[600px] h-[400px] mx-auto bg-white rounded-xl py-20 px-8" >
                    <div>
                        {account.user.userData.full_name} * {account.user.userData.orgid}
                    </div>
                    <div>
                        Enter the code sent to <span className="font-bold text-xl ">{account.user.userData.email}</span>
                    </div>
                    <form className="w-full">
                        <label>
                            <input placeholder="Enter code" className="border w-full py-3 px-3 rounded my-3" />
                        </label>
                        <div className="mb-4 flex gap-2 items-center">
                            Get a new code 
                            <FontAwesomeIcon className={`text-light-blue hover:text-hover-blue cursor-pointer `}  icon={faArrowRotateForward}/>
                        </div>
                        <span className=" italic">
                            Ad interim, please press continue
                        </span>
                        <FormButton onClick={() => setTogg(data => ({...data, authenticated: true}))} buttonText={'Continue'} addClass="rounded-full" />
                    </form>
                </div>
            </PopUpSection>
        </>
    )
}