import React from 'react';
import { Link } from 'react-router-dom';

export default function MobileNavbar({mobile, toggleMobile, reverse}) {
    
    return (
        <>
            <div className={`fixed md:hidden text-white text-3xl h-full  ${mobile? "translate-x-0" : "translate-x-[2000px]"} transition-all duration-1000 ${reverse?reverse:"bg-primary-dark"} w-full z-[500]`}>
                <div  className='flex flex-col h-full gap-20  pb-40 items-center justify-center '>
                    <button onClick={() => toggleMobile(false)}>
                        <Link to={"/"} className='hover:text-hover-blue text-white'>Home</Link>
                    </button>
                    <button onClick={() => toggleMobile(false)}>
                        <Link to={"/about"} className='hover:text-hover-blue text-white'>About</Link>
                    </button>
                    <button onClick={() => toggleMobile(false)}>
                        <Link to={"/privacy"} className='hover:text-hover-blue text-white'>Privacy Policy</Link>
                    </button>
                    {/* <button onClick={() => toggleMobile(false)}>
                        <Link to={"/contact"} className='hover:text-hover-blue text-white'>Contact Us</Link>
                    </button> */}
                </div>
            </div>
        </>
    )
};