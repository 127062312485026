import React from 'react';

export default function PopUpSection({children, popone, poptwo}) {
    return (
        <>
            <div className={popone?popone:"absolute top-0 z-[200] h-[100vh] w-[100vw] bg-gray-500 opacity-[0.6]"}></div>
            <div className={poptwo?poptwo:'absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] z-[200] w-full overflow-y-auto '}>
                {children}
            </div>
        </>
    )
};