import emailjs from '@emailjs/browser';

export const emailSend = ({
    name,
    business,
    email,
    message
}) => {
    const templateParams = {
        name,
        business,
        email,
        message
    };

    emailjs.send(process.env.REACT_APP_SERVICE_ID,process.env.REACT_APP_TEMPLATE_ID, templateParams, process.env.REACT_APP_PUBLIC_KEY)
        .then(res => {
            alert('SUCCESS', res.status, res.text)
        },
        err => {
            alert("FAILED...", err)
        },
    );
};

