import React from 'react';
import { convertYears, formatCurrencyExp } from '../../utils/resultsSummary';
import { UseContextClues } from '../../context/ContextClues';

export default function SummaryTables({table, givenData, percentage}) {

    const context = UseContextClues();
    const dealLength = context.exclusionSettings.dealLength;

    const bodyStyles = "py-2 px-3 border-2 bg-background-light text-sm text-right";
    const headersStyles = "bg-primary-dark text-white px-4 py-2 font-bold"

    const givenYears = [
        "Year1",
        "Year2",
        "Year3",
        "Year4",
        "Year5",
    ];

    return (
        <> 
            <table className="w-full mb-[80px] border">
                <thead>
                    <tr>
                        <th className={headersStyles+' text-left uppercase'}>
                                {table}
                        </th>
                        {givenYears.map((year, idx) => (
                            idx<dealLength&&<th key={idx+". summary "+year} className={headersStyles+" text- whitespace-pre-wrap w-40"}>
                                {convertYears(year, context.settings.year.year1)}
                            </th>
                        ))}
                        <th className={headersStyles}>
                            Total
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {givenData.map((stats, idx) => (
                        <tr key={idx+". header: "+stats.header} className='border '>
                            <td className={headersStyles}>
                                {stats.header}
                            </td>
                            {stats.data?.map((number, idx) => (
                                idx<dealLength&&<td key={idx+". "+number} className={bodyStyles}>
                                    {stats.percentage?(number*100).toFixed(2)+"%":(stats.obj?formatCurrencyExp(number[1]):formatCurrencyExp(number))}
                                </td>
                            ))}
                            <td className={bodyStyles}>
                                {stats.obj&&formatCurrencyExp(stats?.data?.reduce((accu, curr, idx) => idx<dealLength?accu + curr[1]:accu, 0))}
                                {!stats.percentage&&!stats.obj&&formatCurrencyExp(stats?.data?.reduce((accu, curr, idx)=> idx<dealLength?accu + curr:accu, 0))}
                                {stats.percentage&&<>{((stats?.data?.reduce((accu, curr, idx)=> idx<dealLength?accu+curr:accu, 0))/5*100).toFixed(2)}%</>}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    );
};