import React, { useRef, useState } from 'react';
import { UseToggleContext } from '../../context/ToggleContexts';
import Rates from './Rates';
import PopUpSection from '../Section/PopUpSection';
import FormButton from '../Buttons/FormButton';
import XButton from '../Buttons/XButton';
import { updateFormularyMetadata } from '../../utils/formularySelection';
import { globalChangeHandler } from '../../utils/globalHandlers';
import { UseContextClues } from '../../context/ContextClues';

export default function RatesWindow() {

    const boxref = useRef(null);
    const toggler = UseToggleContext();
    const context = UseContextClues();

    const [ editData, editEdit ] = useState(null);

    const inputStyles = 'border py-1 px-3 rounded mt-2';
    const labelStyles = 'flex flex-col mt-3 capitalize tracking-widest';

    function handleSubmit(e) {
        e.preventDefault();
        updateFormularyMetadata(editData,context.formularies, context.addFormularies);
    };
    
    return (
        <>
            <div className='' ref={boxref}>
                <div 
                    style={toggler.pageWidth<1500?{width: toggler.pageWidth}:{}} 
                    className={`fixed right-0 bottom-0 top-[100px] bg-white border py-8 pl-8 pr-8 transition-all duration-500 shadow-xl ${toggler.aux ==='rates' ? " ":"translate-x-[2000px] "}`}
                    >
                        {/* <!-- Rates tab contents --> */}	
                        {editData&&<PopUpSection popone={'absolute  top-0 h-[100vh] w-[100vw] bg-gray-500 opacity-[0.6] ml-[-30px] z-[600]'} poptwo={'absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] z-[610] w-full overflow-y-auto '}>
                            <div className='relative w-[500px] h-full bg-white mx-auto rounded z-[600]'>
                                <XButton clickHandler={() => editEdit(null)} additionalClasses={'pt-3 z-[600]'} />
                                <form onSubmit={handleSubmit} className=' flex flex-col p-10 relative '>
                                    <label className={labelStyles}>formulary
                                        <input disabled onChange={e => globalChangeHandler(e, null, editEdit)} name='formularyid' value={editData.formularyid} className={inputStyles+" disabled:cursor-not-allowed"} />
                                    </label>
                                    <label className={labelStyles}>vendor
                                        <input onChange={e => globalChangeHandler(e, null, editEdit)} name='vendor' value={editData.vendor} className={inputStyles} />
                                    </label>
                                    <label className={labelStyles}>client
                                        <input onChange={e => globalChangeHandler(e, null, editEdit)} name='client' value={editData.client} className={inputStyles} />
                                    </label>
                                    <label className={labelStyles+" mb-4"}>biosimilar posiiton
                                        <input onChange={e => globalChangeHandler(e, null, editEdit)} name='biosimilar_position' value={editData.biosimilar_position} className={inputStyles} />
                                    </label>
                                    <label className={labelStyles}>description
                                        <textarea onChange={e => globalChangeHandler(e, null, editEdit)} name='description' value={editData.description} className={inputStyles+" resize-none h-[150px]"} />
                                    </label>
                                    <FormButton buttonText={'Save edit'} />
                                </form>
                            </div>
                        </PopUpSection>}
                        <Rates {...{boxref, editData, editEdit}} />
                        
                </div>
            </div>
        </>
    )
}