import React from 'react';
import { formatCurrencyExp } from '../../utils/resultsSummary';

export default function CreditForecastingTable({data, reference}) {
    return (
        <>
            <table ref={reference} className='w-full'>
                <thead >
                    <tr>
                        {Object.keys(data[0]).map((header, index) => (
                            <th key={`${index}: header: ${header}`} className='capitalize border py-3 text-lg tracking-wider'>
                                {header.split('_').join(' ')}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {data.map((credit, index) => (
                        <tr key={`${index}: header: ${credit}`} >
                            <td className='text-center border'>
                                {credit.period}
                            </td>
                            <td className='text-right border py-3 px-2'>
                                {formatCurrencyExp(credit.total_credit)}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    );
};