import React, { useEffect, useState } from 'react';
import SearchField from '../SearchField/SearchField';
import { autocomplete } from '../../utils/autoComplete';
import { globalUpdateKeyValue } from '../../utils/globalHandlers';
import { getPipelinePrompts } from '../../utils/pipeline';
import { UseContextClues } from '../../context/ContextClues';
import { useDebouncedCallback  } from 'use-debounce';

export default function PipelineForm({payload, load, setPipe}) {

    const context = UseContextClues();

    const [ categories, setCategories ] = useState([]);
    const [ catName, setCatName ] = useState('');


    const autOdboun = useDebouncedCallback(autocomplete, 1000)
    useEffect(() => {
        autOdboun(payload.create.category?.toLowerCase(), setCategories, "get_catnames");
    }, [payload.create.category, autOdboun]);
    
    function handleSearchChange(value) {
        setCatName(value);
        load(current => ({
            ...current,
            create: {category:value}
        }));
    };

    const pipedeb = useDebouncedCallback(getPipelinePrompts, 1000)
    useEffect(() => {
        pipedeb(catName, context.casenumber, setPipe);
    }, [catName, context.casenumber, pipedeb, setPipe]);
    
    const inputStyles = "flex px-2 py-1 rounded w-full text-primary-dark";
    const labelStyles = "text-white capitalize";

    return (
        <>
            <div>
                <label className={labelStyles}> Choose Category
                    <SearchField name="category" set={categories} setName={handleSearchChange} current={payload.create.category} addedStyle={inputStyles} searchId={"pipelineCategory"} textBlack={true} required />
                </label>
                <label className={labelStyles}> New product name
                    <input name="drugname" type='text' minLength={0} maxLength={30} className={inputStyles} onChange={event => globalUpdateKeyValue(event.target.name, event.target.value, load, 'create')}  />
                </label>
                <label className={labelStyles}> entry market share
                    <input name="entry_mkt_share" type='number' min={0} max={100} className={inputStyles} onChange={event => globalUpdateKeyValue(event.target.name, event.target.value, load, 'create')}  />
                </label>
                <label className={labelStyles}> entry date
                    <input name="startdate" type='date' className={inputStyles} onChange={event => globalUpdateKeyValue(event.target.name, event.target.value, load, 'create')}  />
                </label>
                <label className={labelStyles}> WAC per unit
                    <input name="wac_per_unit" type='number' className={inputStyles} onChange={event => globalUpdateKeyValue(event.target.name, event.target.value, load, 'create')}  />
                </label>
                <label className={labelStyles}> unit per day
                    <input name="unit_per_day" type='number' className={inputStyles} onChange={event => globalUpdateKeyValue(event.target.name, event.target.value, load, 'create')}  />
                </label>
                <label className={labelStyles}> unit per Rx
                    <input name="day_per_rx" type='number' className={inputStyles} onChange={event => globalUpdateKeyValue(event.target.name, event.target.value, load, 'create')}  />
                </label>
                <label className={labelStyles+' flex items-center pt-2 '}> specialty?
                    <input name="specialty_ind" type='checkbox' className={inputStyles+' h-full'} onChange={event => globalUpdateKeyValue("specialty_ind", event.target.checked?"y":"n", load, 'create')}  />
                </label>
            </div>
        </>
    );
};