import React, { useEffect, useRef, useState } from "react";
import { UseContextClues } from '../../context/ContextClues';
import { UseToggleContext } from '../../context/ToggleContexts';
import Loader from "../Loader/Loader";
import { downloadToPDFGlobal } from "../../utils/download";
import FormButton from "../Buttons/FormButton";
import { globalBoolHandler } from "../../utils/globalHandlers";
import VolumeComparisonTable from "../Tables/VolumeComparisonTable";
import PopUpSection from "../Section/PopUpSection";
import ReabateOp from "../SVG/RebateOp";
import Logo259 from "../SVG/Logo259";
import DataMetricsDynamic from "../Tables/DataMetricsDynamic";
import HandleKeydownEscape from '../../hooks/HandleKeydownEscape';
import { movingAvgWAC } from "../../utils/analytics";

export default function KpiPanel (){
    
    const dataMetrics = useRef(null);
    
    const context = UseContextClues();
    const toggler = UseToggleContext();
    const [ volumeComparison, setVCWindow ] = useState(false);
    const [ chartData, addData ] = useState({
        movingAvgWAC: null,
        currentView: "main"
    });

    useEffect(() => {
        if (context.mainData?.volume_comparison?.comparison) {
            setVCWindow(true);
        } else setVCWindow(false)
    }, [context.mainData?.volume_comparison])

    function handleEscape(){
        setVCWindow(false)
    };
    HandleKeydownEscape(handleEscape);

    useEffect(() => {
        if(context.mainData) {
            movingAvgWAC(context.settings.claims.tName, null, addData);
        }
    }, [context.settings.claims.tName, context.casenumber, context.mainData]);

    // const buttonStyle = 'text-sm border border-light-blue bg-white hover:bg-light-blue hover:text-white text-primary-dark border rounded-lg py-1 px-10 '
    const buttonStyle = 'text-sm text-black hover:text-light-blue border-r border-primary-dark first:ml-8 mr-[8%] pr-3 tracking-widest pb-2 pt-2 flex items-end '

    function colorChoice(name) {
        if (name === chartData.currentView) return " text-light-blue font-bold"
        else return " text-black font-normal"
    };   

    return (
        <div style={toggler.toggle?.helpWindow?{height: (toggler.pageHeight-100)*.6+"px"}:{height: (toggler.pageHeight-100)+"px"}} className={`relative overflow-y-none ${!toggler.toggle?.helpWindow&&"row-span-2"} ` }>
            {context.mainData?<div className="p-6 ">
                <div className="whitespace-nowrap">
                    <div className="font-medium text-xl flex items-end border-b border-primary-dark mb-10">
                        <div className="pb-1">Data Metrics</div>
                        <div className="w-full flex overflow-x-auto">
                            <button aria-label="toggle data metrics and map viewer" type="button" className={buttonStyle+colorChoice("main")} onClick={() => globalBoolHandler("currentView", chartData, addData, 'main')}>
                                View data
                            </button>
                            <button aria-label="toggle data metrics and map viewer" type="button" className={buttonStyle+colorChoice("countiesMap")} onClick={() => globalBoolHandler("currentView", chartData, addData, 'countiesMap')}>
                                View map
                            </button>
                            <button aria-label="toggle data metrics and map viewer" type="button" className={buttonStyle+colorChoice("avgWAC")} onClick={() => globalBoolHandler("currentView", chartData, addData, 'avgWAC')}>
                                View moving avg WAC
                            </button>
                            <button aria-label="download data metrics by pdf" type="button" className={buttonStyle+colorChoice("Data_Metrics")} onClick={() => downloadToPDFGlobal(dataMetrics, "Data_Metrics", null, null,null,null ,null, toggler.setStatus)}>
                                Download
                            </button>
                        </div>
                    </div>
                    {chartData.currentView==="main"&&<div>
                        <div className="flex justify-center mt-4 mb-1 text-xs">
                            <div className="w-full text-center font-bold uppercase rounded-l-full " >very low</div>
                            <div className="w-full text-center font-bold uppercase  " >low</div>
                            <div className="w-full text-center font-bold uppercase  " >normal</div>
                            <div className="w-full text-center font-bold uppercase  " >high</div>
                            <div className="w-full text-center font-bold uppercase rounded-r-full " >very high</div>
                        </div>
                        <div className="flex justify-center mb-4 mx-2">
                            <div className="w-full h-4 rounded-l-full bg-hover-blue" ></div>
                            <div className="w-full h-4  bg-light-blue" ></div>
                            <div className="w-full h-4  bg-light-green" ></div>
                            <div className="w-full h-4  bg-light-red" ></div>
                            <div className="w-full h-4 rounded-r-full bg-primary-red" ></div>
                        </div>
                    </div>}
                </div>
                    {context.mainData.success&&<div ref={dataMetrics} style={toggler.toggle?.helpWindow?{height: (toggler.pageHeight-325)*.6+"px"}:chartData.currentView==="countiesMap"?{height:toggler.pageHeight-210}:{height:toggler.pageHeight-280}} className="overflow-y-auto px-1 pb-2">
                        <DataMetricsDynamic {...{setVCWindow, chartData, addData }} />
                    </div>
                }
                {!context.mainData.success&&<div>
                    Failed to load data due to the following issues: {context.mainData.error}
                </div>}
            </div>:
            <div className="p-4">
                <div className='border-2 relative rounded' style={toggler.toggle?.helpWindow?{height: (toggler.pageHeight-160)*.6 +"px"}:{height: (toggler.pageHeight-160)}} >
                    <div style={toggler.toggle?.helpWindow?{height: (toggler.pageHeight-160)*.6 +"px"}:{height: (toggler.pageHeight-160)}} className="w-full rounded opacity-[0.1] flex flex-col justify-center absolute top-0 bottom-0 cover bg-primary-dark m-auto">
                        <div className="flex items-center justify-center">
                            {window.location.href.toLowerCase().includes("app.rebateoptimizer.com")?<ReabateOp switchColors={"#cecece"} scale={"scale(0.5)"} className={""}/>:<Logo259 />}
                        </div>
                    </div>
                    <Loader loading={toggler.loader === "kpi"} loadStyle={toggler.toggle?.helpWindow?{height: (toggler.pageHeight-130)*.6 +"px"}:{height: (toggler.pageHeight-150)}} textContent="Please input claims information to retrieve KPI data " addClass={'font-extrabold'}/>
                </div>
            </div>
            }
            {volumeComparison&&<>
                <PopUpSection 
                    popone={'absolute opacity-[0.8] bg-primary-dark top-0 bottom-0 left-0 right-0 flex items-center flex-col justify-center overflow-y-auto'}
                    poptwo={'bg-primary-light p-6 opacity-[1] absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-[350px] rounded'}
                    >
                    <VolumeComparisonTable />
                    <FormButton buttonText={"OK"} onClick={() => setVCWindow(false)} />
                </PopUpSection>
            </>}
        </div>
    )
}