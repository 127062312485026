import React from "react";
import {
    BarChart,
    Bar,
    Cell,
    XAxis,
    YAxis,
    CartesianGrid,
    ResponsiveContainer,
    Tooltip,
  } from "recharts";

export default function WaterfallBar({data}) {

    const CustomTooltip = ({ payload, label, gradientColor }) => (
        <div className="bg-background-light font-medium text-xs p-4 border shadow-xl rounded">
          <p className="">{label}</p>
          {payload.length &&
            payload.map(({ name, color, value }, index) => {
            //   const textColor = color.includes("url(") ? gradientColor : color;
              return (
                <p key={index} className={name.toString()==="lowerLimit"?'hidden':null} style={{ color: "#000000" }}>
                  {`${name}: ${new Intl.NumberFormat('en').format(value.toFixed(0))}`}
                </p>
              );
            })}
        </div>
      );

    return (
        <div className="w-[800px] h-[600px] p-6 border z-[10]">
            <ResponsiveContainer width="100%" height="100%">
                <BarChart
                width={650}
                height={600}
                data={data}
                margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 20
                }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <YAxis tickFormatter={tick => new Intl.NumberFormat('en').format(tick)} />
                    {/* <Tooltip tick={{fontSize: 10}} /> */}
                    <Tooltip
                        content={({ payload, label }) => (
                        <CustomTooltip
                            payload={payload}
                            label={label}
                            gradientColor="#000"
                        />
                        )}
                    />
                    <Bar dataKey="lowerLimit" stackId="a" fill="transparent" />
                    <Bar dataKey="RXs" stackId="a" fill="#242423">
                        {/* <LabelList dataKey="pv" position="insideTop" /> */}
                        {data.map((item, index) => {
                            if (item.RXs < 0) {
                                return <Cell key={index} fill="#F24236" />;
                            }
                            if (item.Step === "Initial Total Brand RXs") {
                                return <Cell key={index} fill="#5FAD41" />;
                            }
                            if (item.Step.includes( "Net Brand RXs")) {
                                return <Cell key={index} fill="#17b3d3" />;
                            }
                            return <Cell key={index} fill="#F24236" />;
                        })}
                    </Bar>
                    <XAxis 
                        dataKey="Step" 
                        padding={{ left: 20, right: 20 }} 
                        tick={{fontSize: 14, fill: "#242423"}} 
                        angle={-90} 
                        textAnchor="start" 
                        dx={-5} 
                        dy={-45}
                    />
                </BarChart>
            </ResponsiveContainer>
        </div>
      );
};