import { useEffect } from 'react';

export default function HandleKeydownEscape(handleFunction){
    useEffect(() => {
        const handleEsc = (event) => {
            if (event.key === 'Escape') {
                handleFunction();
            }
        };
        window.addEventListener('keydown', handleEsc);
     
        return () => {
           window.removeEventListener('keydown', handleEsc);
        };
    }, [handleFunction]);
};