import React, { useEffect, useImperativeHandle, useState } from 'react';

export default function EditSelect({defaultValue, tpnRef, addedClasses, context, handleChangeOver, mfr, drugname}){

    const [ selected, setSelected ] = useState('');

    useImperativeHandle(tpnRef, () => {
        return {
            resetTPN() {
                setSelected(defaultValue)
            },
        }
    });

    useEffect(() => {
        setSelected(defaultValue)
    }, [defaultValue])

    function handleChange(e) {
        e.preventDefault();
        setSelected(e.target.value);
        if (handleChangeOver) {
            handleChangeOver(e.target.value, mfr, drugname, "tpn");
        };
    };

    return (
        <>
            <select  
                    className={`${ selected && selected !== defaultValue ? "bg-primary-red text-white" : "" } flex flex-row justify-center `+addedClasses } 
                    value={selected} 
                    onChange={handleChange}>
                <option>T</option>
                <option>P</option>
                <option>N</option>
            </select>
        </>
    )
}