import React, { useState } from 'react';
import XButton from '../Buttons/XButton';
import FormButton from '../Buttons/FormButton';
import { getSortMethod, globalBoolHandler } from '../../utils/globalHandlers';

export default function EventFilter({toggle, events, modify}) {

    const [ filtered, filter ] = useState({
        startdate: {
            actual: 'startdate',
            bool: false,
            shown: 'start date',
        },
        name: {
            actual: 'ename',
            bool: false,
            shown: 'event',
        },
        type: {
            actual: 'etype',
            bool: false,
            shown: 'type',
        },
        modify: {
            actual: 'modify',
            bool: false,
            shown: 'modify',
        },
        order: [],
        asc: true
    });

    function handleChange(e) {
        const {name, checked} = e.target;

        filter(data=> ({
            ...data,
            order: checked?[...filtered.order, `${filtered.asc?'+':"-"}${filtered[name].actual}`]:filtered.order.filter(rule => rule.substring(1)!==`${filtered[name].actual}`)
        }));
    };

    function handleClear() {
        filter(data => ({
            ...data,
            order: []
        }))
    };

    function handleSave(e) {
        e.preventDefault()
        
        modify(data => ({
            ...data,
            filteredEvents: events.sort(getSortMethod(...filtered.order))
        }));
    };

    return (
        <>
            <div className='absolute top-12 right-[135px] shadow-xl rounded border'>
                <div className='relative h-[450px] w-[300px] bg-white '>
                    <XButton clickHandler={() => toggle(false)} additionalClasses={'py-3'} />
                    <div className='p-8 h-[410px]'>
                        <div className='border-4 h-full'>
                            Sort by:
                            <div className='flex flex-col'>
                                {Object.keys(filtered).map((rule, idx) => (<>
                                    {(rule!=='order'&&rule!=='asc')&&
                                    <label key={`${idx} rule filter - ${rule}`} className='flex justify-between px-8 py-1'>{rule}
                                        <input type='checkbox' name={rule} onChange={handleChange} />
                                    </label>}
                                </>))}
                            </div>
                            <div className='border-t my-2 px-8 py-3'>
                                <label className='flex justify-between'>ascending order
                                    <input disabled="true" checked={filtered.asc} type="checkbox" className='disabled:cursor-not-allowed' onChange={(e) => globalBoolHandler('asc', filtered, filter, e.target.checked)} />
                                </label>
                            </div>
                            <div className='border-t my-2 '>
                                <ul className='p-2 flex flex-wrap gap-2'>
                                    {filtered.order.map(rule => (<>
                                        <li className='border-2 p-2 hover:bg-light-blue hover:text-white'>
                                            {rule[1]==='e'?filtered[rule.substring(2)].shown:filtered[rule.substring(1)].shown}
                                        </li>
                                    </>))}
                                </ul>
                            </div>
                        </div>
                        <div className='flex gap-4'>
                            {/* <FormButton typeB={true} buttonText={'clear'} onClick={handleClear} /> */}
                            <FormButton typeB={true} buttonText={'sort'} onClick={handleSave} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};