import React, { useState } from 'react';
import { saveDealStatus } from '../../utils/loadSave';

export default function SaveCaseForm({selectedCase, current, updated, updateMe, optionalColor}){

    const [ status, setStatus ] = useState(current);

    function handleChange(e) {
        e.preventDefault();
        // e.stopPropagation();
        saveDealStatus(selectedCase, status);
        updateMe(updated+=1);
    };

    return (
        <>
             <form className='flex justify-around' onSubmit={handleChange}>
                <select  value={status} className={`py-2 px-4 rounded border ${optionalColor&&"bg-light-blue"}`} onChange={e => setStatus(e.target.value)}>
                    <option value={"draft"}>
                        Draft
                    </option>
                    <option value={"offered"}>
                        Offered
                    </option>
                    <option value={"accepted"}>
                        Accepted
                    </option>
                    <option value={"executed"}>
                        Executed
                    </option>
                    <option value={"amended"}>
                        Amended
                    </option>
                </select>
                <button className='px-3 py-1 border rounded text-white bg-light-blue hover:bg-hover-blue hover:shadow-xl font-bold'>
                    Save
                </button>
            </form>
        </>
    )
}