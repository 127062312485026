import React, { useState } from 'react';
import PopUpSection from '../Section/PopUpSection';
import { globalBoolHandler, globalChangeHandler } from '../../utils/globalHandlers';
import { UseContextClues } from '../../context/ContextClues';
import { UseToggleContext } from '../../context/ToggleContexts';
import { UseUserContext } from '../../context/UserContext';
import { addCustomList, deleteMyList, listCustomListValues } from '../../utils/customLists';
import ImportPanel from '../ImportWindow/ImportPanel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import HandleKeydownEscape from '../../hooks/HandleKeydownEscape';

export default function UserDefinedLists() {

    const account = UseUserContext();
    const context = UseContextClues();
    const toggler = UseToggleContext();

    const listType = [
        "Medispan Drugname",
        "Category",
        "NDC",
        "NPI",
        "NCPDP",
    ];

    const inputStyles = "px-3 py-3 rounded-lg w-full my-1 text-black";

    // move to utils or create custom component

    const [ messages, setMessage ] = useState("");
    const [ listVals, setListVals ] = useState([]);
    const [ file, setFile ] = useState(null);
    const [ loaded, setLoaded ] = useState(null);
    const [ slim, slimDown ] = useState("");
    const [ paginate, increase ] = useState(1000000);

    const [ loading, setLoading ] = useState(false);
    const [ saveLoading, setSaveLoading ] = useState(false);
    
    const [ switched, toggle ] = useState(false);

    const [ payload, setPay ] = useState({
        listName: "",
        listType: "Medispan Drugname",
        value: "",
        values: ""
    });


    const fileReader = new FileReader();

    function handleLoad(e) {
        e.preventDefault();
        
        if (file) {
            fileReader.onload = function(e) {
                const text = e.target.result;
                
                setLoaded(text);
                slimDown(text.slice(0,1000000))
            };
            fileReader.readAsText(file);
        };
    };

    function handleNext() { 
        slimDown(loaded.slice(paginate, paginate+1000000));
        increase(paginate+1000000);
    };

    function handleDelete(e, name){
        e.preventDefault();
        deleteMyList(name, account.user.userData.orgid, context.setModels);
    };

    function handleChange(e, name){
        e.preventDefault();
        listCustomListValues(name, setListVals, setLoading);
    };

    function handleSubmit(e) {
        e.preventDefault();
        // console.log(e)
        const usePay= [{
            org_id: account.user.userData.orgid,
            list_name: payload.listName,
            list_type: payload.listType,
            value: payload.value,
        }]
        addCustomList(usePay, context.setModels);
    };
    function handleMultiple(e) {
        e.preventDefault();
        let coy =  slim.split(/, ?|\n/gm);
        
        addCustomList(coy.map(ele => ({
            org_id: account.user.userData.orgid,
            list_name: payload.listName,
            list_type: payload.listType,
            value: ele,
        })), context.setModels, setSaveLoading);
    };

    function handleEscape(){
        globalBoolHandler("userDefinedLists", toggler.toggle, toggler.setToggle, false)
    };
    HandleKeydownEscape(handleEscape);

    return (
        <>
            <PopUpSection poptwo={`absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] z-[200] w-full overflow-y-auto ${toggler.pageHeight<800?'top-[2%] bottom-0 translate-y-[-1%]':"top-[0%] translate-y-[-50%]"}`}>   
            <div className=' bg-primary-dark rounded-xl w-[1400px] py-10 flex mx-auto justify-center flex-col px-8 overflow-y-auto'>
                    <div className='grid grid-cols-[2fr,2fr,1fr] gap-3'>

                        <div className='bg-primary-dark pr-2'>
                            <div className='flex justify-between mb-6 gap-4'>
                                <button className='w-full bg-light-blue hover:bg-hover-blue text-white shadow-2xl py-3 rounded-xl' onClick={() => toggle(!switched)}>switch</button>
                                {!switched&&<button type='button'  onClick={handleLoad} className='text-white border rounded-lg border-white  w-40 hover:bg-light-blue '>
                                    Load
                                </button>}
                            </div>
                            {!switched&&<div className='pb-4'>
                                {messages&&<span className='text-white'>{messages}</span>}
                                <ImportPanel {...{setMessage, file, setFile, height:"h-[200px]"}} />
                            </div>}
                            <label className='text-white'>List Name
                                <input name='listName' className={inputStyles}  onChange={(e) => globalChangeHandler(e, null, setPay)}/>
                            </label>
                            <label className='text-white'>List Type
                                <select name='listType' className={inputStyles} onChange={(e) => globalChangeHandler(e, null, setPay)}>
                                    {listType.map(list => (
                                        <option value={list}>
                                            {list}
                                        </option>
                                    ))}
                                </select>
                            </label>
                            {switched&&<form onSubmit={handleSubmit}>
                                <label className='text-white'>List value
                                    <input name='value' className={inputStyles}  onChange={(e) => globalChangeHandler(e, null, setPay)}/>
                                </label>
                                <button className='py-3 px-3 mt-4 bg-light-blue hover:bg-hover-blue text-white rounded shadow-xl w-full'>submit</button>
                            </form>}
                            
                            {!switched&&<label className='text-white '>List values
                                <textarea value={slim} className='w-full p-4 h-[360px] my-1 align-top resize-none rounded-xl text-black' onChange={e => slimDown(e.target.value)}></textarea>
                            </label>}
                        </div>


                        
                        <div style={{minHeight: toggler.pageHeight-500}}  className='bg-white overflow-y-auto  rounded-xl'>
                            <table>
                                <thead>
                                    <tr>
                                        <td className='bg-primary-dark text-white w-[60%] py-3 uppercase border pl-2 rounded-tl-xl'>name</td>
                                        <td className='bg-primary-dark text-white w-[40%] py-3 uppercase border text-center '>type</td>
                                        <td className='bg-primary-dark text-white w-[10%] py-3 uppercase border text-center px-2 rounded-tr-xl'>delete</td>
                                    </tr>
                                </thead>
                                <tbody className='overflow-y-auto'>
                                    {context.models.customList?.map((indiList => (
                                        <tr className=' border hover:bg-primary-dark hover:text-white cursor-pointer' onClick={(e) => handleChange(e, indiList.list_name)}>
                                            <td className='pl-2 py-4'>
                                                {indiList.list_name}
                                            </td>
                                            <td className='py-4'>
                                                {indiList.list_type}
                                            </td>
                                            <td className='text-center py-4 hover:bg-primary-red  '>
                                                <button type='button' className=' w-full' onClick={(e) => handleDelete(e, indiList.list_name)}>
                                                    <FontAwesomeIcon icon={faTrashCan}/>
                                                </button>
                                            </td>
                                        </tr>
                                    )))}
                                </tbody>
                            </table>
                        </div>



                        <ul style={{minHeight: toggler.pageHeight-500}} className='bg-white p-2 h-inherit overflow-y-auto h-full rounded-xl'>
                            {loading? 
                            <div className='flex items-center justify-center h-full'>
                                <l-tail-chase
                                    size={ "30"}
                                    speed="1.75" 
                                    color="black" 
                                ></l-tail-chase>
                            </div>
                            :listVals.map((val, idx) => (
                                <li key={`${idx+1}. custom lists ${val.value} `} className={`pb-1 ${val.value?"":"text-primary-red"}`}>  
                                    {idx+1}. {val.value?val.value:"** N/A - No Entry **"}
                                </li>
                            ))}
                        </ul>
                
                    </div>
                    <div>
                        <button className='text-white border rounded-lg  mt-4 py-2 w-40 bg-light-blue hover:bg-inherit' onClick={() => globalBoolHandler("userDefinedLists", toggler.toggle, toggler.setToggle, false)}>
                            Cancel
                        </button>
                        
                        <button type='button' className='text-white border rounded-lg mt-4 py-2 w-40 bg-light-blue hover:bg-inherit ml-2' onClick={handleNext}>
                            Next
                        </button>

                        <button onClick={handleMultiple} type='button' className='text-white border rounded-lg border-white mt-4 py-2 w-40  hover:bg-light-blue float-right'>
                            {saveLoading?<l-tail-chase size={ "20"} speed="1.75" color="white" ></l-tail-chase>:"Save"}
                        </button>

                    </div>
                </div>
        

            </PopUpSection>
        </>
    );
};