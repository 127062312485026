import React from 'react';
import { UseToggleContext } from '../../context/ToggleContexts';


export default function PageSection(props) {

    const toggler = UseToggleContext();

    return (
        <div style={{height: toggler.pageHeight}} className=' overflow-y-auto' >
            {props.children}
        </div>
    )
}; 