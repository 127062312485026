import React, { useEffect, useState } from 'react';
import { getUserLogs } from '../utils/accounts';
import { convertDateToUTC } from '../utils/globalHandlers';
import { UseToggleContext } from '../context/ToggleContexts';

export default function UserLogs(){

    const toggler = UseToggleContext();

    const [ logs, addLogs ] = useState([]);

    useEffect(() => {
        async function retrieveLogs() {
            addLogs(await getUserLogs());
            
        };
        retrieveLogs()
    }, []);

    const tableHeads = [
        
        "user id",
        "username",
        "login time",
        "ip address",
        "status",
        "failure reason",
        "client browser",
    ];

    const tableBody = "border-t border-b px-3 py-1 text-center"

    function convertTime(date){
        const data = new Date(date);
        const useDate = convertDateToUTC(data);
        return `${useDate.getMonth() + 1}/${useDate.getDate()}/${useDate.getFullYear()} - ${useDate.getHours()}:${useDate.getMinutes()}:${useDate.getSeconds()}.${useDate.getMilliseconds()}   `
    };

    return (
        <>
            <div style={{height: toggler.pageHeight}} className='bg-background- '>
                <div style={{height: toggler.pageHeight-220}} className='max-w-[1260px] relative mx-auto  px-8 py-4  '>
                    <div className='capitalize text-2xl font-medium pb-6 pt-6'>
                        user logs
                    </div>
                    <div className='h-full overflow-auto shadow-xl rounded-xl'>
                        <table  className=' border bg-white overflow-y-auto' >
                            <thead>
                                <tr className='sticky top-0 left-0 bg-primary-dark text-white'>
                                    <th className='border'>

                                    </th>
                                    {tableHeads.map((titles, idx) => (
                                        <th className=' whitespace-nowrap px-2 py-3 capitalize' key={`${idx}: user logs heads - ${titles}`}>
                                            {titles}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody >
                                {logs.map((user, idx) => (
                                    <tr className={idx%2===0?"bg-background-light":""}>
                                        <td className={tableBody+" font-bold text-center bg-primary-dark text-white border"}>{idx+1}</td>
                                        <td className={tableBody}>{user.id}</td>
                                        <td className={tableBody}>{user.username}</td>
                                        <td className={tableBody}>{convertTime(user.login_time)}</td>
                                        <td className={tableBody}>{user.ip_address}</td>
                                        <td className={tableBody}>{user.status}</td>
                                        <td className={tableBody}>{user.failure_reason?user.failure_reason:"n/a"}</td>
                                        <td className={tableBody}>{user.user_agent}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
};