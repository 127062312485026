import React from "react";
import {
    Bar,
    XAxis,
    ResponsiveContainer,
    BarChart
  } from 'recharts';

export default function TinyBarChart({data}) {

    return (
        <div className="w-[300px] h-[150px] p-1 border z-[10] bg-background-light shadow-2xl rounded">
            <ResponsiveContainer width="100%" height="100%">
                <BarChart width={100} height={100} data={data} >
                    <Bar dataKey="uv" fill="#17b3d3" />
                    <XAxis dataKey={'name'} scale="point" padding={{ left: 40, right: 40 }} tick={{fontSize: 12, fill: "#242423"}}  textAnchor="middle" />
                </BarChart>
            </ResponsiveContainer>
        </div>
    )
}