import React, { useState } from 'react';
import PopUpSection from '../Section/PopUpSection';
import XButton from '../Buttons/XButton';
import { globalBoolHandler } from '../../utils/globalHandlers';
import { UseToggleContext } from '../../context/ToggleContexts';
import ImportPanel from '../ImportWindow/ImportPanel';
import { processIncomingDataBatch, uploadMedispan } from '../../utils/medispanAdmin';
import HandleKeydownEscape from '../../hooks/HandleKeydownEscape';

export default function MedispanAdmin() {
    const toggler = UseToggleContext();

    const [ file, setFile ] = useState(null);
    const [ message, setMessage ] = useState('');
    const [ input, typeInputs ] = useState({
        incoming: '',
        master: ''
    });

    function handleXout() {
        globalBoolHandler("medispanAdmin", toggler.toggle, toggler.setToggle, !toggler.toggle.medispanAdmin)
    };

    HandleKeydownEscape(handleXout);

    function uploadFile(e) {
        e.preventDefault();
        if (file) {
            uploadMedispan(file);
        }
    };

    function handleChange(e) {
        const {name, value} = e.target;
        typeInputs(data => ({
            ...data,
            [name]: value
        }));
    };
    
    function handleSubmit(e) {
        e.preventDefault();
        if (input.incoming&&input.master) {
            processIncomingDataBatch(input.incoming, input.master);
        };
    };

    const labelStyles = 'flex flex-col';
    const inputStyles = 'border px-2 py-1 mt-1';

    return  (
        <>
            <PopUpSection>
                <div className='w-[800px] h-[620px] bg-white relative mx-auto rounded'>
                    <XButton additionalClasses={'pt-3'} clickHandler={handleXout} />
                    <div className='p-8'>
                        <div className='flex'>
                            <ImportPanel {...{file, setFile, setMessage, accept: ".csv", acceptedFileType:".csv files only"}} /> 
                            <button disabled={!file} className=' disabled:cursor-not-allowed border ml-2 rounded text-white font-bold bg-light-blue hover:bg-hover-blue hover:shadow-xl'>
                                <div onClick={uploadFile} className='rotate-90 tracking-widest uppercase '>
                                    upload
                                </div>
                            </button>
                        </div>
                        <div className='h-8 flex items-center justify-center text-primary-red'>
                            {message.length?'Error: '+message:""}
                        </div>
                        <form onSubmit={handleSubmit} className='border rounded-lg flex flex-col px-4 py-6'>
                            <label className={labelStyles}>Incoming
                                <input value={input.incoming} name='incoming' onChange={handleChange} className={inputStyles} required />
                            </label>
                            <label className={labelStyles}>Master
                                <input value={input.master} name='master' onChange={handleChange} className={inputStyles} required />
                            </label>
                            <button className='mt-4 py-1 border rounded-full text-white bg-light-blue hover:bg-hover-blue hover:shadow-lg'>
                                submit
                            </button>
                        </form>
                    </div>
                </div>
            </PopUpSection>
        </>
    )
}