import React from 'react';
import { HashRouter } from 'react-router-dom'
import AppSection from './components/Section/AppSection';
import UserContextProvider from './context/UserContext';
import ToggleContextProvider from './context/ToggleContexts';
import ContextCluesProvider from './context/ContextClues';

function App() {

  return (
    <ToggleContextProvider>
      <UserContextProvider>
        <ContextCluesProvider>
          <HashRouter>
              <AppSection />
          </HashRouter>
        </ContextCluesProvider>
      </UserContextProvider>
    </ToggleContextProvider>
  );
}

export default App;
