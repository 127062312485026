import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { globalBoolHandler, handleGlobalLogout } from '../../utils/globalHandlers';
import { UseContextClues } from '../../context/ContextClues'; 
import { UseUserContext } from '../../context/UserContext';
import { UseToggleContext } from '../../context/ToggleContexts';
import { logout } from '../../utils/accounts';

export default function NavbarDropdown() {

    const location = useLocation()
    const account = UseUserContext();
    const context = UseContextClues();
    const toggler = UseToggleContext();

    function handlLogout() {
        logout(handleGlobalLogout, account, context, toggler);
    };

    const dropDownLinks = "pl-12 pr-8 py-4 hover:bg-hover-blue hover:text-white";

    return (
        <>
            <div className={`absolute top-20 ${location.pathname==="/"?'right-10':'right-0'} z-[100] bg-white shadow-xl rounded-bl-xl font-medium`} >
                <ul className='flex flex-col'>
                    <div className='pl-12 pr-8 py-4 bg-primary-dark text-white w-[300px]'><span className=''>{account.user.userData.full_name}</span></div>
                    <div className='pl-12 pr-8 py-4 bg-primary-dark text-white capitalize'><span className=''>{account.user.userData.user_type.split("_").join(" ")}</span></div>
                    <div className='pl-12 pr-8 py-4 bg-primary-dark text-white capitalize'>
                        <span className=''>User guide </span>
                        <span className='lowercase'>unavailable </span>
                        {/* <Toggle
                            className='float-right text-primary-red'
                            id='helpwindow-toggle'
                            defaultChecked={toggler?.helpWindow}
                            onChange={(e) => globalBoolHandler('helpWindow', toggler.toggle, toggler.setToggle, !toggler.toggle?.helpWindow )} 
                        /> */}
                    </div>
                    {account.user.userData.user_type==="client_mgr"&&<Link to={"/case-manager"} className={dropDownLinks}>Case Management</Link>} 
                    {account.user.userData.user_type==="client_mgr"&&<Link to={"/vendor-console"} className={dropDownLinks}>Vendor Console</Link>} 
                    <Link to={"/account-settings"} className={dropDownLinks}>Settings</Link>
                    {account.user.userData.user_type==="superadmin"&&<Link to={"/user-logs"} className={dropDownLinks}>(temporary) User logs</Link>}
                    {account.user.userData.user_type==="superadmin"&&location.pathname==="/"&&<button onClick={() => globalBoolHandler("medispanAdmin", toggler.toggle, toggler.setToggle, !toggler.toggle.orgSettings)} className={dropDownLinks+" text-left"}>Medispan Admin</button>} 
                    {account.user.userData.user_type==="superadmin"&&location.pathname==="/"&&<button onClick={() => globalBoolHandler("copyFormularyNewOrg", toggler.toggle, toggler.setToggle, !toggler.toggle.orgSettings)} className={dropDownLinks+" text-left"}>Copy Formulary</button>} 
                    {account.user.userData.user_type==="client_mgr"&&<Link to={"/revenue-cycle-management"} className={dropDownLinks}>Revenue Cycle Management</Link>}
                    {account.user.userData.user_type==="client_mgr"&&location.pathname==="/"&&<button onClick={() => globalBoolHandler("rebateCredit", toggler.toggle, toggler.setToggle, !toggler.toggle.orgSettings)} className={dropDownLinks+" text-left"}>Rebate Credit Console</button>} 
                    {account.user.userData.user_type==="client_mgr"&&location.pathname==="/"&&<button onClick={() => globalBoolHandler('tableManagement', toggler.toggle, toggler.setToggle, true )} className={dropDownLinks+" flex"}>Table Management</button>}
                    {location.pathname==="/"&&<button onClick={() => globalBoolHandler('myFiles', toggler.toggle, toggler.setToggle, true )} className={dropDownLinks+" flex"}>My Files</button>}
                    <button aria-label='log out current user' className={dropDownLinks+' flex border-t rounded-bl-xl'} onClick={handlLogout}>
                        Logout 
                    </button>
                </ul>
            </div>
        </>
    )
};