import React from "react";
import {
    ComposedChart,
    Line,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    ResponsiveContainer,
    Tooltip
  } from 'recharts';

export default function LineBar({width, height, data, teller, bar, crossLine, customToolTip}) {


    const CustomTooltip = ({ payload, label, gradientColor }) => (
        <div className="bg-background-light font-medium text-xs p-4 border shadow-xl rounded">
          <p className="font-bold">{label}</p>
          <p className="">{`Excluded status: ${payload[0]?.payload?.excluded_status}`}</p>
          <p className="">{`Rebated status: ${payload[0]?.payload?.rebated_status}`}</p>
          {payload.length &&
            payload.map(({ name, color, value }, index) => {
            //   const textColor = color.includes("url(") ? gradientColor : color;
              return (
                <p key={index} className={name==="lowerLimit"&&'hidden'} style={{ color: "#000000" }}>
                  {`${name}: ${new Intl.NumberFormat('en').format(value.toFixed(0))}`}
                </p>
              );
            })}
        </div>
      );

    return (
        <div className={`${width?width:'w-[800px]'} ${height?height:"h-[700px]"} p-6 border z-[10]`}>
            <ResponsiveContainer width="100%" height="100%">
                <ComposedChart
                width={500}
                height={350}
                data={data}
                margin={{
                    top: 20,
                    right: 0,
                    bottom: 300,
                    left: 0,
                }}
                >  
                    <CartesianGrid stroke="#f5f5f5" />
                    <XAxis dataKey={teller} scale="point" padding={{ left: 20, right: 20 }} tick={{fontSize: 12, fill: "242423"}} angle={-90} textAnchor="end" />
                    {!customToolTip?<Tooltip formatter={(value) => bar==="percentatge"?new Intl.NumberFormat('en').format(value)+"%":new Intl.NumberFormat('en').format(value)+""} />
                    :<Tooltip
                        content={({ payload, label }) => (
                        <CustomTooltip
                            payload={payload}
                            label={label}
                            gradientColor="#000"
                        />
                        )}
                    />}
                    <YAxis orientation="left" tickFormatter={tick => bar==="percentage"?`${tick}%`:`${tick}`} />
                    <Bar dataKey={bar} barSize={200} fill="#2B3A67" />
                    {crossLine&&
                        <>
                            <Line yAxisId="right" type="monotone" dataKey="overall" stroke="#F24236" />
                            <YAxis yAxisId="right" orientation="right"  tickFormatter={tick => `${tick}%`} domain={[0, 100]} allowDataOverflow={true} />
                        </>
                    }
                </ComposedChart>
            </ResponsiveContainer>
        </div>
    )
}