import React, { useState } from 'react';
import { UseContextClues } from '../../context/ContextClues';
import { UseToggleContext } from '../../context/ToggleContexts';
import { refreshCasenumber } from '../../utils/loadSave';
import { globalBoolHandler, globalNewProjectSettings, handleGlobalClear } from '../../utils/globalHandlers';
import SettingsForm from '../Forms/SettingsForm';
import Year1StartDate from '../YearStartDate/Year1StartDate';

export default function Create({ caseNumber, updateCase, buttonStyles }) {

    const context = UseContextClues();
    const toggler = UseToggleContext();

    const [ newSettings, setNewSettings ] = useState({
        modelSettings: {
          channelDesignation: "",
          daysBreak: "83",
          modelSpecialtyList: "",
          lDDList: "",
          priceDate: "",
        },
        year:{
          year1: ""
        },
        estimateLife: 0,
        dealLength: 5,
    });
    const [ error, setError ] = useState('');

    function handleNewSettings(e){
        const { name, value } = e.target;
        if (name==="estimateLife"||name==="dealLength") {
            setNewSettings(data => ({
                ...data,
                [name]: +value
            }));
        } else {
            setNewSettings(data => ({
                ...data,
                modelSettings: {
                    ...data.modelSettings,
                    [name]: value
                }
            }));
        }
    };

    function handleSubmit(e) {
        e.preventDefault();
        if (/^[a-zA-Z][a-zA-Z0-9_ ]*$/.test(caseNumber)) {
            e.preventDefault();
            context.setCase(caseNumber.split(" ").join("_"));
            handleGlobalClear(context, toggler, newSettings);
            globalBoolHandler("loadSave", toggler.toggle, toggler.setToggle, false, "saveLoad", false, "breakEven", false);
            refreshCasenumber(caseNumber);
        } else {
            setError('Name must contain only letters, numbers, spaces, or the underscore character.')
        }
    };

    return (
        <>
            <form className='flex flex-col overflow-y-auto' onSubmit={handleSubmit}>
                <>
                    <div className='font-medium uppercase'>Project Name <span className='text-primary-red normal-case tracking-tighter'>{error}</span></div>
                    <input type='text' className='border my-2 py-3 px-2 rounded' onChange={e => updateCase(e.target.value)} placeholder='Enter a case name..' required/>
                    <Year1StartDate additionalClasses={"py-2 uppercase"} handleInput={(e) => globalNewProjectSettings(e, newSettings, setNewSettings)} updateValue={newSettings.year.year1}/>
                    <div className='font-medium uppercase'>project options</div>
                    <SettingsForm additionalClasses={"border p-3 my-2"} allEventsCheckbox={true} handleInput={(e) => globalNewProjectSettings(e, newSettings, setNewSettings)} updateValue={newSettings} handleAltInput={handleNewSettings} popup={'bottom-[-16px]'}/>
                    <div className='flex gap-8'>
                        <button type='submit' className={buttonStyles} >
                            Create new project
                        </button>
                    </div>
                </>
            </form>
        </>
    );
};