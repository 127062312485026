import React, {  } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import SettingsForm from '../Forms/SettingsForm';
import { handleInputChange } from '../../utils/claimsLoad';
import { globalBoolHandler } from '../../utils/globalHandlers';
import PopUpSection from '../Section/PopUpSection';
import { UseToggleContext } from '../../context/ToggleContexts';
import HandleKeydownEscape from '../../hooks/HandleKeydownEscape';


export default function ChannelDesignation(){

    const toggler = UseToggleContext();

    function handleEscape(){
        globalBoolHandler("channelDesignationPanel", toggler.toggle, toggler.setToggle, false)
    }
    HandleKeydownEscape(handleEscape);

    return (
        <PopUpSection >
            <div className='h-[655px] relative max-w-5xl mx-auto justify-center' >
                <button className='absolute right-[15px] top-[12px]' onClick={() => globalBoolHandler("channelDesignationPanel", toggler.toggle, toggler.setToggle, false)}>
                    <FontAwesomeIcon className='hover:rotate-[180deg] duration-500' icon={faXmark}/>
                </button>
                <SettingsForm additionalClasses={"p-8"} handleInput={handleInputChange}/>
            </div>
        </PopUpSection>
    )
};