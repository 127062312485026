import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { formatCurrencyExp } from '../../utils/resultsSummary';
import { formatDateOnly } from '../../utils/globalHandlers';


export default function SimpleLineChart({data}) {

    function formatDate(date) {
        const currentDate = new Date(date);
        return `${currentDate.getMonth()}/${currentDate.getDate()}/${currentDate.getFullYear()}`
        
    }

    const CustomTooltip = ({ payload, label, gradientColor }) => (
        <div className="bg-background-light font-medium text-xs p-4 border shadow-xl rounded">
          {/* <p className="">{label}</p> */}
          {payload.length &&
            payload.map((data, index) => {
              return (
                <>
                    <p key={index}  style={{ color: "#000000" }}>
                        <div>
                            Start date interval: {formatDate(data.payload.start_of_interval)}
                        </div>
                        <div>
                            Average WAC: {formatCurrencyExp(data.payload.avg_wac)}
                        </div>
                    </p>
                </>
              );
            })}
        </div>
      );

    return (
        <div className='absolute top-14 w-full h-[800px] bg-white'>
            <ResponsiveContainer width="100%" height="100%">
                <LineChart
                    width={500}
                    height={300}
                    data={data}
                    margin={{
                        top: 50,
                        right: 50,
                        left: 50,
                        bottom: 125,
                    }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />/
                        <XAxis dataKey="start_of_interval"   tickFormatter={data => formatDate(data) } scale="point" padding={{ left: 20, right: 20 }} tick={{fontSize: 12, fill: "242423"}} angle={-75} textAnchor="end"  />
                        <YAxis tickFormatter={data => formatCurrencyExp(data)} />
                        <Tooltip
                            content={({ payload, label }) => (
                            <CustomTooltip
                                payload={payload}
                                label={label}
                                gradientColor="#000"
                            />
                            )}
                        />
                        <Legend verticalAlign='top'  />
                        <Line type="monotone" dataKey="avg_wac" stroke="#00141c" activeDot={{ r: 8 }} />
                </LineChart>
            </ResponsiveContainer>
        </div>
    )
};