import React, { useEffect } from 'react';
import { UseToggleContext } from '../../context/ToggleContexts';
import { getPaymentFields, getPaymentFiles, deleteRow, addNewRow } from '../../utils/revenueCycleManagement';
import { globalBoolHandler, globalChangeHandler } from '../../utils/globalHandlers';
import CustomSelect from '../CustomSelect/CustomSelect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusCircle, faPlusCircle } from "@fortawesome/free-solid-svg-icons";

export default function FilesSection({pageData, setPageData, fileSelector, selectFile}) {

    const toggler = UseToggleContext();

    useEffect(() => {
        getPaymentFiles(setPageData);
    }, [setPageData]);
    
    useEffect(() => {
        if (fileSelector.pmt_files[0] && fileSelector.claims_file) {
            getPaymentFields(setPageData, fileSelector.pmt_files[0], fileSelector.claims_file);
        }
    }, [setPageData, fileSelector.pmt_files[0], fileSelector.claims_file]);
    
    function addTableRow(idx) {
        return (
            <div className='flex items-center justify-around ' key={`${idx}: payment tale`}>
                <div className='text-center font-bold w-4'>
                    {idx+1}.
                </div>
                <CustomSelect {...{ data: pageData.payment_tables_details, fileSelector, builder: true, selectFile, type: idx,  width:"w-[240px]" }} />
                {idx!==0?<button type='button' onClick={e => deleteRow(e, idx, fileSelector, selectFile)}>
                    <FontAwesomeIcon className={`float-right text-primary-red hover:text-red-700`} icon={faMinusCircle}/> 
                </button>:<div className='w-[20px]'></div>}
            </div>
        );
    };
    
   

    const boxStyle = "flex flex-col justify-center items-center";
    const importButton = "mt-3 mb-4 text-sm py-2 px-10 capitalize bg-light-blue hover:bg-hover-blue rounded-xl text-white font-bold";
    const boxContainer = "border px-10 mb-2 rounded flex justify-center flex-col rounded";
    const selectStyles = "border w-full rounded-lg px-2 py-1";

    return (
        <>
            <div className='bg-background-light  overflow-y-auto'>
                <div className=' bg-white mx-auto px-6 text-center overflow-y-auto '>
                    {/* <div className=' py-20 pl-10'>
                        Revenue Cycle Mangement
                    </div> */}
                    <div className='py-6'>
                        <div className={boxContainer}>
                            <div className='flex justify-center items-center relative '>
                                <label className={boxStyle}><span className='font-bold pb-2 text-lg'>Invoice / Payment File</span></label>
                                <button type='button' className='absolute right-[8px]' onClick={e => addNewRow(e, fileSelector, selectFile)}>
                                    <FontAwesomeIcon className='text-primary-green hover:text-green-700' icon={faPlusCircle}/> 
                                </button>
                            </div>
                            {fileSelector.pmt_files.length>0?fileSelector.pmt_files.map((file, index) => addTableRow(index)):addTableRow(0)}
                            <button type='button' className={importButton} onClick={() => globalBoolHandler("importPayment", toggler.toggle, toggler.setToggle, true)} >import</button>
                        </div>
                        <div className={boxContainer}>
                            <label className={boxStyle}><span className='font-bold pb-2 text-lg'>Submission Claims File</span>
                                <CustomSelect {...{ data: pageData.claims_tables_details, fileSelector, selectFile, type: "claims_file" }} />
                            </label>
                            <button type='button' className={importButton} onClick={() => globalBoolHandler("importWindow", toggler.toggle, toggler.setToggle, !toggler.toggle.importWindow)} >import</button>
                        </div>
                        <div className={boxContainer}>
                            <label className={boxStyle}><span className='font-bold pb-2 text-lg'>Formulary Rate File</span>
                                <select onClick={e => globalChangeHandler(e, null, selectFile)} name='formulary_rate_file'  className={selectStyles}>
                                    <option value="">
                                        --- Choose one ---
                                    </option>
                                    {pageData.formulary_rate_files.map((content, idx) => (
                                        <option key={`${idx}: formulary rate - revenue cycle management: ${content}`}>
                                            {content}
                                        </option>
                                    ))}
                                </select>
                            </label>
                            <button type='button' className={importButton} onClick={() => globalBoolHandler("importFormulary", toggler.toggle, toggler.setToggle, true)} >import</button>
                        </div>
                    </div>
                    {/* <div className=''>
                        {(pageData.claims_columns.length>0&&pageData.pmt_columns.length>0)?<button name='page' value={1} onClick={e => globalChangeHandler(e, null, setPageData)} className='py-3 px-44 bg-light-blue hover:bg-hover-blue rounded-xl text-white shadow-2xl mt-20 mb-[200px] '>Next</button>:null}
                    </div> */}
                </div>
            </div>
        </>
    )
}