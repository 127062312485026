import React, { useState } from 'react';
import { UseContextClues } from '../../context/ContextClues';
import { UseToggleContext } from '../../context/ToggleContexts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile } from "@fortawesome/free-solid-svg-icons";
import { loadSaved, formatDate } from '../../utils/loadSave';

export default function Load({ buttonStyles, updateCase, caseNumber, panel }){

    const context = UseContextClues();
    const toggler = UseToggleContext();

    const [ loader, setLoader ] = useState(false);

    function handleCLick(casename) {
        updateCase(casename);
    };

    return (
        <>
            <div className='h-full overflow-y-auto bg-background-light rounded-b-base border'>
            <table className='border py-2 px-1 gap-1 w-full overflow-y-auto bg-background-light'>
                <thead className='border-b '>
                    <tr className='pl-8 py-1 '>
                        <th className='text-left pl-2'>
                            Project name 
                        </th>
                        <th className='text-right pr-2'>
                            Last Modified
                        </th>
                    </tr>
                </thead>
                <tbody className=''>
                    {Object.keys(panel.case_details)?.map((bycase, idx) => (
                        <tr key={`${idx}: Case No.: ${bycase}`} className={`${caseNumber===bycase?"bg-primary-dark text-white  ":""} hover:bg-primary-dark hover:text-white border hover:cursor-pointer rounded px-3 py-3 mb-1 font-light text-left w-full `} onClick={() => handleCLick(bycase)}>
                            <td className=' py-3 pl-2' >
                                <FontAwesomeIcon className={`text-sm `} icon={faFile}/> 
                                <span className='pl-2'>
                                    {bycase}
                                </span>
                            </td>
                            <td className='text-right pr-2'>
                                {formatDate(panel.case_details[bycase])}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            </div>
            <div className='flex gap-8 mt-2'>
                <button type='button' className={buttonStyles} onClick={() => (loadSaved(caseNumber, context, toggler, setLoader))}>
                    {!loader?"load":
                        <l-tail-chase
                            size={ "12"}
                            speed="1.75" 
                            color="white" 
                        ></l-tail-chase>
                    }
                </button>
            </div>
        </>
    )
};