import {extractFromLocal} from "./loadSave";

export async function uploadMedispan(data) {
    try {

        const formData = new FormData();
        formData.append("file", data);

        if (extractFromLocal().accessToken) {
            const data = await fetch('/api/upload-medispan/', {
                method: "POST",
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${extractFromLocal().accessToken}`,
                },
                body: formData
            });
            if (data.ok) {
                const results = await data.json()
                // set(results);
            }
        }
    } catch (err) {
        console.log(err)
    };
};

export async function processIncomingDataBatch(incoming, master) {
    try {
        if (extractFromLocal().accessToken) {
            const data = await fetch(`/api/process-incoming-data-batch/?incoming=${incoming}&master=${master}`, {
                method: "POST",
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${extractFromLocal().accessToken}`,
                }
            });
            if (data.ok) {
                const results = await data.json()
                // set(results);
            }
        }
    } catch (err) {
        console.log(err)
    };
};
