import React, { useState } from 'react';
import { emailSend } from '../utils/emailSend';
import { globalChangeHandler } from '../utils/globalHandlers';
import { UseToggleContext } from '../context/ToggleContexts';


export default function Contact() {
    
    const toggler = UseToggleContext();

    const [ params, setParams ] = useState({
        name: null,
        phone: null,
        email: null,
        message: null,
    });
    
    const labelStyles = "flex flex-col w-full mb-2 ";
    const inputStyles = "border px-4 py-3 rounded-xl mt-2 ";

    var submitEmail = e => {
        e.preventDefault();
        emailSend(params)
    }

    return (
        <div className='bg-background-light  overflow-y-auto'>
            <div style={{height: toggler.pageHeight-100}} className='max-w-[740px] mx-auto  '>
                <div className='h-[200px] flex items-center text-3xl md:text-5xl pl-4 font-bold'>Contact Support</div>
                <form className='mx-4' onSubmit={submitEmail}>
                    <div className='md:flex'>
                        <label className={labelStyles + " mr-1"}>
                            Name
                            <input name='name' className={inputStyles} type='text' onChange={e => globalChangeHandler(e, params, setParams)} required />
                        </label>
                        <label className={labelStyles + " ml-1"}>
                            Phone Number
                            <input name='phone' className={inputStyles} type='text'  onChange={e => globalChangeHandler(e, params, setParams)}/>
                        </label>
                    </div>
                        <label className={labelStyles + " ml-1"}>
                            Email
                            <input name='email' className={inputStyles} type='email'  onChange={e => globalChangeHandler(e, params, setParams)}/>
                        </label>
                    <label className={labelStyles}>
                        Message
                        <textarea placeholder='Describe your issue or question...' name='message' className={inputStyles+" h-40  "} onChange={e => globalChangeHandler(e, params, setParams)} required/>
                    </label>
                    <button className='px-12 py-2 mb-4 rounded-lg mt-4 text-white font-medium bg-light-blue hover:bg-hover-blue' type='submit'>submit</button>
                </form>
            </div>
        </div>
    );
};