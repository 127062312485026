import React, { createContext, useContext, useState } from 'react';

const ContextClues = createContext({});

export default function ContextCluesProvider({children}) {

    const [ casenumber, setCase ] = useState(null);
    const [ models, setModels ] = useState({
        modelList: null,
        customList: [],
        carriers: [],
        memo: ""
    });

    const [ exclusionSettings, setExclusionSettings ] = useState(
        {
          brandDefinition: "mnoBt",
          otcDefinition: "medispanOpLessDiabeticSupplies",
          exclusions: {
              exclusionLdd: false,
              exclusionAuthGenerics: false,
              exclusionVaccines: true,
              exclusionVa: true,
              exclusionItu: true,
              exclusionLtc: true,
              exclusionHomeInfusion: true,
              exclusionOTC: true,
              exclusion340b: true,
              exclusionCosmetic: false,
              exclusionRepack: false,
              exclusionInstitutional: false,
              exclusionMCO: false,
              exclusionClinic: false,
              exclusionNuclear: false,
              exclusionMilitary: false,
              
              exclusionCovidDrugs: false,
              exclusionDAW3456: false,
              exclusionCompounds: false,
              exclusionVitamins: false,
              exclusionNonFdaDrugs: false,
              exclusionMSB: false,
              
          },
          carriers: ["All"],
          events: [],
          compound: [],
          assumptions: [],
          year1: "",
          estimateLife: 0,
          dealLength: 5,
          startDate: "",
          priceDate: "",
          custom_lists: {
            exclusion: [],
            constraint: []
          },
        }
      );
      
      const [ settings, setSettings ] = useState({
        locked: false,
        savedCase: true,
        modelSettings: {
          channelDesignation: null,
          daysBreak: null,
          modelSpecialtyList: null,
          lDDList: null,
          priceDate: "",
        },
        formulary: {
          baseFormulary: "",
          targetFormulary: "",
        },
        claims: {
          tName: "",
          startDate: "",
          endDate: "",
          enablePhcyAnalytics: false
        },
        // assumptions: [],
        year:{
          year1: ""
        },
        crystal: {
          CrystalBallContractYear: "cy1",
          CrystalBallFormulary: "base",
          category: "",
        }
      });
      
      const [ mainData, setMainData ] = useState();
      const [ events, addEvents ] = useState([]);
      const [ results, setResults ] = useState();
      const [ target, setTarget ] = useState();
      const [ summary, setSummary ] = useState();
      const [ crystal, setCrystal ] = useState();
      const [ pricingLens, setPricingLens ] = useState({
          "results": {
            hedgeCheck: true,
            percentageByYear: false,
            totals: {},
            priceLens: {
              passBack: .90,
              blanket : .05,
              price: {
                  Year1: {
                      all: .05,
                      R30: .05,
                      R90: .05,
                      M: .05,
                      S: .05,
                  },
                  Year2: {
                      all: .05,
                      R30: .05,
                      R90: .05,
                      M: .05,
                      S: .05,
                  },
                  Year3: {
                      all: .05,
                      R30: .05,
                      R90: .05,
                      M: .05,
                      S: .05,
                  },
                  Year4: {
                      all: .05,
                      R30: .05,
                      R90: .05,
                      M: .05,
                      S: .05,
                  },
                  Year5: {
                      all: .05,
                      R30: .05,
                      R90: .05,
                      M: .05,
                      S: .05,
                  }
                }
              },
              passBackbyYear: {
                Year1 : 0.9,
                Year2 : 0.9,
                Year3 : 0.9,
                Year4 : 0.9,
                Year5 : 0.9,
              }
          },
          "target": {
            hedgeCheck: true,
            percentageByYear: false,
            totals: {},
            priceLens: {
              passBack: .90,
              blanket : .05,
              price: {
                  Year1: {
                      all: .05,
                      R30: .05,
                      R90: .05,
                      M: .05,
                      S: .05,
                  },
                  Year2: {
                      all: .05,
                      R30: .05,
                      R90: .05,
                      M: .05,
                      S: .05,
                  },
                  Year3: {
                      all: .05,
                      R30: .05,
                      R90: .05,
                      M: .05,
                      S: .05,
                  },
                  Year4: {
                      all: .05,
                      R30: .05,
                      R90: .05,
                      M: .05,
                      S: .05,
                  },
                  Year5: {
                      all: .05,
                      R30: .05,
                      R90: .05,
                      M: .05,
                      S: .05,
                  }
                }
              },
              passBackbyYear: {
                Year1 : 0.9,
                Year2 : 0.9,
                Year3 : 0.9,
                Year4 : 0.9,
                Year5 : 0.9,
              }
          },
          forecastingData: {
            standard: null,
            target: null,
          }
        }
      );
      const [ analytics, setAnalytics ] = useState({
        top20DrugsTable : {
          standard: [],
          target: []
        },
        top20CategoriesTable : {
          standard: [],
          target: []
        },
        highestProductsNoRebatesTable : {
          standard: [],
          target: []
        },
        mfrs : {
          standard: [],
          target: []
        },
        rebatable : {
          standard: [],
          target: []
        },
      });
      const [ analyticsData, addAnalyticsData ] = useState({
        type: "standard",
        drugs: [],
        categories: [],
        waterfall: [],
        macro: null,
        topVolume: []
      });

    const [ formularies, addFormularies ] = useState([]);

    const contextProps = {
        setMainData, 
        mainData,
        setExclusionSettings, 
        exclusionSettings, 
        setResults,
        results,
        setTarget,
        target,
        setPricingLens,
        pricingLens, 
        setSummary,
        summary, 
        setCrystal,
        crystal,
        setSettings,
        settings,
        setAnalytics,
        analytics,
        addFormularies,
        formularies,
        setModels,
        models,
        // maybe move the below to user context
        setCase,
        casenumber,
        addEvents,
        events, 
        addAnalyticsData,
        analyticsData, 
    };

    return <ContextClues.Provider value={contextProps}>{children}</ContextClues.Provider>
};

export const UseContextClues = () => useContext(ContextClues);
