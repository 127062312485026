// new autocompplete - all renders are done via REACT

export async function autocomplete(term, set, url, double) {
    // Common configuration for autocomplete
	try {
		// let data;
		if (double) {
			if (term?.length >= 2) {
					Promise.all([
						await fetch(`/api/${url}/?term=${term}`).then(async data => data.ok&& await data.json()),
						await fetch(`/api/${double}/?term=${term}`).then(async secondSet => secondSet.ok&& await secondSet.json())
					]).then(([drugs, categories]) => {
						set([
							...drugs,
							...categories
						])
					});
				}
		} else {
			if (term?.length >= 2) {
				await fetch(`/api/${url}/?term=${term}`).then(async data => data.ok&& set(await data.json()));
			};
		}

	} catch (err) {
		alert(`error loading drug names due to following: ${err}`);
	};
};