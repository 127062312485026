import React, { useEffect, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

export default function PageTransition({children, unmount, cycle}) {

    const [ pageTransition, transition ] = useState(false);

    const nodeRef = useRef(null);

    useEffect(() => {
        setTimeout(() => {
            transition(true);
        }, 2000);
    }, []);

    const duration = 1500;

    const defaultStyle = {
        transition: `all ${duration}ms ease-in-out`,
        // opacity: 0,
      }



    return (
        <>
            {/* this is where the transition happens */}
            <CSSTransition nodeRef={nodeRef} in={pageTransition} unmountOnExit={unmount?true:false} classNames={cycle} timeout={500}>
                {state => (
                    <>
                        <div ref={nodeRef} 
                        className={
                            ((state==="exited"&&cycle==="starting")?'  ':null)||
                            ((state==="exited"&&cycle==="ending")?'  absolute top-0 bottom-0 left-0 right-0 z-[500] ':null)
                            +
                            ((state==="entering"&&cycle==="starting")?'  ':null)||
                            ((state==="entering"&&cycle==="ending")?' absolute top-0 bottom-0 left-0 right-0 translate-x-[5000px] z-[500] ':null)
                            +
                            ((state==="entered"&&cycle==="starting")?' opacity-1 ':null)||
                            ((state==="entered"&&cycle==="ending")?'  ':null)
                        } 
                            
                            style={{
                            ...defaultStyle,
                            // ...transitionStyles1[state]
                        }}>
                            {children}
                        </div>
                    </>
                )}
            </CSSTransition>
            
            {/* show this after the transition is complete */}
                
        </>
    );
};