import React, { useContext } from 'react';
import { UseContextClues } from '../../context/ContextClues';

export default function LockedMessage() {

    const context = UseContextClues();

    return (
        <>
            {context.settings.locked&&<span className=" font-medium text-primary-yellow bg-primary-dark">(Models must be re-run with modified inputs to show accurate results) </span>}
        </>
    )
}