import React, { useEffect, useState } from 'react';
import PopUpSection from '../Section/PopUpSection';
import { globalBoolHandler } from '../../utils/globalHandlers';
import { UseToggleContext } from '../../context/ToggleContexts';
import AssumptionTable from '../Tables/AssumptionTable';
import { convertToKeyValue, getOrgAssumptions, setOrgAssumptions } from '../../utils/assumptions';
import HandleKeydownEscape from '../../hooks/HandleKeydownEscape';
import FormButton from '../Buttons/FormButton';

export default function OrgSettings() {

    const toggler = UseToggleContext();

    const [ payload, load ] = useState({});
    const [ bool, setBool ] = useState();
    const [ updated, update ] = useState([]);

    useEffect(() => {
        update(convertToKeyValue(payload));
    }, [payload]);

    useEffect(() => {
        getOrgAssumptions(load);
    }, []);

    function changeHandler(e) {
        const { name, value, id, checked } =  e.target;
        let array = []

        if (id === "assumptions-toggle") {
            updated.forEach(ele => {
                if (ele.assumption === 'apply improvement year 1') {
                    array.push({
                        assumption: ele.assumption,
                        value: checked?1:0
                    });
                } else {
                    array.push(ele);
                };
            });
            update(array)
        } else {
            updated.forEach(ele => {
                if (ele.assumption === name) {
                    array.push({
                        assumption: ele.assumption,
                        value: +value
                    })
                } else {
                    array.push(ele);
                };
            });
            update(array)
        }
    };

    function handleSubmit(e) {
        e.preventDefault();
        setOrgAssumptions({ assumptions : updated }, load);
    };

    function handleEscape(){
        globalBoolHandler("orgSettings", toggler.toggle, toggler.setToggle, false);
    };
    HandleKeydownEscape(handleEscape);

    return (
        <>
            <form onSubmit={handleSubmit} className='flex pt-10 justify-center flex-col px-20 overflow-y-auto'>
                <div className='h-[130px] flex items-center tracking-widest text-2xl font-medium'>
                    Set organization assumptions
                </div>     
                <div className='bg-white rounded overflow-y-auto  border p-10 mb-10'>
                    {updated.length>0?<AssumptionTable {...{ dataContext: payload, bool, setBool, changeHandler: changeHandler, excludeHedge: true }} />:<div className='flex h-full items-center justify-center'><l-leapfrog color="black"></l-leapfrog></div>}
                </div>
                <FormButton buttonText={'save'} />
            </form>
        </>
    )
};