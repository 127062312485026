import React, { useContext, useEffect, useState } from 'react';
import { ComposableMap, Geographies, Geography } from "react-simple-maps";
import { scaleQuantize } from "d3-scale";
import csv from "./topojson3.json"
import { UseContextClues } from '../../context/ContextClues';

export default function CountiesMap() {

    const context = UseContextClues();

    const geoUrl = csv
    
    let colorScale 
    = scaleQuantize()
    .domain([1, 10])
    .range([
        "#8DF0B7",
        "#6EEABA",
        "#50E3C6",
        "#50E3C6",
        "#33DCD9",
        "#17B3D3",
        "#117ABC",
        "#0C49A3",
        "#08218A",
        "#070570",
    ]);

    const [data, setData] = useState([]);

    useEffect(() => {
        setData(context.mainData?.topojson);
    }, [context.mainData]);

    return (
        <>
            <ComposableMap projection="geoAlbersUsa">
                <Geographies geography={geoUrl}>
                    {({ geographies }) =>
                        geographies.map(geo => {
                        const cur = data?.find(s => s.fips === geo.id);
                            return (
                                <Geography
                                    key={geo.rsmKey}
                                    geography={geo}
                                    fill={colorScale(cur ? cur.pct_of_total_claims : "#EEE")}
                                />
                            );
                        })
                    }
                </Geographies>
            </ComposableMap>
        </>
    )
};

