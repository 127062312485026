import React, {useEffect, useImperativeHandle, useState} from 'react';

export default function EditInput({ defaultValue, min, max, resetRef, step, className }){
    
    // const inputRef = useRef(null);
    const [ editVal, setEditVal ] = useState();

    useImperativeHandle(resetRef, () => {
        return {
            resetRates() {
                setEditVal(defaultValue)
            },
        };
    });

    useEffect(() => {
        setEditVal(defaultValue)
    }, [defaultValue])
 
    return(
        <div className='whitespace-nowrap'>
            <input type='number' 
                   key={defaultValue}
                   onChange={e => setEditVal(e.target.value)} 
                   value={editVal}
                   min={min}
                   max={max}
                   step={step}
                   className={`border w-20 ${editVal === null || editVal === defaultValue ? "" : editVal > +defaultValue ? "bg-primary-red text-white" : "bg-primary-blue text-white"} ${className?className:""}`}
            />%
        </div>
    )
}