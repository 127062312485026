import React from 'react';
import { copyToClipboard } from '../../utils/download';

export default function AnalyticsRebatableDrugsTable({tableStyles, headerStyles, context, type }) {
    return (
        <>
            <table className={tableStyles}>
                <thead>
                    <tr>
                        <td className={headerStyles}>
                            Drug Name
                        </td>
                    </tr>
                </thead>
                <tbody className='grid grid-cols-4' >
                    {context.analytics.rebatable[type].rdl?.map((row, idx) => (
                        <tr className='hover:bg-primary-blue hover:text-white border py-1 px-2 cursor-pointer' 
                            key={`Rebatable drugs key: ${row.mfr}, index: ${idx}`} 
                            onClick={() => copyToClipboard(context.analytics.rebatable[type].rdl.join("\n") )}>
                            <td >
                                {row}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    )
};