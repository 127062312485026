export default function ReabateOp({switchColors, scale, className}){
    return (
        <svg className={className} transform={scale?scale:"scale(2)"} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            height="100%" width="100%" viewBox="0 0 1024 1024" xmlSpace="preserve">
        <path fill={switchColors} opacity="1.000000" stroke="none" 
            d="
            M543.952087,363.738495 
                C553.227234,365.423096 553.514099,365.644745 550.101318,374.565521 
                C548.566284,378.578125 548.705078,383.225159 544.822937,386.571167 
                C543.483459,387.725647 544.364014,391.649658 544.502991,394.294220 
                C545.411011,411.573456 546.293091,428.855103 547.376709,446.123840 
                C548.865723,469.852081 550.532166,493.569153 552.108521,517.291992 
                C552.527588,523.598633 553.025146,529.904236 553.245117,536.218750 
                C553.419983,541.239014 552.239380,542.101807 547.261658,540.752747 
                C536.670654,537.882263 526.168701,534.750244 515.271545,533.051819 
                C503.231445,531.175232 491.184326,530.005920 478.993439,530.226501 
                C473.091980,530.333313 472.555939,529.493469 472.969604,523.508240 
                C474.953918,494.796021 476.896271,466.080841 478.817474,437.364288 
                C479.861389,421.761078 480.855560,406.154449 481.817352,390.545990 
                C481.989838,387.746979 481.900726,385.343323 479.091248,383.139160 
                C475.085663,379.996521 475.531708,365.104950 479.162567,362.149048 
                C479.796356,361.633057 480.543976,360.745331 481.165192,360.804474 
                C487.443176,361.402313 486.571045,357.592834 486.175293,353.464874 
                C485.683624,348.336700 485.607391,343.140869 485.767731,337.987213 
                C485.884796,334.224579 484.998566,331.600494 481.180145,330.198212 
                C477.924591,329.002655 477.650848,327.211548 479.811157,324.127686 
                C485.258270,316.351898 492.381287,310.913727 501.029388,307.337555 
                C506.002472,305.281097 510.378998,302.843353 510.912964,296.670288 
                C511.009491,295.554474 511.693878,294.463196 513.092468,294.157196 
                C515.053223,294.665161 515.176697,296.271088 515.579346,297.946991 
                C516.333435,301.085236 517.658081,304.111237 521.024170,305.228699 
                C532.765198,309.126343 540.988647,317.485687 548.667480,326.810577 
                C548.190308,329.511017 546.301880,330.822540 543.672363,331.487335 
                C541.822632,331.954956 540.293640,332.699127 540.487183,335.179901 
                C541.159668,343.801727 541.701843,352.433655 542.291626,361.061920 
                C542.362427,362.098297 542.617065,363.034241 543.952087,363.738495 
            M508.303802,407.544464 
                C508.264648,411.023895 508.315369,414.507355 508.161102,417.981659 
                C508.004944,421.498535 510.139069,422.052399 512.931885,422.094025 
                C515.729980,422.135712 517.860474,421.662048 517.768799,418.107239 
                C517.636414,412.973450 517.602539,407.833038 517.701050,402.698822 
                C517.764893,399.370880 517.545959,396.955811 513.051880,397.032166 
                C508.850281,397.103546 507.928162,399.040649 508.263489,402.595490 
                C508.387512,403.910339 508.303467,405.244812 508.303802,407.544464 
            M508.310669,508.362640 
                C508.315338,509.851715 508.321991,511.340759 508.324280,512.829834 
                C508.328217,515.379639 510.036224,515.841614 512.022400,515.643555 
                C514.106995,515.435669 517.384949,516.784973 517.561279,513.264709 
                C517.897339,506.556152 517.658752,499.818787 517.658752,493.178284 
                C508.808502,492.352203 508.360229,492.731445 508.329163,500.456238 
                C508.319824,502.772675 508.317657,505.089142 508.310669,508.362640 
            M517.376404,449.443329 
                C512.267334,446.691437 508.579651,446.845612 508.418152,449.959778 
                C508.078491,456.508118 508.314941,463.086304 508.314941,469.761993 
                C509.932495,469.957642 510.922424,469.956238 511.842651,470.208832 
                C516.893188,471.595123 518.270264,469.399933 517.886047,464.630920 
                C517.501709,459.860931 517.682678,455.045380 517.376404,449.443329 
            M538.207275,358.534027 
                C537.853821,351.108246 537.542480,343.680023 537.112610,336.258667 
                C537.022583,334.704132 537.195557,332.509369 534.801758,332.548065 
                C532.189331,332.590271 532.188354,334.825806 532.379822,336.614868 
                C533.086792,343.222809 533.390259,349.838928 533.407288,356.482422 
                C533.414795,359.402496 535.011475,360.305908 538.207275,358.534027 
            M507.317291,346.418732 
                C507.288055,350.070282 507.338501,353.704102 508.241150,357.289612 
                C508.610077,358.755035 509.107086,359.819519 510.822021,359.608704 
                C512.279175,359.429596 512.742920,358.336365 512.687134,357.098053 
                C512.351562,349.650513 511.976593,342.204498 511.561432,334.760956 
                C511.475769,333.225250 510.541321,332.314270 508.958435,332.341125 
                C506.790558,332.377960 506.773926,333.946136 506.844421,335.516327 
                C506.993195,338.831146 507.152069,342.145477 507.317291,346.418732 
            M499.396332,357.724792 
                C499.782135,359.354065 500.859924,359.928894 502.369568,359.414001 
                C503.734100,358.948669 503.747101,357.638000 503.700775,356.474335 
                C503.416595,349.335480 503.094391,342.198120 502.779785,335.060455 
                C502.702393,333.304810 501.931244,332.204010 500.016754,332.316040 
                C497.893860,332.440247 497.844299,333.866577 497.956329,335.517670 
                C498.440002,342.644836 498.864899,349.775940 499.396332,357.724792 
            M529.664612,348.661652 
                C529.329834,344.206360 528.983093,339.751892 528.667419,335.295258 
                C528.567749,333.887939 528.372498,332.513947 526.581116,332.502075 
                C524.500122,332.488251 524.384216,334.040131 524.294250,335.622192 
                C523.888062,342.771271 524.956238,349.848114 525.299561,356.959900 
                C525.370056,358.420074 525.860718,359.643585 527.629639,359.495178 
                C529.036865,359.377106 529.580078,358.323944 529.613220,357.058319 
                C529.678284,354.571930 529.656250,352.083221 529.664612,348.661652 
            M492.574066,359.586304 
                C495.339233,359.292572 494.855774,357.177399 494.837891,355.417023 
                C494.771942,348.927521 494.726532,342.435699 493.842560,335.985291 
                C493.619904,334.360504 493.888397,332.288361 491.470612,332.370605 
                C489.081787,332.451935 489.253815,334.497131 489.324066,336.137451 
                C489.623108,343.119263 489.995758,350.097900 490.342987,357.077637 
                C490.395905,358.140320 490.746674,359.025085 492.574066,359.586304 
            M520.808228,346.391449 
                C520.641846,342.576691 520.554810,338.755890 520.266907,334.950348 
                C520.163452,333.582428 519.428650,332.351746 517.732483,332.409760 
                C516.043945,332.467529 515.368103,333.627563 515.459839,335.097778 
                C515.914734,342.384552 516.425781,349.668213 516.992065,356.947174 
                C517.098022,358.309814 517.612854,359.708679 519.368591,359.572784 
                C521.358337,359.418762 521.655029,357.820404 521.570984,356.268463 
                C521.409668,353.286591 521.087952,350.313385 520.808228,346.391449 
            M494.519501,373.344147 
                C489.950684,373.344147 485.381866,373.344147 480.813049,373.344147 
                C480.824524,373.828827 480.835968,374.313477 480.847443,374.798157 
                C490.643433,374.054596 500.440491,373.893677 510.239166,374.635620 
                C510.242157,374.208405 510.245148,373.781158 510.248169,373.353943 
                C505.335571,373.353943 500.422974,373.353943 494.519501,373.344147 
            M517.297546,373.371674 
                C517.026672,373.589447 516.178467,372.976440 516.454712,373.950867 
                C516.482544,374.049133 516.739685,374.119720 516.898010,374.138367 
                C524.391235,375.021851 531.878906,374.541168 539.369568,374.707825 
                C539.375183,374.258850 539.380798,373.809845 539.386414,373.360870 
                C532.233154,373.360870 525.079895,373.360870 517.297546,373.371674 
            z"/>
            <path fill={switchColors} opacity="1.000000" stroke="none" 
                d="
            M706.118164,545.069092 
                C686.164062,572.897827 659.001770,586.897461 625.527527,589.306580 
                C599.986328,591.144775 575.560852,585.722839 551.487488,577.910828 
                C532.635864,571.793396 513.589233,566.247437 493.887177,563.754578 
                C474.377136,561.286072 454.795898,560.586853 435.112518,562.647644 
                C406.770630,565.614929 379.038452,572.118530 350.945160,576.520752 
                C322.016907,581.053772 293.577545,579.346558 265.229767,572.751282 
                C246.918823,568.491089 228.654953,563.933594 209.888077,562.030457 
                C191.689514,560.185059 173.668655,561.001648 155.926239,565.821777 
                C154.401901,566.235901 152.948334,567.366272 151.142746,566.664001 
                C150.869110,564.582520 152.468781,564.218628 153.782684,563.667542 
                C175.567368,554.530762 198.303345,551.641357 221.748703,553.210327 
                C242.866135,554.623413 263.496155,559.713867 284.552368,561.487549 
                C307.101349,563.386963 329.444336,561.857239 351.464142,556.455200 
                C372.628784,551.262939 393.743927,545.863831 414.946564,540.832581 
                C461.417816,529.805298 507.290497,531.743042 552.762268,546.728027 
                C571.069397,552.761047 589.346069,559.115906 608.319946,562.943481 
                C651.695068,571.693604 687.019470,558.506042 714.437683,523.883484 
                C716.009399,521.898804 715.971313,521.868652 719.386841,520.415710 
                C716.014160,529.627441 711.439148,537.336853 706.118164,545.069092 
            z"/>
            <path fill={switchColors} opacity="1.000000" stroke="none" 
                d="
            M482.766785,695.394653 
                C470.088745,685.613159 468.937897,672.232666 470.228577,658.223328 
                C471.179718,647.899719 474.883301,638.678162 484.102203,632.932068 
                C497.574249,624.534912 514.622559,628.904297 522.151611,643.313782 
                C529.522827,657.421326 529.651733,672.269348 521.880371,686.349731 
                C514.063477,700.512573 497.925354,704.047180 482.766785,695.394653 
            M500.960175,642.204041 
                C491.851288,641.709473 486.694946,645.042297 484.279846,653.741943 
                C482.295471,660.889954 482.366119,668.290039 484.288513,675.427002 
                C486.525543,683.732117 491.316284,687.190063 498.955902,686.953674 
                C505.735352,686.743896 510.622528,682.311768 512.492920,674.474243 
                C514.057800,667.916748 514.362305,661.293030 512.640320,654.717590 
                C511.096497,648.822571 508.353821,643.860229 500.960175,642.204041 
            z"/>
            <path fill={switchColors} opacity="1.000000" stroke="none" 
                d="
            M670.887268,660.372620 
                C669.765381,656.850769 667.459229,656.035522 664.484131,656.559082 
                C660.964722,657.178467 658.905945,659.257874 658.319519,662.826721 
                C656.741150,672.432922 657.395203,682.081970 657.685181,691.726929 
                C657.752441,693.964661 659.054810,696.859497 656.562622,698.143005 
                C653.690186,699.622375 650.328247,698.651367 647.303162,697.883179 
                C645.600159,697.450745 645.783020,695.452332 645.780457,693.992004 
                C645.754578,679.167542 645.796143,664.342957 645.765076,649.518555 
                C645.759338,646.799438 646.903320,645.423035 649.657227,645.655762 
                C655.407349,646.141663 661.071411,646.039795 666.728943,644.526367 
                C668.532288,644.043945 671.171814,644.109802 672.561890,645.118896 
                C677.806030,648.925720 682.378296,647.185547 687.945801,645.436584 
                C699.491394,641.809814 708.937378,649.450256 709.118530,661.571960 
                C709.275452,672.063843 709.121887,682.560242 709.266296,693.052429 
                C709.312378,696.399963 708.675659,698.597839 704.674561,698.331238 
                C701.328003,698.108337 697.696045,699.298523 697.291809,693.690125 
                C696.571350,683.695190 697.337341,673.706360 696.829895,663.728882 
                C696.548279,658.193787 694.121033,656.336731 688.693726,657.281860 
                C684.844055,657.952271 683.424866,660.626770 683.411438,664.083313 
                C683.373840,673.738525 683.415710,683.397034 683.654236,693.048584 
                C683.727661,696.017517 683.917297,698.686646 679.891663,698.300720 
                C676.560791,697.981445 671.604736,700.378723 671.683960,693.671021 
                C671.786316,685.010681 671.694031,676.347961 671.675537,667.686279 
                C671.670593,665.357361 671.786438,663.013123 670.887268,660.372620 
            z"/>
            <path fill={switchColors} opacity="1.000000" stroke="none" 
                d="
            M189.725952,629.588867 
                C200.155441,630.302429 206.451035,634.902100 209.273239,643.594849 
                C212.695206,654.134888 209.803879,662.595825 199.595856,671.406555 
                C201.079422,675.875610 203.864594,679.805359 206.021606,684.045349 
                C208.168106,688.264526 211.015274,692.206604 211.941757,696.960754 
                C203.898254,700.301025 199.124283,698.762634 195.353165,691.868469 
                C192.559799,686.761780 189.584885,681.745544 187.008896,676.531860 
                C185.145294,672.760010 182.008606,673.094971 178.902893,673.504822 
                C175.441803,673.961609 176.420670,676.925659 176.367416,679.075928 
                C176.247986,683.897339 176.114670,688.734497 176.373352,693.544495 
                C176.609406,697.933411 174.202515,698.387207 170.759155,698.273926 
                C167.448044,698.164856 164.506332,698.444763 164.555389,693.541931 
                C164.720154,677.075928 164.588654,660.606873 164.564743,644.139038 
                C164.560638,641.312561 164.910690,638.418518 164.425720,635.674316 
                C163.532104,630.617554 165.915894,629.042297 170.384537,629.139282 
                C176.697876,629.276367 183.010925,629.426514 189.725952,629.588867 
            M188.968628,642.230896 
                C186.317017,642.209229 183.653107,642.340942 181.016754,642.129272 
                C177.245056,641.826538 175.886032,643.537964 176.251663,647.106628 
                C176.369354,648.255310 176.280411,649.425598 176.278595,650.585999 
                C176.260086,662.396240 178.034241,663.827332 189.694473,661.404724 
                C194.971237,660.308350 197.717606,657.039551 197.853256,651.693787 
                C197.994766,646.117737 195.942566,643.712524 188.968628,642.230896 
            z"/>
            <path fill={switchColors} opacity="1.000000" stroke="none" 
                d="
            M535.756348,688.000000 
                C535.803467,675.836914 535.942627,664.173157 535.850952,652.511169 
                C535.817139,648.219360 537.562317,646.198730 541.819214,646.763733 
                C545.710144,647.280090 549.242981,646.292725 552.961426,645.253784 
                C563.444519,642.324707 572.371399,647.165100 576.184753,657.289429 
                C579.098267,665.024536 579.163086,673.073608 577.704651,680.942688 
                C574.886169,696.150574 564.741638,701.671265 548.992065,696.917725 
                C547.551575,699.293457 548.259644,701.991638 548.109070,704.524170 
                C547.931213,707.515869 548.023010,710.524048 547.830078,713.514282 
                C547.498596,718.653931 541.773804,721.581299 537.296814,718.945007 
                C535.316467,717.778809 535.821411,715.734253 535.806458,713.992798 
                C535.733582,705.495667 535.763428,696.997681 535.756348,688.000000 
            M549.750610,684.528137 
                C555.229492,689.237427 560.726868,688.751831 563.672485,682.857239 
                C567.191040,675.816162 566.761047,668.380249 564.177429,661.155823 
                C562.753723,657.174866 559.097656,656.879272 555.426331,657.188660 
                C551.885010,657.487000 548.916199,659.381897 548.660706,662.713867 
                C548.122131,669.738708 547.171204,676.881775 549.750610,684.528137 
            z"/>
            <path fill={switchColors} opacity="1.000000" stroke="none" 
                d="
            M270.666626,642.001221 
                C270.663818,637.004333 270.701782,632.506531 270.649628,628.009888 
                C270.590027,622.871887 274.419464,623.975037 277.392212,623.776489 
                C280.512726,623.568176 283.087280,624.034546 283.011505,628.154480 
                C282.902863,634.065674 282.983826,639.980225 282.983826,646.311768 
                C284.757599,645.967590 285.560486,645.867310 286.332092,645.653809 
                C299.370026,642.046448 309.125061,647.401123 311.579803,660.696594 
                C313.358887,670.332642 313.625275,680.295471 309.028046,689.550171 
                C305.023834,697.611145 297.426941,701.072449 288.641449,698.645020 
                C285.039642,697.649780 281.475281,697.320129 277.796356,697.352356 
                C271.848602,697.404358 270.882355,696.618164 270.772186,690.471252 
                C270.590118,680.313904 270.687347,670.151550 270.673096,659.991272 
                C270.664917,654.161133 270.669006,648.330994 270.666626,642.001221 
            M283.038208,679.167175 
                C283.111298,683.231323 283.667175,686.943909 288.760101,687.651611 
                C293.845032,688.358154 297.144989,686.266418 298.922791,680.983887 
                C301.054230,674.650513 301.151825,668.197266 299.436890,661.789978 
                C298.238220,657.311646 294.725311,655.981018 290.497864,656.402588 
                C286.421570,656.809082 283.484802,658.826721 283.265594,663.251465 
                C283.018433,668.240479 283.087555,673.245117 283.038208,679.167175 
            z"/>
            <path fill={switchColors} opacity="1.000000" stroke="none" 
                d="
            M348.503357,661.592529 
                C344.008545,653.916321 338.236023,654.529297 329.892609,663.405396 
                C326.638916,661.857971 323.144623,659.948181 322.722473,656.128296 
                C322.265747,651.995605 325.788940,649.662842 329.092712,647.995239 
                C335.607330,644.706848 342.570404,644.351685 349.505096,646.232239 
                C356.537354,648.139160 360.946259,654.243408 361.158966,662.507996 
                C361.428772,672.989929 361.478424,683.490173 360.256439,693.940002 
                C359.795013,697.885986 354.095551,700.535889 349.657684,698.312744 
                C347.809021,697.386719 346.692657,697.734863 345.137543,698.248169 
                C340.825104,699.671875 336.466187,700.496704 331.918518,699.217529 
                C326.157623,697.596924 321.519653,692.337280 320.762054,686.381531 
                C319.857941,679.273926 322.303284,674.061584 328.040100,670.705139 
                C332.930054,667.844177 338.313904,667.004822 343.843048,666.649658 
                C346.731567,666.464172 349.297424,665.844482 348.503357,661.592529 
            M348.065094,685.266296 
                C349.064117,682.219116 349.402252,679.176880 348.164490,676.004639 
                C344.329254,675.990662 340.664490,676.125916 337.202301,677.416809 
                C334.212616,678.531555 332.812561,680.809143 333.175476,684.092773 
                C333.537781,687.370728 335.682281,688.599915 338.603973,688.896362 
                C342.033508,689.244385 345.297302,688.961304 348.065094,685.266296 
            z"/>
            <path fill={switchColors} opacity="1.000000" stroke="none" 
                d="
            M442.227356,686.889404 
                C444.697174,689.822327 443.797424,691.862976 441.481903,694.003357 
                C430.623322,704.040833 413.183441,700.893860 407.125153,687.383423 
                C403.038513,678.269958 402.561188,668.653748 406.120850,659.184387 
                C410.338226,647.965454 418.411804,643.199219 429.809723,644.784241 
                C438.969452,646.058044 445.345978,653.995483 446.251953,665.251465 
                C447.219788,677.276062 447.219788,677.280945 435.202484,677.305298 
                C429.254211,677.317322 423.305878,677.307434 417.388824,677.307434 
                C417.150818,687.429260 426.073547,691.324036 435.809875,685.791687 
                C438.411224,684.313599 440.272339,683.705566 442.227356,686.889404 
            M431.338318,658.089905 
                C424.217072,653.644226 417.990234,657.139587 417.147644,666.266724 
                C420.473572,667.426392 423.952026,666.890137 427.363892,666.890808 
                C434.566284,666.892334 435.464874,665.185120 431.338318,658.089905 
            z"/>
            <path fill={switchColors} opacity="1.000000" stroke="none" 
                d="
            M219.010773,675.636475 
                C218.735397,669.821594 219.407913,664.540894 220.999359,659.432495 
                C224.298660,648.842163 232.869476,643.597473 244.609390,644.791077 
                C253.120819,645.656433 260.421021,654.068970 261.278107,663.999634 
                C262.426392,677.304688 262.426392,677.309937 249.038040,677.322449 
                C244.543549,677.326599 240.047867,677.226746 235.554840,677.301331 
                C231.192947,677.373840 230.600937,678.448547 232.504944,682.439026 
                C235.117035,687.913513 240.771835,689.714172 247.457886,686.840393 
                C251.453720,685.122925 255.116791,685.678833 258.902222,687.049744 
                C257.269379,695.331482 249.559570,700.436951 240.026337,699.816833 
                C229.289551,699.118469 221.894577,692.248718 219.816727,681.013794 
                C219.514389,679.379089 219.311935,677.725830 219.010773,675.636475 
            M239.852631,656.108032 
                C234.574127,657.265625 232.426743,660.956360 231.497787,666.038757 
                C235.654221,667.702881 239.662308,666.837219 243.555115,666.893982 
                C245.648468,666.924561 248.757385,668.130005 249.504135,664.983337 
                C250.195740,662.068909 248.565353,659.492188 246.277359,657.596008 
                C244.680801,656.272949 242.710876,655.885315 239.852631,656.108032 
            z"/>
            <path fill={switchColors} opacity="1.000000" stroke="none" 
                d="
            M813.057800,687.275818 
                C814.383118,686.810303 815.324585,686.479858 816.257690,686.127319 
                C818.742981,685.188538 821.250793,681.891113 823.377502,686.875427 
                C825.667969,692.243530 825.561584,693.286194 820.651978,696.026123 
                C802.892090,705.937256 786.908691,695.845581 785.935852,674.031067 
                C785.696899,668.672424 786.552917,663.393677 788.392517,658.351501 
                C792.030579,648.379761 800.109924,643.574341 810.705750,644.908997 
                C819.613953,646.031128 826.391724,653.548157 827.286072,663.468201 
                C828.815308,680.430908 827.663940,677.017029 815.015564,677.259644 
                C809.741516,677.360840 804.463867,677.276489 799.168396,677.276489 
                C799.687256,686.394531 802.719971,688.526978 813.057800,687.275818 
            M806.782715,656.331787 
                C801.357971,657.410583 799.292358,661.114380 798.785522,666.356689 
                C802.409912,668.148499 806.127686,667.464294 809.697144,667.493591 
                C811.847412,667.511230 814.938599,668.055786 815.493347,665.025330 
                C816.060974,661.924377 814.678284,659.075928 811.821350,657.440063 
                C810.587769,656.733643 808.986938,656.668579 806.782715,656.331787 
            z"/>
            <path fill={switchColors} opacity="1.000000" stroke="none" 
                d="
            M759.293579,662.384277 
                C760.295471,660.732483 762.036926,659.837097 761.645935,657.569336 
                C756.774170,656.648438 751.811035,657.251953 746.905029,657.379211 
                C743.009460,657.480286 741.457397,656.150818 741.650452,652.213501 
                C741.820618,648.742188 741.410828,645.493225 746.604187,645.620239 
                C755.923340,645.848267 765.254028,645.794922 774.575806,645.631165 
                C778.227844,645.567078 779.230164,647.206116 779.033508,650.491577 
                C778.878113,653.087952 777.972046,655.301697 776.597107,657.455383 
                C771.394043,665.605530 765.463806,673.227173 759.695374,680.968445 
                C758.647705,682.374451 757.045105,683.554749 757.318848,685.913635 
                C760.140625,687.144409 763.121887,686.501526 766.052917,686.424133 
                C768.710449,686.354004 771.371460,686.158020 774.024658,686.234680 
                C779.248596,686.385559 779.312927,690.017151 779.338684,693.969910 
                C779.366455,698.211243 776.736145,698.303101 773.700562,698.294861 
                C764.375671,698.269470 755.046570,698.141663 745.727356,698.378296 
                C741.727722,698.479736 740.530273,696.993164 740.582031,693.146973 
                C740.633118,689.342285 741.246826,686.043823 743.693787,682.951599 
                C748.960022,676.296692 753.965332,669.435303 759.293579,662.384277 
            z"/>
            <path fill={switchColors} opacity="1.000000" stroke="none" 
                d="
            M599.955750,628.712891 
                C602.547852,628.780212 603.308289,630.017883 603.297913,631.973877 
                C603.289062,633.638245 603.332092,635.302795 603.336487,636.967285 
                C603.354614,643.835510 603.609497,644.315857 610.643127,644.389893 
                C616.772400,644.454346 614.452026,649.054504 615.021912,651.978638 
                C615.677002,655.340332 613.668274,656.209412 610.599060,656.185669 
                C603.153015,656.127930 603.123474,656.222351 603.136047,663.583740 
                C603.146240,669.576233 603.301086,675.569031 603.268066,681.560974 
                C603.246704,685.439270 605.013184,687.669800 608.887512,687.609192 
                C613.719299,687.533752 614.546997,690.077576 614.452576,694.270996 
                C614.346680,698.974548 611.412781,699.127197 608.087585,699.218140 
                C596.342896,699.539490 590.725403,694.164917 590.678711,682.523499 
                C590.651306,675.698364 590.579163,668.871765 590.689026,662.048462 
                C590.738525,658.975220 589.960815,656.748352 586.546814,656.412476 
                C581.467346,655.912903 583.175049,651.991211 582.873474,649.145508 
                C582.588440,646.455505 583.429932,644.627441 586.639832,644.609863 
                C589.540894,644.593933 590.713257,642.778259 590.694702,640.019531 
                C590.686829,638.855347 590.698303,637.684937 590.805115,636.526978 
                C591.416504,629.897888 592.726074,628.727356 599.955750,628.712891 
            z"/>
            <path fill={switchColors} opacity="1.000000" stroke="none" 
                d="
            M367.279877,653.983154 
                C367.603455,651.306519 367.981049,649.008606 368.552704,646.759949 
                C368.783936,645.850464 369.372772,644.354004 369.911133,644.302612 
                C377.180054,643.610107 374.994843,637.880432 375.638306,633.723877 
                C375.914642,631.938599 375.489655,629.687805 377.734650,629.134155 
                C380.621216,628.422180 383.692627,628.108093 386.496643,629.482849 
                C387.621368,630.034241 387.359070,631.549561 387.385956,632.686768 
                C387.433136,634.679871 387.518311,636.687439 387.341980,638.667053 
                C387.026093,642.214600 388.281891,643.815918 392.078827,643.833801 
                C397.116119,643.857483 400.133911,648.105469 399.100403,653.073181 
                C398.504852,655.935913 396.171509,655.294678 394.307312,655.380798 
                C387.267273,655.706238 387.302338,655.672119 387.135742,662.839294 
                C387.000488,668.657532 386.779419,674.473999 386.566528,680.290161 
                C386.369507,685.673218 388.597229,687.960510 394.092712,687.523621 
                C396.624359,687.322327 399.028534,686.953430 399.042419,690.429810 
                C399.054169,693.367920 400.385651,696.818420 395.901672,698.253723 
                C384.313660,701.963135 374.919220,695.442200 374.809540,683.336182 
                C374.744751,676.183044 374.640472,669.020508 374.930939,661.877563 
                C375.108673,657.506958 374.356262,654.630249 369.121735,655.235840 
                C368.591797,655.297241 367.983704,654.682617 367.279877,653.983154 
            z"/>
            <path fill={switchColors} opacity="1.000000" stroke="none" 
                d="
            M845.451294,645.747559 
                C849.044373,647.697571 851.948059,646.960999 855.245544,645.529968 
                C859.922424,643.500366 864.715332,644.921326 869.287415,646.642517 
                C871.412598,647.442566 871.756042,648.939636 871.037537,651.059692 
                C869.038330,656.958374 867.238770,658.127502 860.990295,657.230103 
                C854.039429,656.231812 850.252747,659.355042 850.117004,666.551697 
                C849.950928,675.356384 849.860291,684.169861 850.072449,692.971375 
                C850.182007,697.515015 848.250366,698.601013 844.071716,698.426086 
                C840.122498,698.260742 837.460205,698.014160 837.577148,692.839172 
                C837.877563,679.550781 837.675293,666.251282 837.692627,652.956177 
                C837.701721,645.977966 837.719360,645.977966 845.451294,645.747559 
            z"/>
            <path fill={switchColors} opacity="1.000000" stroke="none" 
                d="
            M732.138550,673.000000 
                C732.163696,680.159119 732.021667,686.824280 732.267334,693.475159 
                C732.423645,697.710327 730.295105,698.532104 726.723328,698.337891 
                C723.429565,698.158691 719.841858,699.034790 719.879211,693.705994 
                C719.981995,679.056396 719.947327,664.404968 719.833069,649.755310 
                C719.799133,645.412109 722.350708,645.036377 725.710999,645.066833 
                C729.227356,645.098694 732.151550,645.599304 732.155396,650.024414 
                C732.161865,657.516235 732.143494,665.008118 732.138550,673.000000 
            z"/>
            <path fill={switchColors} opacity="1.000000" stroke="none" 
                d="
            M622.680176,672.000610 
                C622.529968,664.684265 622.479248,657.863403 622.194336,651.052368 
                C622.002869,646.476624 624.355225,645.486328 628.382446,645.604004 
                C632.043457,645.710938 634.658020,645.652527 634.525208,650.784424 
                C634.159302,664.921997 634.104736,679.081360 634.472656,693.217834 
                C634.632935,699.376953 630.890320,698.119812 627.336792,698.372742 
                C623.166138,698.669495 622.262512,696.668762 622.412476,692.963318 
                C622.688354,686.149902 622.610474,679.322144 622.680176,672.000610 
            z"/>
            <path fill={switchColors} opacity="1.000000" stroke="none" 
                d="
            M726.740662,639.246338 
                C720.427734,638.676331 717.028076,634.873718 718.310364,629.993896 
                C719.263306,626.367432 721.480347,623.658325 725.593933,623.815857 
                C729.539856,623.967041 732.153259,626.460693 732.845642,630.154968 
                C733.680237,634.608154 731.588806,637.786743 726.740662,639.246338 
            z"/>
            <path fill={switchColors} opacity="1.000000" stroke="none" 
                d="
            M625.231018,624.183167 
                C630.744812,623.383057 634.731506,626.103394 635.256042,630.694885 
                C635.648621,634.131775 634.694824,637.076050 631.417175,638.661194 
                C628.432861,640.104553 625.491943,639.618713 623.119812,637.280029 
                C620.619202,634.814636 620.127258,631.777100 621.442200,628.535461 
                C622.142639,626.808655 623.360779,625.426758 625.231018,624.183167 
            z"/>
        </svg>
    )
}