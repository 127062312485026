import React, { useEffect, useState } from 'react';
import TailChaseLoader from '../Loader/TailChaseLoader';
import { formatCurrencyExp } from '../../utils/resultsSummary';
import { UseToggleContext } from '../../context/ToggleContexts';
import Logo259 from '../SVG/Logo259';

export default function PayStatsTable({stats, loading, formData, constraint}) {
    const toggler = UseToggleContext();

    // const [filteredStats, filter] = useState(null); 

    const [ collapse, toggle ] = useState({});
    useEffect(() => {
        Object.keys(stats).forEach(stat => {
            toggle(data => ({
                ...data,
                [stat]: false
            }))
        })
    }, [stats])

    function handleToggle(key) {
        toggle(past => ({
            ...past,
            [key]: !past[key]
        }));
    };

    // useEffect(() => {
    //     if (Object.keys(constraint.rules).length>0) {
    //         const statsss = {...stats};
    //         Object.keys(constraint.rules).forEach(type => {
    //             statsss[type] = omitFilter(type, constraint.rules, filteredStats?filteredStats:stats);
    //         })
    //         filter(statsss);
    //     } else {
    //         filter(stats);
    //     };
    // }, [constraint.rules]);

    // function omitFilter(type, rules, array) {
    //     const temp = array[type]?[...array[type]]:[];
    //     const operations = {
    //         "===": (a, b) => a === b,
    //         "!==": (a, b) => a !== b,
    //         "<": (a, b) => a < b,
    //         ">": (a, b) => a > b,
    //         "<=": (a, b) => a <= b,
    //         ">=": (a, b) => a >= b,
    //     };
    //     const omitting =  temp.filter(vals => {
    //         let bool = true;
    //         if (rules[type].forEach(rule => {
    //             if (operations[rule.operator](rule.rule==="03"?rule.freeform:parseInt(+rule.freeform), rule.rule==="03"?vals[rule.choice]:parseInt(+vals[rule.choice]))) {
    //                 bool = rule.rule==="02"?true:false
    //             };
    //         }));
    //         return bool;
    //     });
    //     return omitting;
    // };

    return (
        <div className='bg-background-light w-full p-8 '>
            {loading?<div className='flex justify-center items-center h-full'>
                <TailChaseLoader size={"150"} />
            </div>
             :
             stats.success?<>
                <div className='flex items-center justify-between pb-2'>
                    <span className='text-3xl'>Gap Analysis</span>
                    <div className=' uppercase pb-1 text-2xl'>
                        Casename: <span className='font-bold'>{formData.case_name}</span>
                    </div>
                </div>
                    <div className='flex items-center mx-auto w-fuil py-3 text-primary-red font-medium'>
                        {stats.message&&`Message: ${stats.message}`}
                    </div>
                <div style={{height: toggler.pageHeight-240}} className=' w-full overflow-y-auto'>
                    <table className='w-full overflow-y-auto h-full bg-white'>
                        <thead>
                        </thead>
                        <tbody>
                            {stats.success&&Object.entries(stats).map((stat, index) => (
                                <React.Fragment key={`${index}: gap analysis ${stat[0]}`}>
                                    <tr className='border'>
                                        {(stat[0]!=="success"&&stat[0]!=="message")&&
                                            <td 
                                                colSpan={7} 
                                                onClick={() => handleToggle(stat[0])}
                                                className='cursor-pointer bg-primary-dark text-white uppercase text-4xl py-4 pl-2 border-t' 
                                                >
                                                    {stat[0].split('_').join(" ")}     
                                            </td>
                                        }
                                    </tr>
                                    {collapse[stat?.[0]]&&Array.isArray(stat[1])&&stat[1].map((data, ind) => (
                                        <React.Fragment key={`${index}-${ind}: ${stat[0]}`}>
                                            <tr>
                                                {ind===0&&Object.keys(data).map((table, placement) => (
                                                    <td key={`${placement}: ${table}`} className={`capitalize py-3 px-2 bg-primary-dark text-white ${(index===1&&(placement===2||placement===4))||(placement===1||placement===3||placement===5||(index!==1&&placement===2))?'text-right':""}`}>
                                                        {table.split("_").join(" ")}
                                                    </td>
                                                ))}
                                            </tr>
                                            <tr className='border'>
                                                {Object.keys(data).map((key, idx) => (
                                                    <td key={`${idx}: ${data[key]}`} className={`py-3 px-2 ${(index===1&&(idx===2||idx===4))||(idx===1||idx===3||idx===5||(index!==1&&idx===2))?'text-right':""}`}>
                                                        {index===1&&(idx===2||idx===4)?`${(data[key]*100).toFixed(2)}%`:(idx===1||idx===3||idx===5||(index!==1&&idx===2))?formatCurrencyExp(data[key]):data[key]}
                                                    </td>
                                                ))}
                                            </tr>
                                        </React.Fragment>
                                    ))}
                                </React.Fragment>
                            ))}
                        </tbody>
                    </table>
                </div>
             </>
            :
            <div className='w-full h-full flex items-center justify-center'>
                <div className='bg-primary-dark w-full h-full rounded-2xl opacity-[0.1] flex items-center justify-center'>
                    <Logo259 className={'h-[300px]'} />
                </div>
            </div>}
        </div>
    );
};