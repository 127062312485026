import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleLeft, faPen, faXmarkCircle } from "@fortawesome/free-solid-svg-icons";
import { deleteRebateCredit } from '../../utils/creditForecast';

export default function RebateCreditWindow({data, set, change, setting}) {

    function handleDelete(e, id) {
        e.stopPropagation();
        deleteRebateCredit(id, data.creditlist, data.creditSelect, set, change);
    };
    function handleChange(e, details) {
        e.stopPropagation();
        change('edit')
        set(data => ({
            ...data,
            editList: details
        }));
    };
    
    function selectList(e, credit) {
        e.stopPropagation();
        set(data => ({
            ...data,
            creditSelect: credit
        }));
    }
    
    function clearSelect(e) {
        e.stopPropagation();
        set(data => ({
            ...data,
            creditSelect: null
        }));
    };

    const listStyles = 'border-b rounded hover:bg-primary-dark hover:text-white py-4 px-4 flex justify-between';

    return (
        <>  
            {(setting!=="list"||data.creditSelect)&&<button type='button' onClick={e=>clearSelect(e)} className='float-right pt-2'>
                <FontAwesomeIcon className={`text-2xl text-light-blue hover:text-hover-blue`} icon={faCircleLeft}/> 
            </button>}
            <ul className='border p-1 h-[650px] overflow-y-auto flex flex-col gap-1 rounded'>
                {!data.creditSelect&&data.creditlist&&Object.keys(data.creditlist).map((creditKey, index) => (
                    <li onClick={e =>selectList(e, creditKey)} key={`${index}: rebate credit console ${creditKey}`} className={listStyles+' cursor-pointer'}>
                        {creditKey}
                    </li>
                ))}
                {data.creditSelect&&Object.entries(data.creditlist[data.creditSelect]).map((creditDetails, index) => (
                    <li key={`${index}: rebate credit details console ${creditDetails}`} className={listStyles}>
                        <div>
                            {index+1}:
                            Drugname: <span className='font-bold pr-2 underline-offset-2 underline'>{creditDetails[1].drugname}</span>
                            NDC: <span className='font-bold pr-2 underline-offset-2 underline'>{creditDetails[1].ndc}</span>
                            Mag: <span className='font-bold pr-2 underline-offset-2 underline'>{creditDetails[1].mag}</span>
                            Type: <span className='font-bold pr-2 underline-offset-2 underline'>{creditDetails[1].credit_type}</span>
                        </div>
                        <div className='flex gap-4'>
                            <button type='button' onClick={e => handleChange(e, creditDetails[1])}>
                                <FontAwesomeIcon className={`text-2xl text-light-blue hover:text-hover-blue`} icon={faPen}/> 
                            </button>
                            <button type='button' onClick={e => handleDelete(e, creditDetails[1].id)}>
                                <FontAwesomeIcon className={`text-2xl text-primary-red hover:text-red-700`} icon={faXmarkCircle}/> 
                            </button>
                        </div>
                    </li>
                ))}
            </ul>
        </>
    )
}