import React from 'react';
import Select from 'react-select'
import QuickYear from '../SidePanel/QuickYear';
import { addRow, matchLobDynamic } from '../../utils/revenueCycleManagement';
import { globalChangeHandler } from '../../utils/globalHandlers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';

export default function JoinsPage({pageData, fileSelector, setStats, setFeilds, fields, setLoading, formData, setForm, constraintBuilder, toggleCB}) {

    function handleChange(value, column, idx) {
        let arr = [...fields];
        if (arr[idx]) {
            arr[idx][column] = value.value;
        }
        setFeilds(arr);
    };

    function addTableRow(idx) {
        return (
            <tr key={`${idx}: joins payment claims row`}>
                <td className='text-center font-bold'>
                    {idx+1}.
                </td>
                <td className='w-[50%]'>
                    <Select menuShouldScrollIntoView={false} onChange={e => handleChange(e, "on_claims_column", idx)} required options={pageData.claims_columns.map(ele => ({value: ele, label: ele}))} />
                </td>
                <td className='w-[50%]'>
                    <Select menuShouldScrollIntoView={false} onChange={e => handleChange(e, "on_pmt_column", idx)} required options={pageData.pmt_columns.map(ele => ({value: ele, label: pageData.reversed_mapping[ele]?pageData.reversed_mapping[ele]:ele}))} />
                </td>
            </tr>
        );
    };

    function removeRow() {
        let arr = [...fields];
        arr.pop();
        setFeilds(arr);
    };

    function handleSubmit(e) {
        e.preventDefault();
        matchLobDynamic(formData, fields, fileSelector, setStats, setLoading);
    };

    const tableHeadStyles = "border px-2 py-1 bg-primary-dark text-white";
    const labelStyles = "flex flex-col pt-1";
    const inputStyles = "border p-2 rounded-lg mt-1";
    const buttonStyles = "border rounded-full text-white  w-full";

    return (
        <div className='w-full  px-6  '>
            <form onSubmit={handleSubmit} >
                <label className={labelStyles}>Case name
                    <input value={formData.case_name} className={inputStyles} name='case_name' type='text' onChange={e => globalChangeHandler(e, null, setForm)} required />
                </label>
                <label className={labelStyles}>
                    <span className='relative'>Start date<QuickYear set={setForm} top={"top-6"} /></span>
                    <input value={formData.startDate} className={inputStyles+" cursor-pointer"} name='startDate' type='date' onChange={e => globalChangeHandler(e, null, setForm)} required />
                </label>
                <label className={labelStyles}>End date
                    <input value={formData.endDate} className={inputStyles+" cursor-pointer"} name='endDate' type='date' onChange={e => globalChangeHandler(e, null, setForm)} required />
                </label>
                <label className={labelStyles}>Price date
                    <input value={formData.priceDate} className={inputStyles+" cursor-pointer"} name='priceDate' type='date' onChange={e => globalChangeHandler(e, null, setForm)} required />
                </label>
                <div className='flex gap-[350px] mt-4 mb-1'>
                    <button type='button' className={buttonStyles+" bg-primary-green hover:bg-green-700"} onClick={() => addRow(setFeilds)}>
                        <FontAwesomeIcon icon={faPlus}/>
                    </button>
                    {fields.length>1?<button type='button' className={buttonStyles+" bg-primary-red hover:bg-red-700"} onClick={removeRow}>
                        <FontAwesomeIcon icon={faMinus}/>
                    </button>:<div className='w-full'></div>}
                </div>
                <table className='w-full '>
                        <thead>
                            <tr className='text-center'>
                                <th className={tableHeadStyles}>#</th>
                                <th className={tableHeadStyles}>Claims column</th>
                                <th className={tableHeadStyles}>Payment Column</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.keys(fields).map((row, idx) => addTableRow(idx))}         
                        </tbody>
                </table>
                <button className='w-full rounded-full bg-light-blue hover:bg-hover-blue text-white font-bold py-2 mt-3' type='button' onClick={() => toggleCB(!constraintBuilder)}>Constraint builder</button>
                <button className={buttonStyles+" w-full mt-2 mb-4 py-2 font-bold bg-light-blue hover:bg-hover-blue"}>Run Process</button>     
            </form>   
        </div>
    );
};