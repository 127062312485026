import React, { useContext } from 'react';
import { UseContextClues } from '../../context/ContextClues';
import { handlePricingLensChange, transform } from '../../utils/pricingLens';

export default function PricingLensNumbers({switchContext}) {

    const context = UseContextClues();
    const dealLength = context.exclusionSettings.dealLength;

    const inputStyles = "border rounded border-gray-400 px-1 ml-2 max-w-[100px]";

    function handlePercentbyYear(e) {
        const {name, value} = e.target
        // context.pricingLens.alterPassBackbyYear({
        //     ...context.pricingLens[switchContext]?.passBackbyYear,
        //     [name]: value/100
        // });
        context.setPricingLens(data => ({
            ...data,
            [switchContext] : {
                ...data[switchContext],
                passBackbyYear: {
                    ...data[switchContext].passBackbyYear,
                    [name]: value/100
                }
            }
        }));
    };

    const years = [
        "Year1",
        "Year2",
        "Year3",
        "Year4",
        "Year5",
    ];

    return (
        <>
            <table className={!context.pricingLens[switchContext]?.hedgeCheck?"pointer-events-none text-gray-300":""}>
                <thead>
                    <tr>
                        <th></th>
                        {years.map((year, idx) => (
                            idx<dealLength&&<th key={idx+": year"}>{year}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {Object.keys(transform(context.pricingLens[switchContext]?.priceLens?.price)).map((channel, idx) => (
                        <React.Fragment key={`${idx}: Pricing lens by channel: ${channel} `}>
                            {idx===0&&<tr className={!context.pricingLens[switchContext]?.percentageByYear?"text-gray-300 pointer-events-none":""}>
                                <td>
                                    %
                                </td>
                                {Object.keys(context.pricingLens[switchContext]?.passBackbyYear).map((year, idx) => (
                                    idx<dealLength&&<td key={`${idx}. Passback percentage - ${year}`}>
                                        <input 
                                            type='number' 
                                            min={-100} 
                                            max={100} 
                                            step={.1} 
                                            name={year} 
                                            className={`${inputStyles} ${!context.pricingLens[switchContext]?.percentageByYear&&"border-gray-100"}`} 
                                            value={(context.pricingLens[switchContext]?.passBackbyYear[year]*100).toFixed(1)} 
                                            onChange={handlePercentbyYear} 
                                        />%
                                    </td>
                                ))}
                            </tr>}
                            <tr key={`Pricing lens ${channel} - ${idx}`}>
                                <td>{channel}</td>
                                {Object.entries(transform(context.pricingLens[switchContext]?.priceLens?.price)).map(channelSelect => (
                                    Object.entries(channelSelect[1]).map((year, idx) => (
                                        channel===channelSelect[0]&&idx<dealLength&&
                                            <td className='whitespace-nowrap' key={`${idx}: Pricing lens control panel`}>
                                                <input 
                                                    className={`${context.pricingLens[switchContext]?.hedgeCheck&&context.pricingLens[switchContext]?.priceLens?.price[year[0]][channel]<0&&"bg-primary-red text-white " } + ${inputStyles}`} 
                                                    type='number' 
                                                    min={-100} 
                                                    max={100} 
                                                    step={.1} 
                                                    value={((year[1])*100).toFixed(1)} 
                                                    onChange={(e) => handlePricingLensChange(year[0], channel, e.target.value/100, null, switchContext, context)} 
                                                />%
                                            </td> 
                                    ))
                                ))}
                            </tr>
                        </React.Fragment>
                    ))}
                </tbody>
            </table>
        </>
    )
};