import React from 'react';
import PopUpSection from '../Section/PopUpSection';
import { globalBoolHandler } from '../../utils/globalHandlers';
import { UseToggleContext } from '../../context/ToggleContexts';

export default function SaveMemo({handleSave, addMemo, memo}) {

    const toggler = UseToggleContext();

    return (
        <>
            <PopUpSection >
                <div className=' bg-primary-dark rounded-xl w-[500px] h-[400px] flex mx-auto justify-center flex-col px-8 overflow-y-auto'>
                    <div className='text-white mb-2 '>
                        Leave a memo with your saved case
                    </div>
                    <textarea className='p-2 h-[265px] rounded' value={memo} onChange={(e) => addMemo(e.target.value)}/>
                    <div>
                        <button className='text-white border rounded-lg  mt-4 py-2 w-40 bg-light-blue hover:bg-inherit' onClick={() => globalBoolHandler("saveMemo", toggler.toggle, toggler.setToggle, false)}>
                            Cancel
                        </button>
                        <button className='text-white border rounded-lg border-white mt-4 py-2 w-40  hover:bg-light-blue float-right' onClick={handleSave}>
                            Save
                        </button>
                    </div>
                </div>
            </PopUpSection>
        </>
    )
}