import React from 'react';
import { UseContextClues } from '../../context/ContextClues';
import { UseToggleContext } from '../../context/ToggleContexts';
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EditSelect from '../EditInput/EditSelect';

export default function CrystalBallTable({setHovered, setPayload, payload, breakEvenBool, tableRef}) {

    const context = UseContextClues();
    const toggler = UseToggleContext();

    function handleChange(value, mfr, drugname, column, checkbox){
        setPayload(data => ({
            ...data,
            [mfr]: {
                ...data?.[mfr],
                [drugname.split(" ").join("_")]: {
                    ...data?.[mfr][drugname.split(" ").join("_")],
                    [column]: column==="tpn"?value:checkbox?value:+value/100,
                }
            }
        }));
    };

    function handleCheckbox(value, mfr, drugname) {
        if (payload[mfr][drugname.split(" ").join("_")].rate !== "x") {
            handleChange("x", mfr, drugname, "rate", true)
        } else {
            handleChange(value, mfr, drugname, "rate")
        };
    };

    const tBodyStyle = "py-1 px-2 h-12 box-content text-xs";

    return (
        <table ref={ref => tableRef.current.mainTable = ref} className='text-sm w-full relative '>
            <thead className='text-left'>
                <tr className='sticky top-0 bg-background-light z-[5]'>
                    <th className='align-bottom pl-1'>Drug Name</th>
                    <th className='align-bottom'>Manufacturer</th>
                    <th className='text-center align-bottom'>WAC</th>
                    <th className='text-center align-bottom'>Rebates</th>
                    <th className='text-center align-bottom'>Rate</th>
                    <th className='text-center align-bottom'>Rxs</th>
                    <th className={toggler.toggle.breakEven?"text-center min-w-[63px] whitespace-pre-wrap":'text-right'}>Market<br></br> Share %</th>
                    {toggler.toggle.breakEven&&
                            <>
                                <th className='text-center align-bottom'>Days</th>
                                <th className='text-center align-bottom'>New Rate</th>
                                <th className='text-right align-bottom pr-2' onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>TPN</th>
                            </>
                        }                   
                </tr>
            </thead>
            <tbody className='border bg-white '>
                {context.crystal?.grid.map((rows, idx) => (
                    <tr key={`Crystal ball key: ${rows.drugname} index: ${idx}`} className='border '>
                        <td className={"pl-1 text-left "+tBodyStyle}>{rows.drugname}</td>
                        <td className={tBodyStyle + " w-10"}>{rows.mfr}</td>
                        <td className={tBodyStyle + " text-center"}>{parseFloat(rows.WAC).toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0, minimumFractionDigits: 0 })}</td>
                        <td className={tBodyStyle + " text-center"}>{parseFloat(rows.rebates).toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0, minimumFractionDigits: 0 })}</td>
                        <td className={tBodyStyle + " text-center"}>{parseFloat(rows.rate).toLocaleString('en-US', { style: 'percent', minimumFractionDigits: 2 })}</td>
                        <td className={tBodyStyle + " text-center"}>{parseFloat(rows.rxs).toLocaleString('en-US', { maximumFractionDigits: 0 })}</td>
                        <td className={`${toggler.toggle.breakEven?"text-center":'text-right'} ${tBodyStyle+"  pr-1"}`}>{parseFloat(rows.marketshare).toLocaleString('en-US', { style: 'percent', minimumFractionDigits: 2 })}</td> 
                        {toggler.toggle.breakEven&&
                            <>
                                <td className={tBodyStyle+ " text-center"}>{parseFloat(rows.dayssupply).toLocaleString('en-US', { maximumFractionDigits: 0 })}</td>
                                <td className={`relative ${payload?.[rows?.mfr]?.[rows?.drugname.split(" ").join("_")]?.rate === "x"?"bg-red-500":"bg-primary-dark hover:bg-primary-blue"} text-center whitespace-nowrap min-w-[160px] `}>
                                    <input 
                                        key={`${idx} jump gump`}
                                        type='number' 
                                        min={0} 
                                        max={100} 
                                        step={0.01}
                                        autoFocus 
                                        disabled={payload?.[rows.mfr]?.[rows.drugname.split(" ").join("_")]?.rate === "x"} 
                                        className='border w-18 py-2 px-1 disabled:cursor-not-allowed' 
                                        // defaultValue={parseFloat(payload[rows.mfr][rows.drugname.split(" ").join("_")]?.rate*100).toFixed(2)} 
                                        value={+parseFloat(payload?.[rows.mfr]?.[rows.drugname.split(" ").join("_")]?.rate*100).toFixed(2)}
                                        onChange={(e) => handleChange(e.target.value, rows.mfr, rows.drugname, "rate")} 
                                    />
                                    <span className="text-white font-bold">%</span>
                                    {!breakEvenBool&&<label className={`absolute top-3 right-3 cursor-pointer`}>
                                        <span>
                                            {payload?.[rows.mfr]?.[rows.drugname.split(" ").join("_")]?.rate === "x"?
                                            <FontAwesomeIcon className='text-lg text-primary-green hover:text-green-600 ' icon={faCheck}/>
                                            :<FontAwesomeIcon className='text-lg text-primary-red hover:text-red-600 ' icon={faXmark}/>}
                                        </span>
                                        <input className='hidden' type='checkbox' value={parseFloat(payload?.[rows.mfr]?.[rows?.drugname.split(" ").join("_")]?.starting_rate*100).toFixed(2)} onClick={e => handleCheckbox(e.target.value, rows.mfr, rows.drugname)} />
                                    </label>}
                                </td> 
                                <td className={tBodyStyle}>
                                    <EditSelect context={context} defaultValue={rows.tpn} mfr={rows.mfr} drugname={rows.drugname} handleChangeOver={handleChange} addedClasses={"float-right h-full"}  />    
                                </td> 
                            </>
                        }                   
                    </tr>
                ))}
            </tbody>
        </table>
    )
};