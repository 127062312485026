import React, { useState } from 'react';
import { deleteUserTable } from '../../utils/claimsLoad';
import { downloadCase } from '../../utils/loadSave';
import { globalBoolHandler } from '../../utils/globalHandlers';
import { UseToggleContext } from '../../context/ToggleContexts';

export default function SimpleButton({tableName, setModels, download, tablename}) {
    
    const toggler = UseToggleContext();
    const [ load, set ] = useState(false); 

    return (
        <>
            {tablename?
            <button type='button' onClick={() => globalBoolHandler("createVendorPricingExtract", toggler.toggle, toggler.setToggle, tablename)} className='w-full py-3 hover:bg-primary-yellow text-black h-full uppercase'>
                {load?<l-tail-chase size={"15"} speed="1.75" color="black" ></l-tail-chase>:"create"}
            </button>
            :download?
            <button type='button' className='text-xs uppercase border bg-light-blue hover:bg-hover-blue px-5 py-2 text-white font-bold rounded hover:shadow-lg' onClick={() => downloadCase(tableName, setModels, set)}>
                {load?<l-tail-chase size={"15"} speed="1.75" color="white" ></l-tail-chase>:"download"}
            </button>:
            <button type='button' className='w-full py-3 hover:bg-primary-red h-full uppercase' onClick={() => deleteUserTable(tableName, setModels, set)}>
                {load?<l-tail-chase size={"15"} speed="1.75" color="black" ></l-tail-chase>:"delete"}
            </button>
            }
        </>
    )
};